import { createContext } from 'react';
import { Checklist } from '@/api/generated';

export type ChecklistsManagerDialogMode = 'select' | 'default' | undefined;

export type ChecklistsManagerDialogContextValue = {
  showChecklistsManagerDialog(mode?: ChecklistsManagerDialogMode): Promise<Checklist[] | undefined>;
};

export const ChecklistsManagerDialogContext = createContext<ChecklistsManagerDialogContextValue | null>(null);

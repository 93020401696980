import { createContext } from 'react';
import { AnnotationBackendJSON } from 'pspdfkit';
import { EquipmentPiece } from '@/api/generated';

interface AnnotationsContextValue {
  annotations: AnnotationBackendJSON[];
  documentAnnotationsRef: { current: AnnotationBackendJSON[] };
  equipmentList: EquipmentPiece[];
  isLoading: boolean;
  isAnnotationsFailedToLoad: boolean;
  hideAnnotations: (annotationIdOrIds: string | Array<string>) => void;
  showAnnotations: (annotationIdOrIds: string | Array<string>) => void;
  hiddenAnnotationsIds: string[];
  highlightAnnotations: (ids: string[]) => void;
  highlightedAnnotationsIds: string[];
}

export const AnnotationsContext = createContext<AnnotationsContextValue | null>(null);

import { Chip, ChipProps, useTheme } from '@mui/material';
import { FC } from 'react';

interface TagChipProps extends Omit<ChipProps, 'color'> {
  name: string;
  color: string;
}

const TagChip: FC<TagChipProps> = ({ name, color, sx = {}, ...props }) => {
  const { palette, typography } = useTheme();

  return (
    <Chip
      label={name}
      variant="outlined"
      sx={{
        fontSize: typography.body4.fontSize,
        color: palette.primary.dark,
        fontWeight: typography.fontWeightBold,
        backgroundColor: color,
        py: 0.7,
        px: 0,
        height: 'unset',
        position: 'relative',
        border: 0,
        borderRadius: 2,
        ...sx,
      }}
      {...props}
    />
  );
};

export default TagChip;

const unitConversions: Record<string, number> = {
  in: 0.0833333,
  mm: 0.00328084,
  cm: 0.0328084,
  pt: 0.0833333,
  ft: 1,
  m: 3.28084,
  yd: 3,
  km: 3280.84,
  mi: 5280,
  'sq in': 0.00694444,
  'sq mm': 0.000010764,
  'sq cm': 0.00107639,
  'sq pt': 0.00694444,
  'sq ft': 1,
  'sq m': 10.7639,
  'sq yd': 9,
  'sq km': 10763910,
  'sq mi': 27878400,
};

export const convertToFeet = (inputValue: string) => {
  const number = parseFloat(inputValue);
  const unit = inputValue.replace(/\d*(\.\d*)? /, '');

  if (!unit || !unitConversions[unit]) {
    throw new Error(`Invalid unit "${unit}"`);
  }

  return +(number * unitConversions[unit]).toFixed(2);
};

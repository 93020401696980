import { FC, ReactNode, useState } from 'react';
import { useAuth } from '@/hooks/useAuth';
import { useOrganization } from '@/hooks/useOrganization';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import useRouteId from '@/hooks/useRouteId';
import { ROUTER_IDS } from '@/services/linker';
import {
  useBatchDeleteDocuments,
  useDeleteThread,
  useGetAllThreads,
  useGetProjectsId,
  useGetThreadById,
  useUploadFileWithVdb,
} from '@/api/generated';
import { KnowledgeContext } from '@/views/Knowledge/KnowledgeContext';

interface KnowledgeProviderProps {
  children: ReactNode;
}

const KnowledgeProvider: FC<KnowledgeProviderProps> = ({ children }) => {
  const { currentUser } = useAuth();
  const { organization } = useOrganization();
  const { t } = useTranslation('common');
  const [currentThreadId, setCurrentThreadId] = useState('');
  const [isLoadingUpload, setLoadingUpload] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const routeId = useRouteId();
  const isOrgKbActive = routeId === ROUTER_IDS.ORG_KB;

  const {
    data: knowledgeBase,
    isFetching: isUploadFilesFetching,
    refetch: uploadedFilesRefetch,
  } = useGetProjectsId(organization.slug, {
    query: { enabled: isActive || isOrgKbActive },
  });
  const { data: threads, refetch: threadsRefetch } = useGetAllThreads(organization.slug, {
    query: { enabled: isActive || isOrgKbActive },
  });

  const { data: currentThread, isLoading: isThreadLoading } = useGetThreadById(organization.slug, currentThreadId);

  const { mutateAsync: uploadFileMutation } = useUploadFileWithVdb({ mutation: { retry: true, retryDelay: 500 } });
  const { mutateAsync: deleteDocumentMutation } = useBatchDeleteDocuments();
  const { mutateAsync: deleteThreadMutation, isPending: isDeleteThreadPending } = useDeleteThread();

  const deleteThread = (threadId: string) =>
    deleteThreadMutation(
      { slug: organization.slug, threadId },
      { onSuccess: () => threadsRefetch(), onError: err => console.error(err) },
    );

  const deleteDocument = (documentId: string) =>
    deleteDocumentMutation(
      { slug: organization.slug, data: [documentId] },
      {
        onSuccess: () => {
          uploadedFilesRefetch();
          enqueueSnackbar(t('knowledge.deleteSucceed'));
        },
        onError: () => {
          enqueueSnackbar(t('knowledge.deleteFailed'), { variant: 'error' });
        },
      },
    );

  const uploadFile = (file: File) => {
    setLoadingUpload(true);
    return uploadFileMutation(
      {
        slug: organization.slug,
        data: { file },
      },
      {
        onSuccess: () => {
          setLoadingUpload(false);
          uploadedFilesRefetch();
          enqueueSnackbar(t('knowledge.uploadSucceed'));
        },
        onError: () => {
          setLoadingUpload(false);
          enqueueSnackbar(t('knowledge.uploadFailed'), { variant: 'error' });
        },
      },
    );
  };

  return (
    <KnowledgeContext.Provider
      value={{
        currentUser,
        threads,
        uploadFile,
        threadsRefetch,
        setCurrentThreadId,
        currentThread,
        currentThreadId,
        isThreadLoading,
        isLoadingUpload,
        setLoadingUpload,
        deleteThread,
        isDeleteThreadPending,
        uploadedFiles: knowledgeBase?.documents ?? [],
        isUploadFilesFetching,
        deleteDocument,
        isModified,
        setIsModified,
        setIsActive,
        isActive: isActive || isOrgKbActive,
        uploadedFilesRefetch,
      }}
    >
      {children}
    </KnowledgeContext.Provider>
  );
};

export default KnowledgeProvider;

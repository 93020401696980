import { ButtonBase, Checkbox, IconButton, List, ListItem, Stack, Typography, useTheme } from '@mui/material';
import Icon from '@/components/Icon/Icon';
import { ChangeEvent, FC } from 'react';
import { Checklist } from '@/api/generated';

export type ChecklistGroupProps = {
  name: string;
  checklists?: Checklist[];
  setCurrentChecklistId: (checklistId?: string) => void;
  onAddNewChecklist: () => void;
  onDeleteChecklist: (id?: string) => Promise<void>;
  onSelectItems?: (items: { checklistId: string; isChecked: boolean }[]) => void;
  selectedChecklists?: string[];
  withAddButton?: boolean;
  withDeleteButton?: boolean;
};

const ChecklistGroup: FC<ChecklistGroupProps> = ({
  name,
  checklists,
  setCurrentChecklistId,
  onAddNewChecklist,
  onDeleteChecklist,
  onSelectItems,
  selectedChecklists,
  withAddButton = false,
  withDeleteButton = false,
}) => {
  const { palette, spacing } = useTheme();

  const onSelect = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    onSelectItems?.([
      {
        checklistId: event.target.id,
        isChecked: event.target.checked,
      },
    ]);
  };

  const isSelectMode = !!onSelectItems;
  const isAllSelected =
    checklists?.reduce((acc, checklist) => {
      if (selectedChecklists?.includes(checklist._id as string)) {
        return acc + 1;
      }
      return acc;
    }, 0) === checklists?.length && !!checklists?.length;

  const onSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (!checklists) return;
    onSelectItems?.(
      checklists.map(checklist => ({
        checklistId: checklist._id!,
        isChecked: event.target.checked,
      })),
    );
  };

  return (
    <Stack
      sx={{
        borderBottom: `1px solid ${palette.grey[100]}`,
        px: 1.5,
        pb: 1,
        backgroundColor: palette.grey[50],
        '&:first-of-type': { pt: 1 },
        '&:last-of-type': { borderBottom: 'none', pb: 8 },
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          pl: 1.5,
          minHeight: '2rem',
        }}
      >
        <Stack flexDirection="row">
          {isSelectMode && (
            <Checkbox
              id={'all'}
              data-testid="checkbox"
              size="medium"
              disableRipple
              sx={{
                p: 0,
                mr: 1.5,
                '&:not(.Mui-checked) .MuiSvgIcon-root': { fill: palette.grey['300'] },
              }}
              onChange={onSelectAll}
              checked={isAllSelected}
            />
          )}
          <Typography
            component="div"
            sx={{
              fontSize: 'body1.fontSize',
              fontWeight: 'fontWeightMedium',
              color: palette.grey[700],
            }}
          >
            {name}
          </Typography>
        </Stack>
        {withAddButton && (
          <IconButton
            sx={{
              p: 0.5,
              width: 32,
              height: 32,
              color: palette.text.secondary,
              backgroundColor: palette.background.default,
              boxShadow: 1,
              '&:hover': { backgroundColor: palette.grey[50] },
            }}
            onClick={onAddNewChecklist}
          >
            <Icon name="plus" sx={{ width: 16, height: 16 }} />
          </IconButton>
        )}
      </Stack>
      <List sx={{ p: 0, display: 'flex', flexDirection: 'column' }}>
        {checklists?.map(({ name: listName, _id }) => (
          <ListItem
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              p: spacing(0, 0.25, 0, 0),
              borderRadius: 1,
              pl: 3,
              '&:hover': { backgroundColor: palette.grey[200], '.deleteChecklist': { opacity: 1 } },
            }}
            key={_id}
          >
            {isSelectMode && (
              <Checkbox
                id={_id}
                data-testid="checkbox"
                size="medium"
                disableRipple
                sx={{
                  p: 0,
                  ml: 1.5,
                  mr: 1.5,
                  '&:not(.Mui-checked) .MuiSvgIcon-root': { fill: palette.grey['300'] },
                }}
                onChange={onSelect}
                checked={!!selectedChecklists?.includes(_id as string)}
              />
            )}
            <ButtonBase
              disableRipple
              onClick={() => setCurrentChecklistId(_id)}
              sx={{
                fontSize: 'body2.fontSize',
                color: palette.grey[900],
                width: '100%',
                justifyContent: 'flex-start',
                height: '100%',
                minHeight: 20,
                py: 1,
                textAlign: 'left',
              }}
            >
              {listName}
            </ButtonBase>
            {withDeleteButton && (
              <IconButton className="deleteChecklist" sx={{ p: 0.5, opacity: 0 }} onClick={() => onDeleteChecklist(_id)}>
                <Icon name="delete" fontSize="xsmall" />
              </IconButton>
            )}
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

export default ChecklistGroup;

import { FC, useCallback, useRef, useState } from 'react';
import { Box, Breadcrumbs, IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CreateHandler, DeleteHandler, MoveHandler, NodeRendererProps, RenameHandler, Tree, TreeApi } from 'react-arborist';
import TreePageNode from '@/views/Project/views/AutomatePages/components/TreePageNode';
import { type TreePage } from '@/views/Project/views/AutomatePages/types';
import SearchInput from '@/views/Project/views/Navigator/components/SearchInput';
import useEditorClick from '@/containers/PagesEditor/hooks/useEditorClick';
import Icon from '@/components/Icon/Icon';
import { TEMPLATE_FOLDER } from '@/views/Project/views/AutomatePages/constants';
import Drawer from '@/components/Drawer';
import { getFadingShadowSxProps } from '@/utils/getFadingShadowSxProps';
import { RxDoubleArrowLeft, RxHamburgerMenu } from 'react-icons/rx';

interface PagesDrawerProps {
  currentPageId?: string | null;
  currentPagePath?: string[];
  pagesTree?: TreePage[];
  onPage: (pageId: string) => void;
  onMove: MoveHandler<TreePage>;
  onCreate: CreateHandler<TreePage>;
  onRename: RenameHandler<TreePage>;
  onDelete: DeleteHandler<TreePage>;
  onMakeTemplate: (page: TreePage) => void;
  onCreatePageFromTemplate: (page: TreePage) => void;
}

const PagesDrawer: FC<PagesDrawerProps> = ({
  currentPageId,
  currentPagePath,
  pagesTree,
  onPage,
  onMove,
  onCreate,
  onRename,
  onDelete,
  onMakeTemplate,
  onCreatePageFromTemplate,
}) => {
  const { t } = useTranslation('project');
  const treeRef = useRef<TreeApi<TreePage>>();
  const dndRootRef = useRef<HTMLDivElement | null>(null);
  const { palette, spacing } = useTheme();
  const [searchTerm, setSearchTerm] = useState('');
  const [isPagesMenuOpened, setIsPagesMenuOpened] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);

  useEditorClick(() => setIsPagesMenuOpened(false));

  const handlePageChange = useCallback(
    (node: TreePage) => {
      setIsPagesMenuOpened(false);
      onPage(node.item.id);
    },
    [onPage],
  );

  const onAddToRoot = () => {
    treeRef.current?.create();
  };

  const handleCreate: CreateHandler<TreePage> = (...params) => {
    setSearchTerm('');
    return onCreate(...params);
  };

  const TreePage = useCallback(
    (nodeProps: NodeRendererProps<TreePage>) => (
      <TreePageNode
        {...nodeProps}
        onNameClick={handlePageChange}
        onMakeTemplate={onMakeTemplate}
        onCreatePageFromTemplate={onCreatePageFromTemplate}
      />
    ),
    [handlePageChange],
  );

  const renderHeader = () => {
    if (!currentPagePath?.length) return null;

    return (
      <Breadcrumbs maxItems={2} key={currentPageId}>
        {currentPagePath.map((pathPart, index) => (
          <Box
            key={index}
            sx={{
              maxWidth: 180,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {pathPart}
          </Box>
        ))}
      </Breadcrumbs>
    );
  };

  return (
    <Box ref={dndRootRef} sx={{ position: 'relative' }}>
      <Box sx={{ display: 'inline-block', width: '100%', ...getFadingShadowSxProps(8) }}>
        <Stack direction="row" gap={1} alignItems="center" pr={0} p={3} width="100%">
          <Tooltip arrow title={t('pages.tooltips.pagesLibrary')}>
            <IconButton className="drawer-toggle" onClick={() => setTimeout(() => setIsPagesMenuOpened(isOpened => !isOpened))}>
              {isPagesMenuOpened ? <RxDoubleArrowLeft /> : <RxHamburgerMenu />}
            </IconButton>
          </Tooltip>
          {renderHeader()}
        </Stack>
      </Box>

      <Drawer sx={{ top: 90, ml: 3 }} isOpened={isPagesMenuOpened} onClose={() => setIsPagesMenuOpened(false)}>
        <Box sx={{ minWidth: 310, minHeight: 500 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 1,
              borderBottom: `1px solid ${palette.grey[200]}`,
            }}
          >
            <Tooltip
              arrow
              title={t('pages.tooltips.search')}
              open={openTooltip}
              onOpen={() => setOpenTooltip(true)}
              onClose={() => setOpenTooltip(false)}
            >
              <SearchInput
                value={searchTerm}
                onChange={event => {
                  setSearchTerm(event.target.value);
                  setOpenTooltip(false);
                }}
                isOpen
              />
            </Tooltip>
            <Tooltip arrow title={t('pages.tooltips.createNewPage')} placement="left">
              <IconButton onClick={onAddToRoot}>
                <Icon name="plus" fontSize="small" htmlColor={palette.primary.dark} />
              </IconButton>
            </Tooltip>
          </Box>

          {!pagesTree?.length && <Box p={2}>{t('pages.noPages')}</Box>}
          <Tree
            ref={treeRef}
            dndRootElement={dndRootRef.current}
            width={310}
            height={500}
            padding={parseInt(spacing(2))}
            indent={parseInt(spacing(2))}
            openByDefault={false}
            searchTerm={searchTerm}
            searchMatch={(node, term) => node.data.item.name.toLowerCase().includes(term.toLowerCase())}
            rowHeight={38}
            data={pagesTree}
            idAccessor={node => node.item.id}
            disableDrag={node => !!node.item.isStatic}
            disableDrop={({ parentNode }) =>
              parentNode.data.item &&
              (parentNode.data.item.parentId === TEMPLATE_FOLDER.id || !!parentNode.data.item.isTemplateFolder)
            }
            childrenAccessor="children"
            onMove={onMove}
            onCreate={handleCreate}
            onRename={onRename}
            onDelete={onDelete}
          >
            {TreePage}
          </Tree>
        </Box>
      </Drawer>
    </Box>
  );
};

export default PagesDrawer;

import { FC, KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';
import { bindPopover, PopupState } from 'material-ui-popup-state/hooks';
import { Box, ButtonBase, Input, Popover, Stack, useTheme } from '@mui/material';
import TagView from '@/components/TagView';
import Icon from '@/components/Icon/Icon';
import { Tag } from '@/api/generated';
import { Key } from 'ts-key-enum';

interface TagsSelectPopoverProps {
  popupState: PopupState;
  placeholder: string;
  headerText?: string;
  tags: Tag[];
  selectedTags: string[];
  onTagToggle: (tag: string) => void;
  onEnter?: (search: string) => void;
}

const TagsSelectPopover: FC<TagsSelectPopoverProps> = ({
  popupState,
  placeholder,
  headerText,
  tags,
  selectedTags,
  onTagToggle,
  onEnter,
}) => {
  const { palette } = useTheme();

  const [search, setSearch] = useState('');
  const inputRef = useRef<HTMLInputElement | null>();

  const isHeaderVisible = headerText || !!selectedTags.length;
  const filteredTags = useMemo(
    () => tags.filter(tag => tag.name.toLowerCase().includes(search.toLowerCase()) && !selectedTags.includes(tag.name)),
    [search, tags, selectedTags],
  );

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.code !== Key.Enter) return;
    const newTagName = search.trim();
    if (newTagName.length) {
      onEnter?.(newTagName);
      setSearch('');
    }
  };

  useEffect(() => {
    if (popupState.isOpen) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    } else {
      setSearch('');
    }
  }, [popupState.isOpen]);

  const onTagClick = (tagName: string) => () => {
    onTagToggle(tagName);
    setSearch('');
    inputRef.current?.focus();
  };

  return (
    <Popover
      {...bindPopover(popupState)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: -10, horizontal: 'center' }}
      slotProps={{ paper: { sx: { px: 0, boxShadow: 1, minWidth: 300 } } }}
    >
      <Box sx={{ maxWidth: 300 }}>
        {isHeaderVisible && (
          <Stack sx={{ gap: 1, p: 1 }}>
            {headerText && <Box sx={{ fontSize: 'body4.fontSize', color: palette.grey[500] }}>{headerText}</Box>}
            {!!selectedTags.length && (
              <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                {Array.from(selectedTags.values()).map(tag => (
                  <TagView disableRipple isHighlighted tag={tag} onDelete={onTagClick(tag)} />
                ))}
              </Box>
            )}
          </Stack>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            py: 0.5,
            px: 1,
            borderTop: '1px solid',
            borderBottom: '1px solid',
            borderColor: palette.grey[200],
          }}
        >
          <Icon name="search" />
          <Input
            inputRef={inputRef}
            sx={{ width: '100%', fontSize: 'body3.fontSize' }}
            placeholder={placeholder}
            disableUnderline
            value={search}
            onChange={event => setSearch(event.target.value)}
            onKeyDown={onKeyDown}
          />
        </Box>
        <Box sx={{ mt: '-1px', maxWidth: 300, maxHeight: 200, overflow: 'auto' }}>
          {filteredTags.map(tag => (
            <ButtonBase
              key={tag.name}
              sx={{
                width: '100%',
                py: 1,
                px: 1.5,
                fontSize: 'body3.fontSize',
                justifyContent: 'flex-start',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                borderTop: '1px solid',
                borderColor: palette.grey[200],
                '&:hover': { backgroundColor: palette.primary.main, color: palette.darkPurple.dark },
              }}
              disableRipple
              onClick={onTagClick(tag.name)}
            >
              {tag.name}
            </ButtonBase>
          ))}
        </Box>
      </Box>
    </Popover>
  );
};

export default TagsSelectPopover;

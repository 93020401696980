import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@/store';
import { TreeFileSystemNode } from '@/types';

export type FileTreeState = {
  [slug: string]: TreeFileSystemNode[];
};

type SetFileTreePayload = {
  slug: string;
  fileTree: TreeFileSystemNode[];
};

const fileTreeSlice = createSlice({
  name: 'fileTree',
  initialState: {} as FileTreeState,
  reducers: {
    setFileTree: (state: FileTreeState, action: PayloadAction<SetFileTreePayload>) => ({
      ...state,
      [action.payload.slug]: action.payload.fileTree,
    }),
  },
});

const { setFileTree } = fileTreeSlice.actions;

const EMPTY_STATE: TreeFileSystemNode[] = [];
export const selectProjectFileTree = (projectSlug?: string) => (state: RootState) =>
  projectSlug ? state.fileTree[projectSlug] : EMPTY_STATE;

export { setFileTree };
export default fileTreeSlice;

import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ChecklistFormValue } from '@/views/Project/views/Queries/components/EditorChecklistsDialog/ChecklistsManagerDialog';
import TextInput from '@/components/TextInput/TextInput';
import { useTranslation } from 'react-i18next';
import Questions from '@/views/Project/views/Queries/components/EditorChecklistsDialog/Questions';
import { FC, useEffect, useState } from 'react';
import { Checklist, QueryQuestion, Tag } from '@/api/generated';
import Icon from '@/components/Icon/Icon';
import { Key } from 'ts-key-enum';

interface ChecklistFormProps {
  onSaveField: (data: ChecklistFormValue) => Promise<void>;
  selectedIds: Set<string>;
  onQuestionToggle: (id?: string) => void;
  onToggleAll: () => void;
  selectedAllIds: boolean;
  onDeleteQuestions: () => void;
  onUpdateQueryTags: (newTag: Tag, queryToUpdateId: string) => Promise<void>;
  onDeleteTag: (tag: Tag, id?: string) => Promise<void>;
  checklists?: Checklist[];
  selectedQuestions?: QueryQuestion[];
  setCurrentChecklistId: (currentChecklistId?: string) => void;
  canEdit?: boolean;
  currentChecklist?: Checklist;
}

const ChecklistForm: FC<ChecklistFormProps> = ({
  onSaveField,
  selectedIds,
  onQuestionToggle,
  onToggleAll,
  selectedAllIds,
  onDeleteQuestions,
  onUpdateQueryTags,
  onDeleteTag,
  checklists,
  selectedQuestions,
  setCurrentChecklistId,
  canEdit,
  currentChecklist,
}) => {
  const { t } = useTranslation('project');
  const { palette } = useTheme();
  const [isEditChecklistTitle, setIsEditChecklistTitle] = useState(false);
  const { control, setFocus, getValues, handleSubmit, watch, clearErrors } = useFormContext<ChecklistFormValue>();
  const name = watch('name');
  const isEditMode = (canEdit && isEditChecklistTitle) || !currentChecklist?._id;

  useEffect(() => {
    clearErrors();
  }, [clearErrors]);

  useEffect(() => {
    isEditChecklistTitle && setFocus('name');
  }, [isEditChecklistTitle, setFocus]);

  const handleSaveField = () => {
    const currentValues = getValues();
    const trimmedName = currentValues.name?.trim();
    if (trimmedName) {
      onSaveField({ ...currentValues, name: trimmedName });
      setIsEditChecklistTitle(false);
    }
  };

  return (
    <Stack direction="column" gap={3}>
      <Stack flexDirection="row" alignItems="center">
        {isEditMode ? (
          <TextInput
            autoFocus
            required
            label={t('queries.checklistsEditDialog.checklistLabel')}
            control={control}
            id="name"
            name="name"
            labelSx={{ fontWeight: 'fontWeightBold' }}
            translate="project"
            onBlur={handleSubmit(handleSaveField)}
            onKeyDown={event => {
              if (event.code !== Key.Enter) return;
              event.preventDefault();
              handleSaveField();
            }}
          />
        ) : (
          <Stack
            flexDirection="row"
            alignItems="center"
            gap={1}
            sx={{
              width: 'auto',
              overflow: 'hidden',
              '&:hover .ProjectForm__title-edit': { opacity: 1 },
            }}
          >
            <Typography sx={{ fontSize: 'h2.fontSize', fontWeight: 'bold', color: palette.controls.label }}>{name}</Typography>

            <IconButton onClick={() => setIsEditChecklistTitle(true)} className="ProjectForm__title-edit" sx={{ opacity: 0 }}>
              <Icon fontSize="small" name="editWithUnderline" sx={{ width: 20, height: 20 }} />
            </IconButton>
          </Stack>
        )}
      </Stack>
      <Questions
        canEdit={canEdit}
        control={control}
        setFocus={setFocus}
        onSaveField={onSaveField}
        handleSubmit={handleSubmit}
        getValues={getValues}
        selectedIds={selectedIds}
        onQuestionToggle={onQuestionToggle}
        onToggleAll={onToggleAll}
        selectedAllIds={selectedAllIds}
        onDeleteQuestions={onDeleteQuestions}
        onUpdateQueryTags={onUpdateQueryTags}
        onDeleteTag={onDeleteTag}
        checklists={checklists}
        selectedQuestions={selectedQuestions}
        setCurrentChecklistId={setCurrentChecklistId}
        currentChecklist={currentChecklist}
      />
    </Stack>
  );
};

export default ChecklistForm;

import { type FC, type PropsWithChildren, useRef, useState } from 'react';
import { Checklist } from '@/api/generated';
import ChecklistsManagerDialog from '@/views/Project/views/Queries/components/EditorChecklistsDialog/ChecklistsManagerDialog';
import {
  ChecklistsManagerDialogContext,
  ChecklistsManagerDialogContextValue,
  ChecklistsManagerDialogMode,
} from '@/contexts/ChecklistsManagerDialogContext';

export const ChecklistsManagerDialogProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isOpened, setIsOpened] = useState(false);
  const resolveRef = useRef<(result: Checklist[] | undefined) => void>(() => false);
  const [mode, setMode] = useState<ChecklistsManagerDialogMode>('default');

  const contextValue: ChecklistsManagerDialogContextValue = {
    showChecklistsManagerDialog: (actionWithMode: ChecklistsManagerDialogMode) => {
      setIsOpened(true);
      setMode(actionWithMode);
      return new Promise<Checklist[] | undefined>(resolve => (resolveRef.current = resolve));
    },
  };

  const onDialogClose = (result?: Checklist[]) => {
    resolveRef.current(result);
    setIsOpened(false);
  };

  return (
    <ChecklistsManagerDialogContext.Provider value={contextValue}>
      <ChecklistsManagerDialog mode={mode} isOpen={isOpened} onClose={() => onDialogClose()} onSelect={onDialogClose} />
      {children}
    </ChecklistsManagerDialogContext.Provider>
  );
};

import { FC, MouseEvent, ReactNode } from 'react';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import Dialog from '@/components/Dialog/Dialog';

export type ConfirmButton = {
  label: string;
  value: string;
};

export type ConfirmDialogTexts = {
  title: ReactNode;
  description?: ReactNode;
  subDescription?: ReactNode;
  confirm?: string;
  cancel?: string;
  buttons?: ConfirmButton[];
};

export type Options = { hideCancel?: boolean };

interface ConfirmDialogProps {
  isOpened?: boolean;
  options?: Options;
  texts: ConfirmDialogTexts;
  onClose: (result?: boolean | string) => void;
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({ isOpened, options, texts, onClose }) => {
  const { palette, typography } = useTheme();
  const { hideCancel = false } = options ?? {};
  const { title, description, subDescription, cancel, confirm, buttons = [{ label: confirm, value: true }] } = texts;

  const handleClose = (event: MouseEvent, result?: boolean | string) => {
    event.stopPropagation();
    onClose(result);
  };

  return (
    <Dialog
      PaperProps={{ sx: { '&.MuiPaper-root': { py: 6, borderRadius: 2 } } }}
      id="confirm-dialog"
      width={572}
      open={!!isOpened}
      onClose={event => handleClose(event as MouseEvent)}
    >
      <Stack gap={3.5}>
        <Typography fontWeight="fontWeightBold" textAlign="center" sx={{ color: palette.grey[800] }}>
          {title}
        </Typography>
        <Stack>
          <Typography variant="body2" textAlign="center" sx={{ color: palette.grey[800] }}>
            {description}
          </Typography>
          {subDescription && (
            <Typography variant="body2" textAlign="center" sx={{ color: palette.grey[800] }}>
              {subDescription}
            </Typography>
          )}
        </Stack>
        <Box sx={{ display: 'flex', justifyContent: 'space-around', gap: 2, mt: 2, px: 3 }}>
          {!hideCancel && (
            <Button
              sx={{ minWidth: 100, py: 1, px: 3, color: palette.secondary.light, fontWeight: typography.fontWeightRegular }}
              color="secondary"
              variant="contained"
              onClick={event => handleClose(event, false)}
            >
              {cancel}
            </Button>
          )}
          {buttons.map(({ label, value }) => (
            <Button
              sx={{ minWidth: 100, py: 1, px: 2 }}
              key={value.toString()}
              variant="contained"
              onClick={event => handleClose(event, value)}
            >
              {label}
            </Button>
          ))}
        </Box>
      </Stack>
    </Dialog>
  );
};

export default ConfirmDialog;

const palette = {
  tags: [
    '#49758F',
    '#AC6F81',
    '#D57BB6',
    '#FDC2CD',
    '#FF00AA',
    '#ED0404',
    '#570BB7',
    '#BCABC6',
    '#808000',
    '#A4C578',
    '#0ACB10',
    '#B8F331',
    '#DA6E0F',
    '#FF9E34',
    '#FFEA00',
    '#FFE4B5',
    '#17DEEE',
    '#1EE8B6',
    '#0088FF',
    '#26A1D5',
  ],
  primary: {
    main: '#EEE6FC',
    dark: '#5209E6',
    light: '#D0D0D0',
  },
  secondary: {
    main: '#F5F5F5',
    light: '#8B8986',
    dark: '#64615E',
    contrastText: '#000',
  },
  success: {
    main: '#BFDAA4AA',
    dark: '#051F20',
  },
  accent: {
    main: '#fea622',
    light: '#FFDBB3',
    dark: '#EB9130',
    contrastText: '#290473',
  },
  error: {
    light: '#ef5350',
    main: '#d32f2f',
    dark: '#c62828',
  },
  text: {
    primary: '#3D3A36',
    secondary: '#000000',
  },
  background: {
    default: '#FFFFFF',
    paper: '#FCFAFE',
    selection: 'rgba(35, 131, 226, 0.28)',
  },
  grey: {
    0: '#FFFFFF',
    25: '#F4F4F4',
    50: '#F5F5F5',
    100: '#ECEBEB',
    200: '#E1E1E1',
    300: '#C5C4C3',
    400: '#B8BCCA',
    500: '#D8D8D7',
    600: '#D9D9D9',
    700: '#777572',
    800: '#64615E',
    900: '#504E4A',
    A100: '#54595E',
    A200: '#484C52',
    A400: '#504E4A',
  },
  warning: {
    main: '#E1E1E1',
    light: '#FFD8AE',
    dark: '#FFBCB3',
  },
  action: {
    hover: '#EEE6FC',
    selected: '#5209E6',
  },
  controls: {
    label: '#3A3A3A',
    text: '#6F7482',
    background: '#F8FAFC',
    border: 'rgba(0, 0, 0, 0.23)',
  },
  darkPurple: {
    main: '#DCCEFA',
    dark: '#8653ED',
    light: '#DCCEFA',
  },
  darkerPurple: {
    main: '#A884F3',
    dark: '#3906A1',
    light: '#CBB5F7',
  },
  mid: {
    main: '#FFD8AE',
  },
  low: {
    main: '#E1E1E1',
  },
  high: {
    main: '#FFBCB3',
    dark: '#FF7130',
    contrastText: '#FF7130',
  },
  chat: {
    main: '#3D3D3D',
    light: '#666668',
  },
  likes: {
    main: '#27B376',
    dark: '#E53030',
  },
  feedback: {
    main: '#FF9E34',
    dark: '#290473',
    light: '#61C423',
  },
  feedbackLike: {
    main: '#409e3d',
    dark: '#b80e0e',
  },
  pink: {
    main: '#FF3C68',
    light: '#FF3C68',
    contrastText: '#FF3C68',
  },
  purple: {
    main: '#c33480',
    light: '#C497CC',
    dark: '#872B97',
    contrastText: '#872B97',
  },
};
export default palette;

import { Theme } from '@mui/material/styles';

const styles = (theme: Theme) => ({
  html: { overflow: 'hidden' },
  body: { height: '100%', overflow: 'auto' },
  '*::selection': { background: theme.palette.background.selection },
  '.pac-container': { zIndex: 40000 },

  '.mentions--multiLine': {
    width: 'calc(100% - ((8px * 2) + 4px + (9px * 2) + 16px + 16px + 10px ))', // it's the calculation of the size and spacing of the input icon's button
    backgroundColor: 'transparent',
  },
  '.mentions__control': {
    fontSize: theme.typography.body2.fontSize,
    fontFamily: theme.typography.fontFamily,
    minHeight: 38,
    backgroundColor: 'transparent',
  },
  '.mentions__highlighter': {
    padding: theme.spacing(1.5, 2),
    border: 'none !important',
    lineHeight: theme.typography.body2.fontSize,

    '& strong': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      padding: 0.5,
      lineHeight: theme.typography.body2.fontSize,
    },
  },
  '.mentions--multiLine .mentions__input': {
    padding: theme.spacing(1.5, 2),
    border: 'none',
    borderRadius: 20,
    backgroundColor: 'transparent',
    color: theme.palette.chat.light,
    lineHeight: theme.typography.body2.fontSize,
    '&:focus-visible': {
      outline: 'none',
    },
    '&::placeholder': {
      color: theme.palette.chat.light,
      whiteSpace: 'nowrap',
    },
  },
  '.mentions__suggestions': {
    borderRadius: 8,
    boxShadow: theme.shadows[1],
    border: `1px solid ${theme.palette.grey[200]}`,
    '& .mentions__suggestions__list': {
      overflow: 'auto',
      height: 'auto',
      backgroundColor: theme.palette.background.default,
      '& .mentions__suggestions__item': {
        fontSize: theme.typography.body2.fontSize,
        padding: theme.spacing(1.5, 2),
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        color: theme.palette.chat.light,
        '&:last-child': {
          borderBottom: 'none',
        },

        '&.mentions__suggestions__item--focused': {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  },
  '.slick-list::before': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    boxShadow: '40px 0px 30px -30px #fff inset, -40px 0px 30px -30px #fff inset',
  },
  '.slick-track': {
    display: 'flex',
    whiteSpace: 'pre-line',
  },
  '.slick-slide': {
    flex: '0 0 auto',
    minWidth: 0,
    float: 'none',
    '> div': { height: '100%' },
  },
  '.slick-arrow': {
    background: theme.palette.primary.dark,
    '&:hover, &:focus': {
      background: theme.palette.primary.dark,
    },
  },
  '.picker.picker-dialog, .picker.picker-dialog-bg': {
    zIndex: 5000,
  },
});

export default styles;

import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppBar, Box, Button, Stack, Toolbar, Tooltip, useTheme } from '@mui/material';
import Logo from '@/assets/logo.svg?react';
import { ROUTER_IDS, toHome } from '@/services/linker';
import UserDropdown from '@/components/Header/UserDropdown/UserDropdown';
import FeedbackDialog from '@/views/Feedback/FeedbackDialog';
import { useOrganization } from '@/hooks/useOrganization';
import OpenKnowledge from '@/views/Knowledge/OpenKnowledge';
import useRouteId from '@/hooks/useRouteId';

export const headerId = 'header';

const Header: FC = () => {
  const { t } = useTranslation('common');
  const routeId = useRouteId();
  const { spacing } = useTheme();

  const { organization } = useOrganization();
  const [openFeedbackModal, setOpenFeedbackModal] = useState<boolean>(false);

  return (
    <AppBar position="sticky" sx={{ top: 0, opacity: 1, zIndex: 999, borderRadius: 0 }} id={headerId}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', height: 78 }}>
        <Stack direction="row" alignItems="center" gap={3}>
          <Tooltip arrow title={t('tooltips.pellesLogo')}>
            <Link to={toHome()} style={{ textDecoration: 'none', height: '39px' }}>
              <Logo style={{ height: '100%' }} />
            </Link>
          </Tooltip>
          <Box id="header-main-slot" sx={{ '&:empty': { display: 'none' } }} />
        </Stack>
        <Stack direction="row" alignItems="center" gap={2} height="100%">
          <Tooltip arrow title={t('tooltips.share')}>
            <Button variant="contained" color="accent" onClick={() => setOpenFeedbackModal(true)} sx={{ borderRadius: 999 }}>
              {t('header.feedback')}
            </Button>
          </Tooltip>
          {routeId !== ROUTER_IDS.ORG_KB && <OpenKnowledge />}
          <Box id="header-actions-slot" sx={{ '&:empty': { display: 'none' } }} />
          <UserDropdown />
          <img
            src={organization.logo.url}
            width={organization.logo.width ?? 50}
            height={organization.logo.height ?? 50}
            style={{ maxWidth: 76, maxHeight: '100%', margin: spacing(0.75, 1.5) }}
            alt={t('header.systemLogo')}
          />
        </Stack>
      </Toolbar>

      <FeedbackDialog onClose={() => setOpenFeedbackModal(false)} isOpen={openFeedbackModal} />
    </AppBar>
  );
};

export default Header;

import { Box, Stack, SvgIcon, SxProps, Typography, useTheme } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Descope } from '@descope/react-sdk';
import DropZoneArea from '@/components/DropZoneArea';
import { acceptedFileTypes } from '@/utils/consts';
import { DocumentMetadata } from '@/api/generated';
import UploadIcon from '@/assets/upload-icon.svg?react';
import DialogCloseButtonArrow from '@/components/Dialog/DialogCloseButtonArrow';
import { DocumentVersionNode, ImportDriveFilesFn } from '@/views/Projects/components/ProjectFormDialog/types';

import { useDrivesImport } from '@/views/Projects/components/ProjectFormDialog/hooks/useDrivesImport';
import { isSocialLoginError } from '@/views/Projects/components/ProjectFormDialog/utils/isSocialLoginError';

import DropdownMenu from './DropdownMenu';
import Icon from '@/components/Icon/Icon';
import GoogleDriveIcon from '@/assets/google-drive.svg?react';
import OneDriveIcon from '@/assets/onedrive.svg?react';
import AutodeskIcon from '@/assets/autodesk.svg?react';
import ProcoreIcon from '@/assets/procore.svg?react';
import SharePointIcon from '@/assets/sharepoint.svg?react';
import VersionsTable from './VersionTable/VersionsTable';

interface DocumentUploadProps {
  sx?: SxProps;
  document: DocumentMetadata;
  versionsNodes: DocumentVersionNode[];
  uploadVersion: (files: File[]) => void;
  importDriveFiles: ImportDriveFilesFn;
  onVersionClick: (documentId: string) => void;
  onClose: () => void;
  deleteDocumentVersion: (documentVersionId: string) => Promise<void>;
  setAsMainDocumentVersion: (documentVersionId: string) => Promise<void>;
}

const VersionsUpload: FC<DocumentUploadProps> = ({
  sx,
  document,
  versionsNodes,
  uploadVersion,
  importDriveFiles,
  onVersionClick,
  onClose,
  deleteDocumentVersion,
  setAsMainDocumentVersion,
}) => {
  const { t } = useTranslation('projectUpdate');
  const { palette } = useTheme();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: uploadVersion,
    accept: acceptedFileTypes,
    multiple: false,
  });
  const { onClick: onComputerClick, ...rootProps } = getRootProps();

  const { redirectUrl, errorContext, onOneDriveClick, onGoogleDriveClick, onAutodeskDriveClick, onSharePointDriveClick } =
    useDrivesImport({
      importDriveFiles,
      documentId: document._id,
    });

  return (
    <Stack
      sx={{
        ...sx,
        position: 'relative',
        minWidth: 370,
        minHeight: 0,
        height: '100%',
        p: 3,
        overflow: 'auto',
        borderRadius: 4,
        boxShadow: 6,
      }}
    >
      {errorContext && errorContext.type === 'google' && redirectUrl && isSocialLoginError(errorContext.error) && (
        <Descope flowId="re-auth-google" redirectUrl={redirectUrl} />
      )}
      {errorContext && errorContext.type === 'onedrive' && redirectUrl && isSocialLoginError(errorContext.error) && (
        <Descope flowId="re-auth-microsoft" redirectUrl={redirectUrl} />
      )}
      {errorContext && errorContext.type === 'autodesk' && redirectUrl && isSocialLoginError(errorContext.error) && (
        <Descope flowId="re-auth-autodesk" redirectUrl={redirectUrl} />
      )}

      <DialogCloseButtonArrow onClick={() => onClose()} />

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Icon name="version" fontSize="large" />
        {t('uploadFiles.versionsTitle')}
      </Box>

      <VersionsTable
        deleteDocumentVersion={deleteDocumentVersion}
        setAsMainDocumentVersion={setAsMainDocumentVersion}
        document={document}
        versionsNodes={versionsNodes}
        onVersionClick={onVersionClick}
      />

      <DropZoneArea sx={{ mt: 2 }} isDragActive={isDragActive} {...rootProps}>
        <input {...getInputProps()} />
        <Stack alignItems="center" p={2} gap={0.7}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, minHeight: '2.25rem' }}>
            <SvgIcon inheritViewBox component={UploadIcon} sx={{ fontSize: 28 }} />
            <Typography
              sx={{
                color: palette.grey['800'],
                fontSize: 'body3.fontSize',
                fontWeight: 700,
              }}
            >
              {t('uploadFiles.versionsDropzone')}
            </Typography>
          </Box>
          <Typography
            sx={{
              color: palette.grey['800'],
              fontSize: 'body4.fontSize',
              fontWeight: 700,
              pb: 0.5,
            }}
          >
            {t('uploadFiles.divider')}
          </Typography>
          <DropdownMenu
            items={[
              {
                label: t('uploadFiles.cloudsDropdownList.computer'),
                onClick: onComputerClick,
                icon: <Icon name="computer" fontSize="medium" htmlColor={palette.primary.dark} />,
              },
              {
                label: t('uploadFiles.cloudsDropdownList.google'),
                onClick: onGoogleDriveClick,
                icon: <GoogleDriveIcon width={22} height={22} />,
                disabled: true,
              },
              {
                label: t('uploadFiles.cloudsDropdownList.sharepoint'),
                onClick: onSharePointDriveClick,
                icon: <SharePointIcon width={22} height={22} />,
              },
              {
                label: t('uploadFiles.cloudsDropdownList.onedrive'),
                onClick: onOneDriveClick,
                icon: <OneDriveIcon width={22} height={22} />,
              },
              {
                label: t('uploadFiles.cloudsDropdownList.autodesk'),
                onClick: onAutodeskDriveClick,
                icon: <AutodeskIcon width={22} height={22} />,
              },
              {
                label: t('uploadFiles.cloudsDropdownList.procore'),
                icon: <ProcoreIcon width={22} height={22} />,
                disabled: true,
              },
            ]}
          />
        </Stack>
      </DropZoneArea>
    </Stack>
  );
};

export default VersionsUpload;

import { FC } from 'react';
import { GoogleMap, Marker, Libraries, useLoadScript } from '@react-google-maps/api';
import Loader from '../Loader/Loader';
import { CSSProperties } from '@mui/material/styles/createMixins';

interface Location {
  lat: number;
  lng: number;
}
interface MapProps {
  center: Location;
  containerStyle?: CSSProperties;
}

const LIBRARIES: Libraries = ['places'];

const Map: FC<MapProps> = ({
  center,
  containerStyle = {
    width: '400px',
    height: '400px',
    borderRadius: '0.5rem',
    overflow: 'hidden',
  },
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAP_KEY,
    libraries: LIBRARIES,
  });

  if (loadError) return 'Unable to load map';
  if (!isLoaded) return <Loader id="google-map" />;

  return (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
      <Marker position={center} />
    </GoogleMap>
  );
};

Map.displayName = 'Map';

export default Map;

import { FC, ReactNode } from 'react';
import { Box, useTheme } from '@mui/material';
import { EDITOR_CONTENT_LEFT_PADDING, PLACEHOLDER_ELEMENT_ID } from '@/containers/PagesEditor/constants';

interface PlaceholderProps {
  top: number;
  children: ReactNode;
}

const Placeholder: FC<PlaceholderProps> = ({ top, children }) => {
  const { palette } = useTheme();

  return (
    <Box
      id={PLACEHOLDER_ELEMENT_ID}
      sx={{
        maxWidth: '100%',
        overflow: 'hidden',
        position: 'absolute',
        top,
        pl: `${EDITOR_CONTENT_LEFT_PADDING}px`,
        zIndex: -1,
        textOverflow: 'ellipsis',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        pointerEvents: 'none',
        color: palette.grey[500],
      }}
    >
      {children}
    </Box>
  );
};

export default Placeholder;

import { Box, Stack, Typography, useTheme } from '@mui/material';
import { MessagesValue } from '@/views/Knowledge/OpenKnowledge';
import { FC } from 'react';

interface MessageProps {
  message: MessagesValue;
}

const Message: FC<MessageProps> = ({ message }) => {
  const { palette, typography } = useTheme();
  const commonStyles = {
    fontSize: typography.body2.fontSize,
    color: palette.grey[800],
  };
  const { question, answer } = message;

  return (
    <Stack>
      <Typography
        sx={{
          ...commonStyles,
          maxWidth: 433,
          alignSelf: 'end',
          backgroundColor: palette.primary.main,
          borderRadius: 3,
          py: 1,
          px: 2,
          fontWeight: typography.fontWeightBold,
          color: palette.grey[700],
          wordBreak: 'break-word',
        }}
      >
        {question}
      </Typography>
      <Typography sx={commonStyles} id="answer">
        <Box dangerouslySetInnerHTML={{ __html: answer ?? '' }} />
      </Typography>
    </Stack>
  );
};

export default Message;

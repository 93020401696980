import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import { updateProjectCache } from '@/utils/updateProjectCache';
import { deleteFilesystemBatch } from '@/api/generated';

type Params = {
  fileIds?: string[];
  folderIds?: string[];
  projectSlug: string;
  updateUrl?: boolean;
};

export const useDeleteFilesAndFolders = (queryClient: QueryClient) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const deletedIds = useRef(new Set<string>());

  return async ({ folderIds = [], fileIds = [], projectSlug, updateUrl = true }: Params) => {
    fileIds.forEach(fileIdToDelete => deletedIds.current.add(fileIdToDelete));
    const updatedFileSystem = await deleteFilesystemBatch(projectSlug!, [...folderIds, ...fileIds]);

    updateProjectCache({ queryClient, projectSlug: projectSlug! }, prevProject => {
      if (!prevProject) return;

      const updatedDocuments = prevProject.documents?.filter(({ _id }) => !deletedIds.current.has(_id!)) ?? [];
      const isDeletedDocumentOpened = deletedIds.current.has(searchParams.get('documentId')!);

      setSearchParams(prevParams => {
        if (!isDeletedDocumentOpened || !updateUrl) return prevParams;

        const nextParams = new URLSearchParams(prevParams);

        const nextDocumentToLoad = updatedDocuments.find(document => !deletedIds.current.has(document._id!));
        if (nextDocumentToLoad) {
          nextParams.set('documentId', nextDocumentToLoad._id!);
        } else {
          nextParams.delete('documentId');
        }

        return nextParams;
      });

      return {
        ...prevProject!,
        defaultDocumentId:
          prevProject!.defaultDocumentId && deletedIds.current.has(prevProject!.defaultDocumentId)
            ? undefined
            : prevProject!.defaultDocumentId,
        documents: updatedDocuments,
        filesystem: updatedFileSystem,
      };
    });

    return updatedFileSystem;
  };
};

import { Box, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { createFeedback } from '@/api/generated';
import SubmitButton from '@/components/SubmitButton/SubmitButton';
import TextInput from '@/components/TextInput/TextInput';
import Dialog from '@/components/Dialog/Dialog';
import DialogCloseButton from '@/components/Dialog/DialogCloseButton';

interface FeedbackProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ValidationSchema = z.object({
  text: z.string(),
});

export type FormFeedback = z.infer<typeof ValidationSchema>;

const FeedbackDialog: FC<FeedbackProps> = ({ onClose, isOpen }) => {
  const { palette, typography, shape } = useTheme();
  const { t } = useTranslation('feedback');

  const { handleSubmit, control, reset } = useForm<FormFeedback>({
    mode: 'onSubmit',
    resolver: zodResolver(ValidationSchema),
  });

  const onSubmit = (data: FormFeedback) => {
    createFeedback(data);
    reset();
    onClose();
  };

  return (
    <Dialog open={isOpen} width={600}>
      <DialogCloseButton onClick={onClose} />
      <Typography
        variant="h4"
        sx={{
          color: palette.grey[900],
          textAlign: 'center',
          fontWeight: typography.fontWeightBold,
        }}
      >
        {t('title')}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: palette.grey[900],
          textAlign: 'center',
          mt: 1,
          fontSize: typography.body2.fontSize,
          fontWeight: typography.fontWeightLight,
        }}
      >
        {t('description')}
      </Typography>
      <Box component="form" sx={{ width: '100%', textAlign: 'center', alignContent: 'center' }} onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          label=""
          control={control}
          name="text"
          placeholder={t('textareaPlaceholder')}
          id={'FeedbackText'}
          multiline
          rows={4}
          sx={{
            mt: 2,
            overflow: 'visible',
            borderRadius: shape.borderRadius,
            alignSelf: 'center',
            '& .MuiInputBase-root': { border: `1px solid ${palette.grey[600]}` },
          }}
          inputProps={{
            sx: {
              px: 1,
              py: 1,
            },
          }}
          variant="outlined"
        />
        <SubmitButton
          sx={{
            backgroundColor: palette.primary.main,
            color: palette.primary.dark,
            fontSize: typography.body2.fontSize,
            fontWeight: typography.fontWeightLight,
            width: '25%',
            mt: 2,
          }}
          isDisabled={false}
        >
          {t('submit')}
        </SubmitButton>
      </Box>
    </Dialog>
  );
};

export default FeedbackDialog;

import { MutableRefObject, useRef } from 'react';
import PSPDFKit, {
  Instance,
  ToolbarItem,
  MeasurementValueConfiguration,
  AnnotationBackendJSON,
  InlineTextSelectionToolbarItem,
} from 'pspdfkit';
import { useTranslation } from 'react-i18next';
import searchIcon from '../icons/search-icon.svg?raw';
import withInstantJsonEnvelop from '@/containers/DocumentEditor/utils/withInstantJsonEnvelop';
import { CustomPreset } from '@/containers/DocumentEditor/utils/presets';
import { isAnnotationsWithPoints } from '@/containers/DocumentEditor/guards/isAnnotationsWithPoints';
import { replaceFractionWithDecimal } from '@/containers/DocumentEditor/utils/replaceFractionWithDecimal';
import { isMeasurementConfigsEqual } from '@/containers/DocumentEditor/utils/isMeasurementConfigsEqual';
import { usePspdfkit } from '@/containers/DocumentEditor/internalHooks/usePspdfkit';

type Configuration = {
  containerRef: MutableRefObject<HTMLDivElement | null>;
  documentUrl?: string | null;
  annotations: AnnotationBackendJSON[];
  isEnabled: boolean;
  onLoad: (instance: Instance) => void;
  onInlineTextSelectionPress: () => void;
};

const EXCLUDE_TOOLBAR_ITEMS = [
  'image',
  'sidebar-bookmarks',
  'sidebar-annotations',
  'sidebar-document-outline',
  'sidebar-thumbnails',
  'export-pdf',
];

const TOOLBAR_ITEMS: ToolbarItem[] = [
  { type: 'layout-config' },
  { type: 'measure' },
  { type: 'redact-rectangle' },
  { type: 'redact-text-highlighter' },
  { type: 'sidebar-thumbnails' },
];

const VIEW_STATES: Record<string, string | number | boolean> = {
  spreadSpacing: 35,
  disablePointSnapping: true,
  canScrollWhileDrawing: true,
  sidebarPlacement: 'END',
};

const toolbarItems: ToolbarItem[] = [
  ...PSPDFKit.defaultToolbarItems.filter(item => !EXCLUDE_TOOLBAR_ITEMS.includes(item.type)),
  ...TOOLBAR_ITEMS,
  { type: 'image' },
  { type: 'sidebar-bookmarks' },
  { type: 'sidebar-annotations' },
  { type: 'sidebar-document-outline' },
];

export const useDocumentEditor = ({
  containerRef,
  documentUrl,
  annotations,
  isEnabled,
  onLoad,
  onInlineTextSelectionPress,
}: Configuration) => {
  const scalesRef = useRef<MeasurementValueConfiguration[]>([]);
  const lastPrevConfigRef = useRef<MeasurementValueConfiguration | null>(null);
  const initialConfig = useRef<MeasurementValueConfiguration | undefined>(undefined);
  const { t } = useTranslation('editor');

  const inlineTextSelectionToolbarItemsWebSearch: InlineTextSelectionToolbarItem = {
    type: 'custom',
    id: 'web-search',
    title: t('webSearch.label'),
    icon: searchIcon,
    onPress: onInlineTextSelectionPress,
  };

  const { isLoading, loadingPercent, instance, instanceRef, unload } = usePspdfkit({
    isEnabled,
    containerRef,
    url: documentUrl,
    instanceConfig: {
      enableClipboardActions: true,
      // Prevents saving annotations with only one point.
      // @see https://app.clickup.com/t/86bzw1emz
      instantJSON: withInstantJsonEnvelop(
        annotations.filter(annotation => !isAnnotationsWithPoints(annotation) || annotation.points.length > 1),
      ),
      initialViewState: PSPDFKit.viewStateFromOpenParameters(new PSPDFKit.ViewState(VIEW_STATES)),
      toolbarItems,
      annotationPresets: {
        ...PSPDFKit.defaultAnnotationPresets,
        distance: { ...PSPDFKit.defaultAnnotationPresets.distance, lineCaps: undefined },
        // We add skip save here because we need user to set settings before (user can press cancel).
        [CustomPreset.autoCounting]: { customData: { specialType: 'autoAnnotationParent', isLocal: true } },
        [CustomPreset.dynamic]: {},
      },
      inlineTextSelectionToolbarItems: ({ defaultItems }) => [...defaultItems, inlineTextSelectionToolbarItemsWebSearch],
      measurementValueConfiguration: configs => {
        const fractionsConfigs = configs.map(config => ({
          ...config,
          scale: {
            ...config.scale,
            fromValue: replaceFractionWithDecimal(config.scale.fromValue),
            toValue: replaceFractionWithDecimal(config.scale.toValue),
          },
        }));
        scalesRef.current = fractionsConfigs.length === 0 && initialConfig.current ? [initialConfig.current] : fractionsConfigs;

        const [changedConfig] =
          scalesRef.current.filter(config => !configs!.find(newConfig => isMeasurementConfigsEqual(newConfig, config))) ?? [];
        const [lastPrevConfig] =
          configs.filter(config => !scalesRef.current!.find(newConfig => isMeasurementConfigsEqual(newConfig, config))) ?? [];

        lastPrevConfigRef.current = lastPrevConfig;
        if (lastPrevConfig) {
          lastPrevConfig.scale.fromValue = changedConfig.scale.fromValue;
          lastPrevConfig.scale.toValue = changedConfig.scale.toValue;
        }

        return scalesRef.current;
      },
    },
    onLoad,
  });

  const setInitialConfig = (value: MeasurementValueConfiguration) => {
    initialConfig.current = value;
    if (!scalesRef.current.length) {
      scalesRef.current = [value];
    }
  };

  return { instance, instanceRef, scalesRef, lastPrevConfigRef, setInitialConfig, isLoading, loadingPercent, unload };
};

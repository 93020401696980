import PSPDFKit, { IInteractionMode, Instance, MeasurementValueConfiguration } from 'pspdfkit';
import { RefObject, useEffect, useRef, useState } from 'react';
import isMeasurementMode from '@/containers/DocumentEditor/utils/isMeasurementMode';

type Params = {
  instance?: Instance;
  scalesRef: RefObject<MeasurementValueConfiguration[]>;
  setInitialConfig: (config: MeasurementValueConfiguration) => void;
};

type ScaleSelectData = {
  interactionMode: IInteractionMode;
  preset?: string | null;
};

export const useScaleSelect = ({ instance, scalesRef, setInitialConfig }: Params) => {
  const scaleSelectDataRef = useRef<ScaleSelectData | undefined>(undefined);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!instance) return;

    instance.addEventListener('viewState.change', (currentViewState, prevViewState) => {
      const isMeasurementToolOpened =
        currentViewState.interactionMode !== prevViewState.interactionMode && isMeasurementMode(currentViewState.interactionMode);
      const isPspdfHasScales = scalesRef.current && scalesRef.current.length > 0;

      if (!isMeasurementToolOpened || isPspdfHasScales) {
        return;
      }

      setIsOpen(true);
      scaleSelectDataRef.current = {
        interactionMode: currentViewState.interactionMode,
        preset: instance.currentAnnotationPreset,
      };
    });
  }, [instance]);

  const closeScaleDialog = () => {
    setIsOpen(false);
    instance?.setViewState(viewState => viewState.set('interactionMode', null));
  };

  const openScalesModal = () => {
    instance?.contentDocument.querySelector<HTMLSelectElement>('.PSPDFKit-Toolbox-MeasurementScale > div')?.click();
  };

  const closeScalesModal = () => {
    instance?.contentDocument.querySelector<HTMLButtonElement>('.PSPDFKit-Confirm-Dialog-Button-Confirm')?.click();
  };

  const addScaleConfig = (config: MeasurementValueConfiguration) => {
    setIsOpen(false);

    if (!instance) return;

    setInitialConfig(config);
    instance.setMeasurementScale(new PSPDFKit.MeasurementScale(config.scale));
    instance.setMeasurementPrecision(config.precision);

    openScalesModal();
    closeScalesModal();
    // First attempt to close triggers PSPDF scales change
    // Second one closes the modal
    closeScalesModal();

    instance.setCurrentAnnotationPreset(scaleSelectDataRef.current?.preset);
    instance.setViewState(viewState => viewState.set('interactionMode', scaleSelectDataRef.current?.interactionMode ?? null));
  };

  return { isScaleDialogOpened: isOpen, closeScaleDialog, addScaleConfig };
};

import { Position } from 'react-rnd';
import { Size } from '@/views/Knowledge/types';

export const MIN_WIDTH = 830;
export const MIN_HEIGHT = 490;

export const recalculateSafePositionForCollapsed = (size: Size, position: Position) => {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const zeroPoint = 0; // (-1 * screenWidth) / 2;

  let nextPosition = { ...position };
  let nextSize = {
    width: Math.max(size.width, MIN_WIDTH),
    height: Math.max(size.height, MIN_HEIGHT),
  };

  const isMoreWindowWidth = nextSize.width > screenWidth;
  const isMoreWindowHeight = nextSize.height > screenHeight;

  if (isMoreWindowWidth || isMoreWindowHeight) {
    nextPosition = {
      x: isMoreWindowWidth ? zeroPoint : nextPosition.x,
      y: isMoreWindowHeight ? 0 : nextPosition.y,
    };
    nextSize = {
      width: isMoreWindowWidth ? screenWidth : nextSize.width,
      height: isMoreWindowHeight ? screenHeight : nextSize.height,
    };
  }

  const { x, y } = nextPosition;
  const { width, height } = nextSize;
  const isOverRightWindowSide = x + width - zeroPoint > screenWidth;
  const isOverBottomWindowSide = y + height > screenHeight;

  if (isOverRightWindowSide || isOverBottomWindowSide) {
    // Moves await away from right or bottom window corners.
    nextPosition.x = isOverRightWindowSide ? screenWidth - width + zeroPoint : x;
    nextPosition.y = isOverBottomWindowSide ? screenHeight - height : y;
  }

  const isWidthLessMin = width < MIN_WIDTH;
  const isHeightLessMin = height < MIN_HEIGHT;

  if (isWidthLessMin || isHeightLessMin) {
    nextSize.width = isWidthLessMin && window.innerWidth < MIN_WIDTH ? window.innerWidth : nextSize.width;
    nextSize.height = isHeightLessMin && window.innerHeight < MIN_HEIGHT ? window.innerHeight : nextSize.height;
  }

  nextPosition = {
    x: nextPosition.x < zeroPoint ? zeroPoint : nextPosition.x,
    y: nextPosition.y < 0 ? 0 : nextPosition.y,
  };
  return { position: nextPosition, size: nextSize };
};

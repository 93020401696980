import { forwardRef, ReactNode, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { EditorState, LexicalEditor } from 'lexical';
import { Box, SxProps } from '@mui/material';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { ClickableLinkPlugin } from '@lexical/react/LexicalClickableLinkPlugin';
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HashtagPlugin } from '@lexical/react/LexicalHashtagPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import AutoEmbedPlugin from '@lexical/playground/plugins/AutoEmbedPlugin';
import AutoLinkPlugin from '@lexical/playground/plugins/AutoLinkPlugin';
import CodeHighlightPlugin from '@lexical/playground/plugins/CodeHighlightPlugin';
import CollapsiblePlugin from '@lexical/playground/plugins/CollapsiblePlugin';
import DraggableBlockPlugin from '@lexical/playground/plugins/DraggableBlockPlugin';
import FigmaPlugin from '@lexical/playground/plugins/FigmaPlugin';
import FloatingLinkEditorPlugin from '@lexical/playground/plugins/FloatingLinkEditorPlugin';
import KeywordsPlugin from '@lexical/playground/plugins/KeywordsPlugin';
import { LayoutPlugin } from '@lexical/playground/plugins/LayoutPlugin/LayoutPlugin';
import LinkPlugin from '@lexical/playground/plugins/LinkPlugin';
import ListMaxIndentLevelPlugin from '@lexical/playground/plugins/ListMaxIndentLevelPlugin';
import MentionsPlugin from '@lexical/playground/plugins/MentionsPlugin';
import PageBreakPlugin from '@lexical/playground/plugins/PageBreakPlugin';
import TabFocusPlugin from '@lexical/playground/plugins/TabFocusPlugin';
import TableCellActionMenuPlugin from '@lexical/playground/plugins/TableActionMenuPlugin';
import TableCellResizer from '@lexical/playground/plugins/TableCellResizer';
import TwitterPlugin from '@lexical/playground/plugins/TwitterPlugin';
import YouTubePlugin from '@lexical/playground/plugins/YouTubePlugin';
import { TableContext } from '@lexical/playground/plugins/TablePlugin';
import { SharedHistoryContext, useSharedHistoryContext } from '@lexical/playground/context/SharedHistoryContext';
import ContentEditable from '@/containers/PagesEditor/ui/ContentEditable';
import ExcalidrawPlugin from '@/containers/PagesEditor/plugins/ExcalidrawPlugin';
import FloatingTextFormatToolbarPlugin from '@/containers/PagesEditor/plugins/FloatingTextFormatToolbarPlugin/FloatingTextFormatToolbarPlugin';
import ComponentPickerPlugin from '@/containers/PagesEditor/plugins/ComponentPickerPlugin';
import Nodes from '@/containers/PagesEditor/nodes/Nodes';
import EditorTheme from '@/containers/PagesEditor/themes/EditorTheme';
import { AskAiFn, Block } from '@/containers/PagesEditor/types';
import AskAiPlugin from '@/containers/PagesEditor/plugins/AskAiPlugin/AskAiPlugin';
import PlaceholderPlugin from '@/containers/PagesEditor/plugins/PlaceholderPlugin/PlaceholderPlugin';
import { PAGES_TRANSFORMERS } from '@/containers/PagesEditor/transformers';
import { $convertToMarkdownString } from '@lexical/markdown';
import { MentionType, Question } from '@/api/generated';

import './PageEditor.css';
import { SuggestionDataItem } from '@/utils/getMentionTypeOptions';
import { AiMenuItemProps } from '@/components/Pages/Pages';

interface PagesEditorProps {
  sx?: SxProps;
  shellSx?: SxProps;
  initialState?: Block[];
  placeholder?: string;
  children?: ReactNode;
  askAi: AskAiFn;
  isReadOnly?: boolean;
  getSuggestions: (selectedText?: string) => Promise<Question[]>;
  onChange: (content: Block[], markdown: string) => void;
  getMentions: (type: MentionType) => SuggestionDataItem[];
  width: string | number;
  minMaxHeight: number;
  aiMenuItems: AiMenuItemProps[];
}

const PagesEditor = forwardRef<LexicalEditor | null, PagesEditorProps>(
  (
    {
      sx,
      shellSx,
      askAi,
      getSuggestions,
      initialState,
      placeholder,
      children,
      isReadOnly,
      onChange,
      getMentions,
      width,
      minMaxHeight,
      aiMenuItems,
    },
    editorRef,
  ) => {
    const internalEditorRef = useRef<LexicalEditor | null>(null);

    const editorState = useMemo(
      () =>
        initialState?.length
          ? JSON.stringify({
              root: { children: initialState, direction: 'ltr', format: '', indent: 0, type: 'root', version: 1 },
            })
          : null,
      [],
    );

    const initialConfig: InitialConfigType = {
      namespace: 'PellesEditor',
      nodes: [...Nodes],
      theme: EditorTheme,
      editable: !isReadOnly,
      editorState,
      onError: console.error,
    };

    const { historyState } = useSharedHistoryContext();
    const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);
    const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);

    useImperativeHandle(editorRef, () => internalEditorRef.current!);

    useEffect(() => {
      internalEditorRef.current?.setEditable(!isReadOnly);
    }, [isReadOnly, internalEditorRef]);

    const onRef = (_floatingAnchorElem: HTMLDivElement) => {
      if (_floatingAnchorElem !== null) {
        setFloatingAnchorElem(_floatingAnchorElem);
      }
    };

    const handleChange = (nextEditorState: EditorState) => {
      nextEditorState.read(() => {
        onChange(nextEditorState.toJSON().root.children, $convertToMarkdownString(PAGES_TRANSFORMERS));
      });
    };

    return (
      <LexicalComposer initialConfig={initialConfig}>
        <SharedHistoryContext>
          <TableContext>
            <Box className="editor-shell" sx={{ position: 'relative', overflow: 'auto', ...shellSx }}>
              <RichTextPlugin
                contentEditable={
                  <ContentEditable ref={onRef} sx={sx}>
                    {children}
                  </ContentEditable>
                }
                placeholder={null}
                ErrorBoundary={LexicalErrorBoundary}
              />
              {floatingAnchorElem && (
                <>
                  <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
                  {!isReadOnly && placeholder && <PlaceholderPlugin text={placeholder} anchorElem={floatingAnchorElem} />}
                  <FloatingLinkEditorPlugin
                    anchorElem={floatingAnchorElem}
                    isLinkEditMode={isLinkEditMode}
                    setIsLinkEditMode={setIsLinkEditMode}
                  />
                  <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} cellMerge={true} />
                  <FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem} setIsLinkEditMode={setIsLinkEditMode} />
                  <AskAiPlugin
                    anchorElem={floatingAnchorElem}
                    askAi={askAi}
                    getSuggestions={getSuggestions}
                    getMentions={getMentions}
                    width={width}
                    minMaxHeight={minMaxHeight}
                    aiMenuItems={aiMenuItems}
                  />
                </>
              )}
              <EditorRefPlugin editorRef={internalEditorRef} />
              <OnChangePlugin ignoreSelectionChange ignoreHistoryMergeTagChange onChange={handleChange} />
              <MarkdownShortcutPlugin transformers={PAGES_TRANSFORMERS} />
              <TablePlugin hasCellMerge hasCellBackgroundColor />
              <ClearEditorPlugin />
              <ComponentPickerPlugin />
              <AutoEmbedPlugin />
              <MentionsPlugin />
              <HashtagPlugin />
              <KeywordsPlugin />
              <AutoLinkPlugin />
              <ExcalidrawPlugin />
              <HistoryPlugin externalHistoryState={historyState} />
              <CodeHighlightPlugin />
              <ListPlugin />
              <CheckListPlugin />
              <ListMaxIndentLevelPlugin maxDepth={7} />
              <TableCellResizer />
              <LinkPlugin />
              <TwitterPlugin />
              <YouTubePlugin />
              <FigmaPlugin />
              <ClickableLinkPlugin />
              <HorizontalRulePlugin />
              <TabFocusPlugin />
              <TabIndentationPlugin />
              <CollapsiblePlugin />
              <PageBreakPlugin />
              <LayoutPlugin />
            </Box>
          </TableContext>
        </SharedHistoryContext>
      </LexicalComposer>
    );
  },
);

export default PagesEditor;

import { FC, useMemo, useState } from 'react';
import { Box, ClickAwayListener, LinearProgress, Paper, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';
import { useAppSelector } from '@/store';
import { selectUploadProgress } from '@/store/uploadSlice';
import FabButton from '@/components/FabMenu/FabButton';
import uploadAnimation from '@/assets/upload-animation.json';
import Icon from '@/components/Icon/Icon';

const locationSx = {
  position: 'fixed',
  zIndex: 1000,
  right: 25,
};

const GlobalDocumentsUpload: FC = () => {
  const { palette } = useTheme();
  const { t } = useTranslation('common');

  const uploadProgressState = useAppSelector(selectUploadProgress);

  const [isOpen, setIsOpen] = useState(false);

  const { total, loaded } = useMemo(() => {
    const allFiles = Object.values(uploadProgressState).flatMap(projectState => Object.values(projectState));
    const loadedFiles = allFiles.filter(file => file.progress === 100);

    return { total: allFiles.length, loaded: loadedFiles.length };
  }, [uploadProgressState]);

  if (total === 0) return null;

  return (
    <>
      <FabButton
        size="large"
        sx={{ ...locationSx, bottom: 25, '&:hover': { backgroundColor: palette.background.default } }}
        onClick={() => setIsOpen(state => !state)}
      >
        {isOpen ? (
          <Icon name="plus" fontSize="large" sx={{ transform: 'rotate(45deg)' }} />
        ) : total === loaded ? (
          <Icon name="documentUploads" sx={{ width: 32, height: 32 }} />
        ) : (
          <Box component={Lottie} animationData={uploadAnimation} loop sx={{ height: 50 }} />
        )}
      </FabButton>

      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <Paper
            sx={{
              ...locationSx,
              bottom: 100,
              width: 200,
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              p: 2,
              pt: 1,
            }}
          >
            <Stack
              sx={{
                position: 'relative',
                maxHeight: 100,
                gap: 1.5,
                mt: 1,
                pb: 1,
                overflow: 'auto',
                scrollbarWidth: 'thin',
                '&::before': { content: '""', position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, boxShadow: 20 },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ fontSize: 'body2.fontSize', color: palette.grey[800] }}>{t('documentsStatus.uploadsTitle')}</Box>
                <Box sx={{ fontSize: 'body2.fontSize', color: palette.grey[800] }}>
                  ({total}/{loaded})
                </Box>
              </Box>
              <LinearProgress sx={{ height: 6, borderRadius: 999 }} value={(loaded / total) * 100} variant="determinate" />
            </Stack>
          </Paper>
        </ClickAwayListener>
      )}
    </>
  );
};

export default GlobalDocumentsUpload;

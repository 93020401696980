import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import cloneDeep from 'lodash/cloneDeep';
import { AnnotationBackendJSON } from 'pspdfkit';
import { useDocuments } from '@/views/Project/hooks/useDocuments';
import { useProject } from '@/views/Project/hooks/useProject';
import { useQueries } from '@/views/Project/hooks/useQueries';
import { AnnotationsContext } from '@/views/Project/contexts/AnnotationsContext';
import { useAppDispatch, useAppSelector } from '@/store';
import { selectAnnotations, selectEquipments, selectLoadingState } from '@/store/pdfEditorSlice';
import { fetchAnnotationsAndEquipments } from '@/store/pdfEditorThunks';

interface AnnotationsProviderProps {
  children: React.ReactNode;
}

const AnnotationsProvider: FC<AnnotationsProviderProps> = ({ children }) => {
  const { t } = useTranslation('project');
  const { projectSlug } = useProject();
  const { documentId } = useDocuments();
  const { selectedMatch } = useQueries();
  const dispatch = useAppDispatch();
  const stateDocumentAnnotations = useAppSelector(selectAnnotations(projectSlug, documentId));
  const equipmentList = useAppSelector(state => selectEquipments(state, projectSlug, documentId));
  const { isLoading, error } = useAppSelector(selectLoadingState(projectSlug, documentId));

  const documentAnnotationsRef = useRef<AnnotationBackendJSON[]>([]);
  const [hiddenAnnotationsIds, setHiddenAnnotations] = useState<string[]>([]);
  const [highlightedAnnotationsIds, setHighlightedAnnotationsIds] = useState<string[]>([]);

  // We do this because we need to allow to modify annotations for pspdf.
  const documentAnnotations = useMemo(
    () => stateDocumentAnnotations.map(cloneDeep<AnnotationBackendJSON>),
    [stateDocumentAnnotations],
  );

  useEffect(() => {
    if (!projectSlug || !documentId) return;
    dispatch(fetchAnnotationsAndEquipments({ slug: projectSlug, documentId }));
  }, [projectSlug, documentId]);

  useEffect(() => {
    documentAnnotationsRef.current = documentAnnotations;
  }, [documentAnnotations]);

  useEffect(() => {
    if (!error) return;
    enqueueSnackbar(t('annotationsLoadError'), { variant: 'error', autoHideDuration: 5000 });
  }, [error]);

  const annotations = useMemo(() => {
    const lockedMatchAnnotations = ((selectedMatch?.annotations || []) as AnnotationBackendJSON[]).map(annotation => ({
      ...annotation,
      flags: ['locked' as const],
    }));
    return [documentAnnotations, lockedMatchAnnotations].filter(annotation => !!annotation).flat();
  }, [selectedMatch, documentAnnotations]);

  const hideAnnotations = (annotationIdOrIds: string | Array<string>) => {
    const annotationIds = typeof annotationIdOrIds === 'string' ? [annotationIdOrIds] : annotationIdOrIds;
    setHiddenAnnotations(prevAnnotationIds => [...prevAnnotationIds, ...annotationIds]);
  };

  const showAnnotations = (annotationIdOrIds: string | Array<string>) => {
    const annotationIds = typeof annotationIdOrIds === 'string' ? [annotationIdOrIds] : annotationIdOrIds;
    setHiddenAnnotations(hiddenAnnotationsIds.filter(id => !annotationIds.includes(id)));
  };

  const value = useMemo(
    () => ({
      annotations,
      isAnnotationsFailedToLoad: !!error,
      documentAnnotationsRef,
      equipmentList,
      isLoading,
      hideAnnotations,
      showAnnotations,
      hiddenAnnotationsIds,
      highlightAnnotations: setHighlightedAnnotationsIds,
      highlightedAnnotationsIds,
    }),
    [annotations, equipmentList, error, isLoading, hiddenAnnotationsIds, highlightedAnnotationsIds],
  );

  return <AnnotationsContext.Provider value={value}>{children}</AnnotationsContext.Provider>;
};

export default AnnotationsProvider;

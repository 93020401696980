import { Stack, StackProps, useTheme } from '@mui/material';
import { forwardRef } from 'react';
import { DropzoneState } from 'react-dropzone';

interface FilesDropZoneProps extends StackProps {
  isDragActive: DropzoneState['isDragActive'];
}

const DropZoneArea = forwardRef<HTMLDivElement, FilesDropZoneProps>(({ isDragActive, sx, ...props }, ref) => {
  const { palette } = useTheme();

  return (
    <Stack
      ref={ref}
      justifyContent="center"
      alignItems="center"
      sx={{
        zIndex: 1000,
        borderRadius: 4,
        border: `1px dashed ${isDragActive ? palette.darkPurple.dark : palette.darkPurple.light}`,
        boxShadow: 6,
        backgroundColor: palette.background.default,
        ...sx,
      }}
      {...props}
    />
  );
});

export default DropZoneArea;

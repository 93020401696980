import { FC } from 'react';
import { ButtonBase, ButtonBaseOwnProps } from '@mui/material';
import Icon from '@/components/Icon/Icon';

interface DialogCloseButtonArrowProps extends ButtonBaseOwnProps {
  onClick: () => void;
}

const DialogCloseButtonArrow: FC<DialogCloseButtonArrowProps> = props => (
  <ButtonBase
    {...props}
    sx={{
      position: 'absolute',
      cursor: 'pointer',
      top: 15,
      right: 15,
      p: 0.5,
      '&, &:hover': {
        boxShadow: 'none',
      },
      ...props.sx,
    }}
  >
    <Icon name="arrowRightDouble" fontSize="large" />
  </ButtonBase>
);

export default DialogCloseButtonArrow;

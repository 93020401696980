import { QueryClient } from '@tanstack/react-query';
import { renameDocument } from '@/api/generated';
import { updateDocumentCache } from '@/utils/updateDocumentCache';

type Params = {
  documentId: string;
  projectSlug: string;
  name: string;
};

export const useRenameDocument =
  (queryClient: QueryClient) =>
  async ({ documentId: documentIdToRename, projectSlug, name }: Params) => {
    const updatedDocument = await renameDocument(projectSlug!, documentIdToRename, { new_name: name });
    updateDocumentCache({ queryClient, documentId: documentIdToRename, projectSlug: projectSlug! }, prevDocument => ({
      ...prevDocument!,
      ...updatedDocument,
      // TODO BE will use ID later instead of file name, so in case url (signed params) won't change delete this.
      url: prevDocument.url,
    }));
    return updatedDocument;
  };

import { useCallback, useEffect, useState } from 'react';
import uniq from 'lodash/uniq';
import PSPDFKit, { AnnotationsUnion, Instance } from 'pspdfkit';
import { ConstructingEquipmentPiece, EquipmentDescriptionSearch, UnitType, type UnitType as UnitTypeType } from '@/api/generated';
import { SetToolCategoryOptions, UpdatePspdfAnnotations } from '@/containers/DocumentEditor/types';
import { useStorageState } from 'react-storage-hooks';
import { updateAnnotationsCategory } from '@/store/pdfEditorThunks';
import { useAppDispatch } from '@/store';
import { useProject } from '@/views/Project/hooks/useProject';
import { useDocuments } from '@/views/Project/hooks/useDocuments';
import { useOrganization } from '@/hooks/useOrganization';

type Params = {
  instance?: Instance;
  selectedAnnotations: AnnotationsUnion[] | null;
  selectedEquipmentItems: ConstructingEquipmentPiece[] | null;
  updatePspdfAnnotations: UpdatePspdfAnnotations;
};

type StoredCategories = Record<UnitTypeType, EquipmentDescriptionSearch | null>;

const initialState = Object.keys(UnitType).reduce((acc, type) => {
  acc[type as UnitTypeType] = null;
  return acc;
}, {} as StoredCategories);

export const useToolbarCategory = ({ instance, selectedAnnotations, selectedEquipmentItems, updatePspdfAnnotations }: Params) => {
  const dispatch = useAppDispatch();
  const { projectSlug } = useProject();
  const { documentId } = useDocuments();
  const { organization } = useOrganization();
  const [lastCategories, setLastCategories] = useStorageState<StoredCategories>(localStorage, `catalog-category`, initialState);
  const [toolbarCategory, setToolbarCategoryState] = useState<EquipmentDescriptionSearch | null>(null);

  useEffect(() => {
    if (!selectedAnnotations || !selectedEquipmentItems) return setToolbarCategoryState(null);

    const uniqueKeys = uniq(selectedAnnotations.map(annotation => annotation.customData?.groupAnnotationKey)) as string[];
    const allHaveSameCategory = uniqueKeys.length === 1;

    setToolbarCategoryState(
      allHaveSameCategory ? { description: selectedEquipmentItems[0].description, equipment_id: uniqueKeys[0] } : null,
    );
  }, [selectedAnnotations, selectedEquipmentItems]);

  const updateSelectedAnnotations = (category: EquipmentDescriptionSearch | null) => {
    if (!category || !selectedAnnotations) return;

    const updatedAnnotations: AnnotationsUnion[] = selectedAnnotations.map(annotation => {
      const customData = annotation.get('customData') ?? {};
      return annotation.set('customData', {
        ...customData,
        groupAnnotationKey: category.equipment_id,
        skipTimer: Date.now(),
      });
    });

    const annotationsBeforeUpdate = selectedAnnotations.map(PSPDFKit.Annotations.toSerializableObject);
    const annotationsToUpdate = annotationsBeforeUpdate.map(annotation => ({
      ...annotation,
      customData: {
        ...annotation.customData,
        groupAnnotationKey: category.equipment_id,
      },
    }));

    dispatch(
      updateAnnotationsCategory({
        slug: projectSlug,
        documentId: documentId!,
        annotations: annotationsToUpdate,
        organizationId: organization.id,
        moveToEquipmentId: category.equipment_id,
        moveFromEquipmentIds: uniq(
          annotationsBeforeUpdate.map(annotation => annotation.customData!.groupAnnotationKey as string),
        ),
      }),
    );

    updatePspdfAnnotations({ eventType: 'update', annotations: updatedAnnotations });
  };

  const saveToolbarCategory = (category: EquipmentDescriptionSearch, { toolType }: SetToolCategoryOptions) => {
    setLastCategories(prevState => ({
      ...prevState,
      [toolType]: category,
    }));
    setToolbarCategoryState(category);
  };

  const setCategoryInsidePreset = useCallback(
    (category: EquipmentDescriptionSearch) => {
      if (!instance) return;

      const prevMode = instance.viewState.interactionMode;
      instance.setViewState(viewState => viewState.set('interactionMode', null));
      instance.setAnnotationPresets(prevPresets => ({
        ...prevPresets,
        dynamic: {
          ...prevPresets.dynamic,
          customData: {
            ...prevPresets.dynamic?.customData,
            groupAnnotationKey: category.equipment_id,
          },
        },
      }));
      instance.setCurrentAnnotationPreset('dynamic');
      instance.setViewState(viewState => viewState.set('interactionMode', prevMode));
    },
    [instance],
  );

  const setToolbarCategory = useCallback(
    (category: EquipmentDescriptionSearch | null, { toolType }: SetToolCategoryOptions) => {
      if (!instance) return;

      const nextCategory = category ?? lastCategories[toolType];
      if (!nextCategory) {
        setToolbarCategoryState(null);
        return null;
      }

      if (selectedAnnotations && selectedEquipmentItems) {
        setToolbarCategoryState(nextCategory);
        updateSelectedAnnotations(nextCategory);
        return nextCategory;
      }

      saveToolbarCategory(nextCategory, { toolType });
      return nextCategory;
    },
    [instance, lastCategories, selectedAnnotations, selectedEquipmentItems],
  );

  return { toolbarCategory, selectedAnnotations, setToolbarCategory, setCategoryInsidePreset };
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnnotationBackendJSON } from 'pspdfkit';
import { AxiosError } from 'axios';
import { RootState } from '@/store';
import { EquipmentPiece } from '@/api/generated';
import { KeyProps, getKeySlugAndId } from '@/store/utils/getKeySlugAndId';
import { fetchAnnotationsAndEquipments } from '@/store/pdfEditorThunks';
import { createAppSelector } from '@/store/createAppSelector';

export type EquipmentsMap = { [groupKey: string]: EquipmentPiece };

export type EquipmentsState = {
  [slugAndDocumentId: string]: {
    isLoading: boolean;
    error?: AxiosError | null;
    equipments: EquipmentsMap;
    annotations: AnnotationBackendJSON[];
  };
};

type SetAnnotationsPayload = KeyProps & {
  annotations: AnnotationBackendJSON[];
};

type SetEquipmentsPayload = KeyProps & {
  equipments: EquipmentPiece[];
};

const EMPTY_ANNOTATIONS: AnnotationBackendJSON[] = [];
export const selectAnnotations = (slug?: string, documentId?: string) => (state: RootState) => {
  if (!slug || !documentId) return EMPTY_ANNOTATIONS;

  const statePart = state.pdfEditor[getKeySlugAndId({ slug, documentId })];
  return statePart?.annotations ?? EMPTY_ANNOTATIONS;
};

export const selectEquipmentsMap = (state: RootState, slug?: string, documentId?: string) =>
  slug && documentId ? state.pdfEditor[getKeySlugAndId({ slug, documentId })]?.equipments : undefined;

const EMPTY_EQUIPMENTS: EquipmentPiece[] = [];
export const selectEquipments = createAppSelector([selectEquipmentsMap], equipmentsMap =>
  equipmentsMap ? Object.values(equipmentsMap) : EMPTY_EQUIPMENTS,
);

const DEFAULT_LOADING_STATE = { isLoading: true, error: null };
export const selectLoadingState = (slug?: string, documentId?: string) => (state: RootState) => {
  if (!slug || !documentId) return DEFAULT_LOADING_STATE;

  const statePart = state.pdfEditor[getKeySlugAndId({ slug, documentId })];
  return statePart ? { isLoading: statePart.isLoading, error: statePart.error } : DEFAULT_LOADING_STATE;
};

const pdfEditorSlice = createSlice({
  name: 'pdfEditor',
  initialState: {} as EquipmentsState,
  reducers: {
    setAnnotations: (state: EquipmentsState, action: PayloadAction<SetAnnotationsPayload>) => ({
      ...state,
      [getKeySlugAndId(action.payload)]: {
        ...state[getKeySlugAndId(action.payload)],
        annotations: action.payload.annotations,
      },
    }),
    setEquipments: (state: EquipmentsState, action: PayloadAction<SetEquipmentsPayload>) => ({
      ...state,
      [getKeySlugAndId(action.payload)]: {
        ...state[getKeySlugAndId(action.payload)],
        equipments: action.payload.equipments.reduce<EquipmentsMap>((acc, equipment) => {
          acc[equipment._id] = equipment;
          return acc;
        }, {}),
      },
    }),
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAnnotationsAndEquipments.pending, (state: EquipmentsState, action) => {
        const stateKey = getKeySlugAndId(action.meta.arg);
        state[stateKey] = {
          ...state[stateKey],
          isLoading: true,
          error: null,
        };
      })
      .addCase(fetchAnnotationsAndEquipments.fulfilled, (state: EquipmentsState, action) => {
        const stateKey = getKeySlugAndId(action.meta.arg);
        state[stateKey] = {
          ...state[stateKey],
          isLoading: false,
          error: null,
        };
      })
      .addCase(fetchAnnotationsAndEquipments.rejected, (state: EquipmentsState, action) => {
        const stateKey = getKeySlugAndId(action.meta.arg);
        state[stateKey] = {
          ...state[stateKey],
          isLoading: false,
          error: action.error as AxiosError,
        };
      });
  },
});

const { setAnnotations, setEquipments } = pdfEditorSlice.actions;

export { setAnnotations, setEquipments };
export default pdfEditorSlice;

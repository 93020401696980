import { FC, KeyboardEvent, useMemo, useState } from 'react';
import { Control, FieldValues, useFieldArray, UseFormGetValues } from 'react-hook-form';
import { Box, Grid, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TextInput from '@/components/TextInput/TextInput';
import { ProjectFormValue } from '../types';
import Icon from '@/components/Icon/Icon';
import SortingArrows from '@/views/Projects/components/SortingArrows';
import { SORT_DIRECTION } from '@/enums/sort';
import Cell from '@/views/Projects/components/Cell/Cell';
import { every, omit, isEmpty } from 'lodash';
import { Key } from 'ts-key-enum';

interface StakeholdersProps extends FieldValues {
  control: Control<ProjectFormValue>;
  onSaveField: (data: ProjectFormValue) => Promise<void>;
  getValues: UseFormGetValues<ProjectFormValue>;
}

const HEADER_KEYS = ['role', 'name', 'company', 'email', 'phoneNumber'];
const SORT_DIRECTIONS = [SORT_DIRECTION.DESC, SORT_DIRECTION.ASC, SORT_DIRECTION.NONE];

const Stakeholders: FC<StakeholdersProps> = ({ control, onSaveField, getValues, setFocus }) => {
  const { palette } = useTheme();
  const { t } = useTranslation('projectUpdate');
  const [sortDir, setSortDir] = useState(SORT_DIRECTION.NONE);
  const [sortBy, setSortBy] = useState('name');
  const [focusedRow, setFocusedRow] = useState<string | null>(null);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [isRoleValid, setIsRoleValid] = useState<boolean | undefined>(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'stakeholders',
  });

  const currentStakeholders = getValues('stakeholders');
  const isEmptyRowExist = fields.some(field => every(omit(field, ['id']), isEmpty));

  const onSort = (headerKey: string) => {
    if (sortBy === headerKey) {
      const currentIndex = SORT_DIRECTIONS.findIndex(dir => dir === sortDir);
      const nextSortDir = SORT_DIRECTIONS[currentIndex + 1] ?? SORT_DIRECTIONS[0];
      setSortDir(nextSortDir);
      if (nextSortDir === SORT_DIRECTION.NONE) {
        setSortDir(SORT_DIRECTION.NONE);
        setSortBy('name');
      }
    } else {
      setSortDir(SORT_DIRECTION.DESC);
      setSortBy(headerKey);
    }
  };

  const sortedFields = useMemo(() => {
    if (sortDir === SORT_DIRECTION.NONE) {
      return fields;
    }
    return [...fields].sort((a, b) =>
      sortDir === SORT_DIRECTION.DESC ? (b.name ?? '').localeCompare(a.name ?? '') : (a.name ?? '').localeCompare(b.name ?? ''),
    );
  }, [fields, sortDir]);

  const fieldIds = fields.flatMap((_field, index) => [
    `projectUpdate-stakeholders.${index}.role`,
    `projectUpdate-stakeholders.${index}.name`,
    `projectUpdate-stakeholders.${index}.company`,
    `projectUpdate-stakeholders.${index}.email`,
    `projectUpdate-stakeholders.${index}.phone`,
  ]);
  const fieldNames = fields.flatMap((_field, index) => [
    `stakeholders.${index}.role`,
    `stakeholders.${index}.name`,
    `stakeholders.${index}.company`,
    `stakeholders.${index}.email`,
    `stakeholders.${index}.phone`,
  ]);

  const commonActionsStyle = {
    color: palette.grey[900],
    '&:hover': { backgroundColor: 'transparent' },
  };

  const commonFieldsStyle = {
    border: '1px solid transparent',
    '&.Mui-focused': {
      border: `1px solid ${palette.grey[200]}`,
    },
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code !== Key.Enter) return;
    event.preventDefault();
    const currentValues = getValues('stakeholders');
    const isRoleNotEmpty = currentValues?.every(item => item.role !== '');

    const currentFieldIndex = fieldIds.findIndex(id => (event.target as HTMLInputElement).id === id);

    if (currentFieldIndex >= 0 && fieldIds[currentFieldIndex + 1]) {
      setFocus(fieldNames[currentFieldIndex + 1]);
    } else if (isRoleNotEmpty) {
      append({ name: '', role: '', company: '', email: '', phone: '' });
    }
  };

  const fieldsWithoutId = fields.map(field => omit(field, ['id']));

  const handleBlur = () => {
    const currentValues = getValues();

    const isEqualArrays = JSON.stringify(fieldsWithoutId) === JSON.stringify(currentValues.stakeholders);
    !isEqualArrays && onSaveField(currentValues);
  };

  const handleFocus = (id: string) => {
    if (id !== focusedRow) {
      setShowSaveButton(false);
      setFocusedRow(null);
    }
    setFocusedRow(id);
  };

  const handleChange = () => {
    const currentValues = getValues();
    const isCurrentRoleValid = currentValues.stakeholders?.every(item => item.role !== '');
    setIsRoleValid(isCurrentRoleValid);
    const isEqualArrays = JSON.stringify(fieldsWithoutId) === JSON.stringify(currentValues.stakeholders);
    setShowSaveButton(!isEqualArrays);
  };

  return (
    <>
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="h3" fontWeight="fontWeightBold" color={palette.grey['700']}>
          {t('stakeholders.header')}
        </Typography>
        <IconButton
          disabled={isEmptyRowExist}
          color="primary"
          sx={{
            minWidth: 'unset',
            p: 0.125,
            '&, &:hover': {
              backgroundColor: 'transparent',
              border: `1px solid ${palette.primary.dark}`,
            },
            '&.Mui-disabled': {
              border: `1px solid ${palette.grey[200]}`,
            },
          }}
          onClick={() => append({ name: '', role: '', company: '', email: '', phone: '' })}
        >
          <Icon name="plus" fontSize="small" />
        </IconButton>
      </Stack>
      <Stack gap={2} mb={1}>
        <Grid
          container
          columnSpacing={1.5}
          rowSpacing={0}
          xs={12}
          sx={{ m: 0, boxShadow: 2, borderRadius: 3, overflow: 'hidden' }}
        >
          <Grid container item xs={12} sx={{ py: 1, borderBottom: `1px solid ${palette.grey[200]}` }}>
            {HEADER_KEYS.map((key, index) => (
              <Grid
                item
                xs={index === 0 ? 2.5 : 2}
                key={key}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 0.5,
                  pr: HEADER_KEYS.length - 1 === index ? 0 : 1,
                  pl: 1.5,
                  textTransform: 'uppercase',
                  fontSize: 'body2.fontSize',
                  color: palette.grey[400],
                }}
              >
                <Box sx={{ color: palette.grey[700] }}>{t(`stakeholders.headers.${key}`)}</Box>
                {key !== HEADER_KEYS[3] && key !== HEADER_KEYS[4] && (
                  <Tooltip arrow title={t(`stakeholders.tooltips.sort.${key}`)} placement="top">
                    <IconButton onClick={() => onSort(key)}>
                      <SortingArrows order={sortBy === key ? sortDir : SORT_DIRECTION.NONE} />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            ))}
          </Grid>

          {sortedFields?.map((field, index) => {
            const isNewField = every(omit(field, ['id']), isEmpty);

            return (
              <Grid
                key={field.id}
                item
                xs={12}
                sx={{
                  borderBottom: `1px solid ${palette.grey[200]}`,
                  color: palette.text.secondary,
                  textDecoration: 'none',
                  backgroundColor: isNewField ? palette.grey[50] : 'transparent',
                  '&:last-child , &:last-child ': {
                    border: 0,
                  },
                  '&:hover': {
                    backgroundColor: palette.action.hover,
                  },
                  py: 0,
                }}
              >
                <Grid container item xs={12} alignItems="center" sx={{ '&:hover': { '.row-actions': { display: 'flex' } } }}>
                  <Cell xs={2.5} allowEllipsis fontWeight="fontWeightBold" fontSize="h3.fontSize">
                    <TextInput
                      placeholder={t('stakeholders.placeholders.role')}
                      control={control}
                      id={`projectUpdate-stakeholders.${index}.role`}
                      name={`stakeholders.${index}.role`}
                      hideErrors
                      translate="projectUpdate"
                      InputProps={{
                        sx: {
                          backgroundColor: 'transparent',
                          '&.Mui-error': {
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: `1px solid ${palette.error.main}`,
                            },
                          },
                          ...commonFieldsStyle,
                        },
                      }}
                      onBlur={handleBlur}
                      onKeyDown={handleKeyDown}
                      onFocus={() => handleFocus(field.id)}
                      onChange={handleChange}
                    />
                  </Cell>
                  <Cell xs={2} allowEllipsis sx={{ textTransform: 'capitalize' }}>
                    <TextInput
                      placeholder={t('stakeholders.placeholders.name')}
                      control={control}
                      id={`projectUpdate-stakeholders.${index}.name`}
                      name={`stakeholders.${index}.name`}
                      translate="projectUpdate"
                      InputProps={{
                        sx: {
                          backgroundColor: 'transparent',
                          fontWeight: 'fontWeightBold',
                          ...commonFieldsStyle,
                        },
                      }}
                      onBlur={handleBlur}
                      onKeyDown={handleKeyDown}
                      onFocus={() => handleFocus(field.id)}
                      onChange={handleChange}
                    />
                  </Cell>

                  <Cell xs={2} allowEllipsis>
                    <TextInput
                      placeholder={t('stakeholders.placeholders.company')}
                      control={control}
                      id={`projectUpdate-stakeholders.${index}.company`}
                      name={`stakeholders.${index}.company`}
                      translate="projectUpdate"
                      InputProps={{
                        sx: {
                          backgroundColor: 'transparent',
                          ...commonFieldsStyle,
                        },
                      }}
                      onBlur={handleBlur}
                      onKeyDown={handleKeyDown}
                      onFocus={() => handleFocus(field.id)}
                      onChange={handleChange}
                    />
                  </Cell>
                  <Cell xs={2} allowEllipsis>
                    <TextInput
                      placeholder={t('stakeholders.placeholders.email')}
                      control={control}
                      id={`projectUpdate-stakeholders.${index}.email`}
                      name={`stakeholders.${index}.email`}
                      translate="projectUpdate"
                      type="email"
                      hideErrors
                      InputProps={{
                        sx: {
                          backgroundColor: 'transparent',
                          '&.Mui-error': {
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: `1px solid ${palette.error.main}`,
                            },
                          },
                          ...commonFieldsStyle,
                        },
                      }}
                      onBlur={handleBlur}
                      onKeyDown={handleKeyDown}
                      onFocus={() => handleFocus(field.id)}
                      onChange={handleChange}
                    />
                  </Cell>
                  <Cell xs={2}>
                    <TextInput
                      placeholder={t('stakeholders.placeholders.phoneNumber')}
                      control={control}
                      id={`projectUpdate-stakeholders.${index}.phoneNumber`}
                      name={`stakeholders.${index}.phone`}
                      translate="projectUpdate"
                      hideErrors
                      InputProps={{
                        sx: {
                          backgroundColor: 'transparent',
                          ...commonFieldsStyle,
                          '&.Mui-error': {
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: `1px solid ${palette.error.main}`,
                            },
                          },
                        },
                      }}
                      onBlur={handleBlur}
                      onKeyDown={handleKeyDown}
                      onFocus={() => handleFocus(field.id)}
                      onChange={handleChange}
                    />
                  </Cell>
                  <Grid item xs={1.5} className="actions">
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="flex-end"
                      className="row-actions"
                      sx={{ display: focusedRow === field.id && showSaveButton ? 'flex' : 'none', pr: 1.5 }}
                    >
                      {focusedRow === field.id && showSaveButton && (
                        <IconButton
                          disabled={!isRoleValid}
                          sx={commonActionsStyle}
                          onClick={() => {
                            append({ name: '', role: '', company: '', email: '', phone: '' });
                            setFocusedRow(null);
                            setShowSaveButton(false);
                          }}
                        >
                          <Icon name="verified" fontSize="medium" />
                        </IconButton>
                      )}
                      {!!currentStakeholders?.[index].email && !showSaveButton && (
                        <IconButton href={`mailto:${field.email}`} sx={commonActionsStyle}>
                          <Icon name="email" fontSize="small" />
                        </IconButton>
                      )}
                      {!!currentStakeholders?.[index].phone && !showSaveButton && (
                        <IconButton href={`tel:${field.phone}`} sx={commonActionsStyle}>
                          <Icon name="phone" fontSize="small" />
                        </IconButton>
                      )}
                      {!showSaveButton && (
                        <IconButton
                          sx={commonActionsStyle}
                          onClick={() => {
                            remove(index);
                            handleBlur();
                          }}
                        >
                          <Icon name="delete" fontSize="small" />
                        </IconButton>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </>
  );
};
export default Stakeholders;

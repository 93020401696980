import { ConstructingEquipmentPiece, DefaultAnnotationStyle, EquipmentPiece } from '@/api/generated';
import uniq from 'lodash/uniq';

type Options = {
  defaultAnnotationStyle: DefaultAnnotationStyle;
  prevEquipment?: EquipmentPiece;
  equipmentId?: string;
  description?: string | null;
  pricePerUnit: number;
  isReady?: true;
};

export const createEquipmentFromConstructionItems = (
  items: ConstructingEquipmentPiece[],
  { equipmentId, defaultAnnotationStyle, prevEquipment, description, pricePerUnit, isReady }: Options,
): EquipmentPiece => {
  const [firstItem] = items;

  const { iconColors, count, ...sumItemsProps } = items.reduce(
    (acc, item) => {
      acc.calculated_attributes.weight_in_pounds += (item.calculated_attributes?.weight_in_pounds as number | undefined) ?? 0;
      acc.count += (item.count as number | undefined) ?? 0;
      acc.total_price += item.total_price ?? 0;
      acc.constructing_annotation_ids.push(item._id!);
      acc.iconColors.push(...item.iconColors);
      return acc;
    },
    {
      calculated_attributes: { weight_in_pounds: 0 },
      iconColors: [] as string[],
      count: 0,
      total_price: 0,
      constructing_annotation_ids: [] as string[],
    },
  );

  return {
    _id: equipmentId ?? 'unknown',
    autoDetected: firstItem.autoDetected,
    color: firstItem.color,
    constructing_equipment_items: items,
    createdAt: prevEquipment?.createdAt ?? firstItem.createdAt,
    updatedAt: firstItem.updatedAt,
    customData: firstItem.customData,
    defaultAnnotationStyle,
    description: description ?? '',
    icon: firstItem.icon,
    isReady: isReady || (prevEquipment && prevEquipment.isReady) || firstItem.customData?.specialType !== 'autoAnnotationParent',
    name: prevEquipment?.name || firstItem.name,
    price_per_unit: pricePerUnit,
    tags: prevEquipment?.tags || [],
    unit: firstItem.unit,
    unitType: firstItem.unitType,
    unitSystem: firstItem.unit === 'foot' ? 'imperial' : 'metric',
    iconColors: uniq(iconColors),
    count: +count.toFixed(2),
    ...sumItemsProps,
  } satisfies EquipmentPiece;
};

import { Box, ButtonBase, Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import { DropzoneState } from 'react-dropzone';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Descope } from '@descope/react-sdk';
import snakeCase from 'lodash/snakeCase';
import UploadIcon from '@/assets/upload-icon.svg?react';
import DropZoneArea from '@/components/DropZoneArea';
import { ImportDriveFilesFn } from '@/views/Projects/components/ProjectFormDialog/types';
import { useDrivesImport } from '@/views/Projects/components/ProjectFormDialog/hooks/useDrivesImport';
import { isSocialLoginError } from '@/views/Projects/components/ProjectFormDialog/utils/isSocialLoginError';
import { useOrganization } from '@/hooks/useOrganization';

import GoogleDriveIcon from '@/assets/google-drive.svg?react';
import OneDriveIcon from '@/assets/onedrive.svg?react';
import AutodeskIcon from '@/assets/autodesk.svg?react';
import ProcoreIcon from '@/assets/procore.svg?react';
import SharePointIcon from '@/assets/sharepoint.svg?react';

interface DocumentUploadProps extends Pick<DropzoneState, 'getInputProps' | 'isDragActive' | 'getRootProps'> {
  importDriveFiles: ImportDriveFilesFn;
}

const DocumentUpload: FC<DocumentUploadProps> = ({ getInputProps, isDragActive, getRootProps, importDriveFiles }) => {
  const { t } = useTranslation('projectUpdate');
  const { palette } = useTheme();
  const { organization } = useOrganization();
  const emailInDropzone = `${snakeCase(organization.name)}@pelles.ai`;

  const { redirectUrl, errorContext, onAutodeskDriveClick, onOneDriveClick, onGoogleDriveClick, onSharePointDriveClick } =
    useDrivesImport({
      importDriveFiles,
    });

  const clouds = [
    {
      label: t('uploadFiles.clouds.google'),
      onClick: onGoogleDriveClick,
      icon: <GoogleDriveIcon height={22} />,
      disabled: true,
    },
    {
      label: t('uploadFiles.clouds.sharepoint'),
      onClick: onSharePointDriveClick,
      icon: <SharePointIcon height={22} />,
    },
    {
      label: t('uploadFiles.clouds.onedrive'),
      onClick: onOneDriveClick,
      icon: <OneDriveIcon height={22} />,
    },
    {
      label: t('uploadFiles.clouds.procore'),
      icon: <ProcoreIcon height={22} />,
      disabled: true,
    },
    {
      label: t('uploadFiles.clouds.autodesk'),
      onClick: onAutodeskDriveClick,
      icon: <AutodeskIcon height={22} />,
    },
  ];

  return (
    <>
      {errorContext && errorContext.type === 'google' && redirectUrl && isSocialLoginError(errorContext.error) && (
        <Descope flowId="re-auth-google" redirectUrl={redirectUrl} />
      )}
      {errorContext && errorContext.type === 'onedrive' && redirectUrl && isSocialLoginError(errorContext.error) && (
        <Descope flowId="re-auth-microsoft" redirectUrl={redirectUrl} />
      )}
      {errorContext && errorContext.type === 'autodesk' && redirectUrl && isSocialLoginError(errorContext.error) && (
        <Descope flowId="re-auth-autodesk" redirectUrl={redirectUrl} />
      )}

      <DropZoneArea sx={{ minHeight: 0, mb: 1.5, p: 1, cursor: 'auto' }} isDragActive={isDragActive} {...getRootProps()}>
        <input {...getInputProps()} />
        <Stack alignItems="center" p={1}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <SvgIcon inheritViewBox component={UploadIcon} sx={{ fontSize: 32 }} />
            <Typography variant="body1" color={palette.grey['800']} fontWeight="fontWeightBold">
              <Trans
                t={t}
                i18nKey="uploadFiles.generalDropzone"
                values={{ email: emailInDropzone }}
                components={{
                  a: (
                    <Box
                      component="a"
                      sx={{ color: palette.primary.dark }}
                      href={`mailto:${emailInDropzone}?subject=FilesUpload`}
                      target="_blank"
                      onClick={event => event.stopPropagation()}
                    />
                  ),
                }}
              />
            </Typography>
          </Box>
          <Box display="flex" gap={2}>
            {clouds.map(cloude => (
              <ButtonBase
                sx={{
                  display: 'flex',
                  minWidth: 100,
                  flexDirection: 'column',
                  alignItems: 'center',
                  pt: 1.5,
                  pb: 0.5,
                  cursor: cloude.disabled ? 'not-allowed' : 'pointer',
                }}
                onClick={!cloude.disabled ? cloude.onClick : event => event.stopPropagation()}
              >
                {cloude.icon}
                <Typography flex={1} fontSize="body3.fontSize" mt={1.25} fontWeight="fontWeightMedium" color={palette.grey[800]}>
                  {cloude.label}
                </Typography>
                {cloude.disabled && (
                  <Typography flex={1} fontSize="body4.fontSize" fontWeight="fontWeightMedium" color={palette.grey[800]}>
                    ({t('uploadFiles.comingSoon')})
                  </Typography>
                )}
              </ButtonBase>
            ))}
          </Box>
        </Stack>
      </DropZoneArea>
    </>
  );
};

export default DocumentUpload;

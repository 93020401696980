import React, { FC } from 'react';
import { TagType, useGetOrganizationTagsOrgId } from '@/api/generated';
import { OrganizationTagsContext } from '@/views/Project/contexts/OrganizationTagsContext';
import { useOrganization } from '@/hooks/useOrganization';
import uniqBy from 'lodash/uniqBy';

interface OrganizationTagsProviderProps {
  children: React.ReactNode;
}

const OrganizationTagsProvider: FC<OrganizationTagsProviderProps> = ({ children }) => {
  const { organization } = useOrganization();

  const { data: queryTags = [], refetch: refetchQueryTags } = useGetOrganizationTagsOrgId(
    organization.id,
    { type: TagType.query },
    { query: { enabled: !!organization.id } },
  );

  return (
    <OrganizationTagsContext.Provider value={{ queryTags: uniqBy(queryTags, 'name'), refetchQueryTags }}>
      {children}
    </OrganizationTagsContext.Provider>
  );
};

export default OrganizationTagsProvider;

import { FC, MouseEvent } from 'react';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { IconButton, Typography, useTheme } from '@mui/material';
import Icon from '@/components/Icon/Icon';

interface TagViewProps extends LoadingButtonProps {
  tag: string;
  isHighlighted?: boolean;
  onDelete?: () => void;
}

const TagView: FC<TagViewProps> = ({ sx, tag, isHighlighted, onDelete, ...props }) => {
  const { palette } = useTheme();

  const handleDelete = (event: MouseEvent) => {
    event.stopPropagation();
    onDelete?.();
  };

  return (
    <LoadingButton
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0,
        height: 24,
        py: 0,
        px: 1,
        borderRadius: '999px',
        backgroundColor: isHighlighted ? palette.grey['300'] : palette.background.default,
        '&:hover': {
          gap: 0.5,
          backgroundColor: isHighlighted ? palette.grey['300'] : palette.background.default,
          '.TagChip__delete': { width: 12 },
          '& button': { opacity: 1 },
        },
        ...sx,
      }}
      {...props}
    >
      <Typography
        sx={{
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: 'body3.fontSize',
          fontWeight: 'fontWeightRegular',
          color: palette.text.primary,
        }}
      >
        {tag}
      </Typography>
      {onDelete && (
        <IconButton
          sx={{ width: 0, p: 0, overflow: 'hidden', transition: 'width .2s' }}
          className="TagChip__delete"
          size="small"
          disableRipple
          onClick={handleDelete}
        >
          <Icon name="x" fontSize="xsmall" htmlColor={palette.text.primary} />
        </IconButton>
      )}
    </LoadingButton>
  );
};

export default TagView;

import { Box, Button, Checkbox, Grid, IconButton, InputAdornment, Stack, useTheme } from '@mui/material';
import Icon from '@/components/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { Control, FieldValues, useFieldArray, useFormContext, UseFormGetValues } from 'react-hook-form';
import { ChecklistFormValue } from '@/views/Project/views/Queries/components/EditorChecklistsDialog/ChecklistsManagerDialog';
import { FC, KeyboardEvent, useMemo } from 'react';
import Cell from '@/views/Projects/components/Cell/Cell';
import TextInput from '@/components/TextInput/TextInput';
import { Key } from 'ts-key-enum';
import { Checklist, QueryQuestion, Tag } from '@/api/generated';
import ChecklistAutocomplete from '@/views/Project/views/Queries/components/EditorChecklistsDialog/ChecklistAutocomplete';
import { omit } from 'lodash';
import TagSelect from '@/components/TagSelect';
import { useOrganizationTags } from '@/views/Project/hooks/useOrganizationTags';
import { z } from 'zod';
import { questions } from '@/views/Project/views/Queries/components/EditorChecklistsDialog/validationSchema';

interface QuestionsProps extends FieldValues {
  control: Control<ChecklistFormValue>;
  onSaveField: (data: ChecklistFormValue) => Promise<void>;
  selectedIds: Set<string>;
  onQuestionToggle: (id?: string) => void;
  onToggleAll: () => void;
  selectedAllIds: boolean;
  onDeleteQuestions: () => void;
  onUpdateQueryTags: (newTag: Tag, queryToUpdateId: string) => Promise<void>;
  onDeleteTag: (tag: Tag, id?: string) => Promise<void>;
  checklists?: Checklist[];
  selectedQuestions?: QueryQuestion[];
  setCurrentChecklistId: (currentChecklistId?: string) => void;
  canEdit?: boolean;
  getValues: UseFormGetValues<ChecklistFormValue>;
  currentChecklist?: Checklist;
}

export type ChecklistQuestion = z.infer<typeof questions>;

const HEADER_KEYS = ['question', 'reference', 'tags'];

const Questions: FC<QuestionsProps> = ({
  control,
  setFocus,
  getValues,
  onSaveField,
  handleSubmit,
  selectedIds,
  onQuestionToggle,
  onToggleAll,
  selectedAllIds,
  onDeleteQuestions,
  onUpdateQueryTags,
  onDeleteTag,
  checklists,
  selectedQuestions,
  setCurrentChecklistId,
  canEdit,
  currentChecklist,
}) => {
  const { palette } = useTheme();
  const { t } = useTranslation('project');
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'checklistQuestions',
  });
  const { watch } = useFormContext<ChecklistFormValue>();
  const searchQuestionValue = watch('searchQuestionValue');
  const allValues = getValues('checklistQuestions');
  const { queryTags: organizationTags, refetchQueryTags } = useOrganizationTags();

  const commonActionsStyle = {
    color: palette.grey[900],
    '&:hover': { backgroundColor: 'transparent' },
  };

  const commonFieldsStyle = {
    color: palette.grey[900],
    border: '1px solid transparent',
    '& .MuiInputBase-input': { fontSize: 'body2.fontSize' },
    '&.Mui-focused': {
      border: `1px solid ${palette.grey[200]}`,
    },
  };

  const fieldIds = fields.flatMap((_field, index) => [
    `project-checklistsEditDialog.${index}.question`,
    `project-checklistsEditDialog.${index}.reference`,
  ]);
  const fieldNames = fields.flatMap((_field, index) => [
    `checklistQuestions.${index}.question`,
    `checklistQuestions.${index}.reference`,
  ]);

  const isAllFieldsValid = allValues?.every(field => field.question !== '');

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code !== Key.Enter) return;
    event.preventDefault();

    const currentFieldIndex = fieldIds.findIndex(id => (event.target as HTMLInputElement).id === id);

    if (currentFieldIndex >= 0 && fieldIds[currentFieldIndex + 1]) {
      setFocus(fieldNames[currentFieldIndex + 1]);
    } else if (isAllFieldsValid) {
      append({ question: '', reference: '', _id: crypto.randomUUID(), tags: [] });
    }
  };

  const fieldsWithoutId = fields.map(field => omit(field, ['id']));

  const handleBlur = () => {
    const currentValues = getValues();
    const isEqualArrays = JSON.stringify(fieldsWithoutId) === JSON.stringify(currentValues.checklistQuestions);
    !isEqualArrays && onSaveField(currentValues);
  };

  const addNewQuestion = () => append({ question: '', reference: '', _id: crypto.randomUUID(), tags: [] });

  const filteredFields = useMemo(
    () => fields?.filter(field => field.question.toLowerCase().includes(searchQuestionValue?.toLowerCase() ?? '')),
    [fields, searchQuestionValue],
  );

  const onTagAdd = async (name: string, field: ChecklistQuestion) => {
    const existedTag = organizationTags?.find(tag => tag.name === name);
    if (!existedTag) return;

    await onUpdateQueryTags(existedTag, field._id!);
    await refetchQueryTags();
  };

  const onTagDelete = async (name: string, field: ChecklistQuestion) => {
    const existedTag = organizationTags?.find(tag => tag.name === name);
    if (!existedTag) return;

    await onDeleteTag(existedTag, field._id!);
    await refetchQueryTags();
  };

  const onTagEnter = async (name: string, field: ChecklistQuestion) => {
    await onUpdateQueryTags({ name, type: 'query' as const }, field._id!);
    await refetchQueryTags();
  };

  const renderActionForSelected = () => (
    <>
      <ChecklistAutocomplete
        onlyDuplicate={!canEdit}
        selectedQuestions={selectedQuestions}
        checklists={checklists}
        setCurrentChecklistId={setCurrentChecklistId}
        currentChecklist={currentChecklist}
        selectedIds={selectedIds}
      />

      {canEdit && (
        <IconButton sx={{ p: 0.5 }} onClick={onDeleteQuestions}>
          <Icon name="bin" fontSize="medium" />
        </IconButton>
      )}
    </>
  );

  return (
    <Stack gap={1.5} pb={20}>
      <Stack flexDirection="row" alignItems="center" justifyContent="flex-end">
        {!!selectedIds.size && renderActionForSelected()}
        <TextInput
          control={control}
          width={200}
          sx={{
            '& .MuiInputBase-root': {
              border: `1px solid ${palette.darkPurple.main}`,
              borderRadius: 99999,
              backgroundColor: 'transparent',
              px: 2,
              py: 0.5,
              ml: 1,
              alignSelf: 'flex-end',
              '& .MuiInputAdornment-root': {
                mr: 1,
              },
              '& .MuiInputBase-input': {
                p: 0,
                fontSize: 'body2.fontSize',
                '&::placeholder': { color: palette.grey[800] },
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{ color: palette.darkPurple.dark }} position="start">
                <Icon name="search" fontSize="small" />
              </InputAdornment>
            ),
          }}
          placeholder={t('queries.checklistsEditDialog.search')}
          name="searchQuestionValue"
          id="searchQuestion"
        />
      </Stack>
      <Stack gap={2} mb={1}>
        <Grid container columnSpacing={3} rowSpacing={0} sx={{ m: 0, boxShadow: 2, borderRadius: 3, overflow: 'hidden', xs: 12 }}>
          <Grid container item xs={12} sx={{ py: 2, borderBottom: `1px solid ${palette.grey[200]}` }}>
            <Grid
              item
              xs={0.5}
              sx={{
                px: 0,
              }}
            >
              <Checkbox size="large" disableRipple sx={{ p: 0 }} onChange={onToggleAll} checked={selectedAllIds} />
            </Grid>
            {HEADER_KEYS.map((key, index) => (
              <Grid
                item
                xs={index === 0 ? 3.5 : HEADER_KEYS.length - 2 === index ? 5 : HEADER_KEYS.length - 1 === index ? 2.5 : 4}
                key={key}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  pr: HEADER_KEYS.length - 1 === index ? 0 : 1,
                  pl: 1.5,
                  textTransform: 'uppercase',
                  fontSize: 'body2.fontSize',
                  color: palette.grey[400],
                }}
              >
                <Box sx={{ color: palette.grey[700], fontWeight: 'fontWeightBold' }}>
                  {t(`queries.checklistsEditDialog.questions.${key}`)}
                </Box>
              </Grid>
            ))}
          </Grid>

          {(filteredFields || []).map((field, index) => (
            <Grid
              key={`checklistQuestions.${fields.indexOf(field)}.question`}
              item
              xs={12}
              sx={{
                borderBottom: `1px solid ${palette.grey[200]}`,
                color: palette.text.secondary,
                textDecoration: 'none',
                backgroundColor: palette.grey[50],
                '&:nth-child(odd)': { backgroundColor: palette.background.default },
                '&:last-child , &:last-child ': {
                  border: 0,
                },
                '&:hover': {
                  backgroundColor: palette.action.hover,
                },
                py: 0,
              }}
            >
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                sx={{ '&:hover': { '.row-actions': { display: !canEdit ? 'none' : 'flex' } } }}
              >
                <Cell xs={0.5}>
                  <Checkbox
                    checked={selectedIds.has(field?._id ?? '')}
                    size="large"
                    sx={{ p: 0 }}
                    disableRipple
                    onChange={() => onQuestionToggle(field._id)}
                  />
                </Cell>
                <Cell xs={3.5} allowEllipsis fontWeight="fontWeightBold" fontSize="h3.fontSize" py={1}>
                  <TextInput
                    placeholder={t('queries.checklistsEditDialog.questions.placeholders.question')}
                    control={control}
                    id={`project-checklistsEditDialog.${index}.question`}
                    name={`checklistQuestions.${fields.indexOf(field)}.question`}
                    hideErrors
                    translate="project"
                    multiline
                    InputProps={{
                      sx: {
                        backgroundColor: 'transparent',
                        fontWeight: 'fontWeightBold',
                        '&.Mui-error': {
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: `1px solid ${palette.error.main}`,
                          },
                        },
                        ...commonFieldsStyle,
                      },
                    }}
                    onBlur={handleSubmit(handleBlur)}
                    onKeyDown={handleKeyDown}
                    disabled={!canEdit}
                  />
                </Cell>
                <Cell xs={5} allowEllipsis sx={{ textTransform: 'capitalize' }} py={1}>
                  <TextInput
                    placeholder={t('queries.checklistsEditDialog.questions.placeholders.reference')}
                    control={control}
                    id={`project-checklistsEditDialog.${index}.reference`}
                    name={`checklistQuestions.${fields.indexOf(field)}.reference`}
                    translate="project"
                    InputProps={{
                      sx: {
                        backgroundColor: 'transparent',
                        ...commonFieldsStyle,
                      },
                    }}
                    onBlur={handleSubmit(handleBlur)}
                    onKeyDown={handleKeyDown}
                    disabled={!canEdit}
                  />
                </Cell>

                <Cell xs={2.5}>
                  <Stack flexDirection="row" gap={0.5} flexWrap="wrap" py={1} pl={1} alignItems="center">
                    <TagSelect
                      isDisabled={!field._id || !canEdit}
                      visibleTagsLimit={3}
                      isHighlighted
                      tags={organizationTags}
                      selectedTags={field.tags?.map(tag => tag.name) ?? []}
                      onTagAdd={name => onTagAdd(name, field)}
                      onTagDelete={name => onTagDelete(name, field)}
                      onEnter={name => onTagEnter(name, field)}
                    />
                  </Stack>
                </Cell>
                <Grid item xs={0.5} className="actions">
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    className="row-actions"
                    sx={{ display: 'none' }}
                  >
                    <IconButton
                      sx={commonActionsStyle}
                      onClick={() => {
                        remove(index);
                        handleBlur();
                      }}
                    >
                      <Icon name="delete" fontSize="small" />
                    </IconButton>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Stack>
      {canEdit && (
        <Button
          disabled={!isAllFieldsValid}
          sx={{
            p: 0,
            color: palette.grey[700],
            '& .MuiButton-startIcon': { mr: 1 },
            width: 'fit-content',
            ml: 3.25,
            fontWeight: 'fontWeightRegular',
            '&:hover': {
              color: palette.primary.dark,
            },
          }}
          onClick={addNewQuestion}
          startIcon={<Icon name="plus" />}
        >
          {t('queries.checklistsEditDialog.addNewQuestion')}
        </Button>
      )}
    </Stack>
  );
};

export default Questions;

import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import PSPDFKit, { Instance, StandaloneConfiguration } from 'pspdfkit';

type Params = {
  isEnabled?: boolean;
  containerRef: MutableRefObject<HTMLDivElement | null>;
  url?: string | null;
  key?: string;
  instanceConfig?: Omit<StandaloneConfiguration, 'licenseKey' | 'baseUrl' | 'container' | 'document' | 'styleSheets'>;
  onLoad?: (instance: Instance, controller: AbortController) => void | Promise<void>;
};

export const usePspdfkit = ({ isEnabled, containerRef, url, key, instanceConfig, onLoad }: Params) => {
  const instanceRef = useRef<Instance | undefined>(undefined);
  const [instance, setInstance] = useState<Instance>();
  const fetchAbortRef = useRef<AbortController | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPercent, setLoadingPercent] = useState(0);
  const loadBytesRef = useRef(0);

  // Vite css dev update.
  const [devTimeCssUpdate, setDevTimeCssUpdate] = useState(0);
  import.meta.hot?.on('pspdfkit:css-updated', () => setDevTimeCssUpdate(new Date().getTime()));

  const unload = useCallback((reason?: string) => {
    fetchAbortRef.current?.abort(reason);
    fetchAbortRef.current = null;
    setLoadingPercent(0);
    setInstance(undefined);
    containerRef.current && PSPDFKit.unload(containerRef.current);
    instanceRef.current = undefined;
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (!container || !isEnabled || !url) return;

    (async () => {
      try {
        setIsLoading(true);
        const controller = new AbortController();
        fetchAbortRef.current = controller;
        loadBytesRef.current = 0;

        const response = await fetch(url, { signal: controller.signal });
        const total = parseInt(response.headers.get('content-length')!, 10);
        const reader = response.body!.getReader();
        const stream = new ReadableStream({
          async start(ctrl) {
            // eslint-disable-next-line no-constant-condition
            while (true) {
              const { done, value } = await reader.read();
              if (done) break;
              ctrl.enqueue(value);
              loadBytesRef.current += value.byteLength;
              setLoadingPercent(Math.floor((loadBytesRef.current * 100) / total));
            }
            setLoadingPercent(100);
            ctrl.close();
            reader.releaseLock();
          },
        });
        const tempResponse = new Response(stream);
        const arrayBuffer = await tempResponse.arrayBuffer();

        if (controller.signal.aborted) return;

        const newInstance = await PSPDFKit.load({
          ...instanceConfig,
          licenseKey: import.meta.env.VITE_PSPDFKIT_LICENSE_KEY,
          baseUrl: `${window.location.protocol}//${window.location.host}/`,
          container,
          document: arrayBuffer,
          styleSheets: ['/pspdfkit.css'],
        });

        await onLoad?.(newInstance, controller);

        instanceRef.current = newInstance;
        setInstance(newInstance);
        setIsLoading(false);
      } catch (error) {
        if ((error as Error).name === 'AbortError') {
          setIsLoading(false);
        } else {
          console.error(`Error in editor ${key ? `key: ${key}` : ''}`, error);
        }
      }
    })();

    return () => {
      unload('url or enabled state was changed');
    };
  }, [isEnabled, devTimeCssUpdate, url, containerRef, key]);

  return { instance, instanceRef, loadingPercent, isLoading, unload };
};

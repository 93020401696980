import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import {
  Checklist,
  getGetOrganizationChecklistsOrgIdQueryKey,
  Tag,
  useCreateOrganizationChecklist,
  createOrganizationTag,
  deleteOrganizationChecklists,
  useGetOrganizationChecklistsOrgId,
  updateOrganizationChecklists,
} from '@/api/generated';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@/hooks/useAuth';
import Dialog from '@/components/Dialog/Dialog';
import { useOrganization } from '@/hooks/useOrganization';
import Icon from '@/components/Icon/Icon';
import TextField from '@mui/material/TextField';
import ChecklistForm from '@/views/Project/views/Queries/components/EditorChecklistsDialog/ChecklistForm';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { validationSchema } from '@/views/Project/views/Queries/components/EditorChecklistsDialog/validationSchema';
import uniqBy from 'lodash/uniqBy';
import { useConfirmDialog } from '@/hooks/useConfirmDialog';
import { ChecklistsManagerDialogMode } from '@/contexts/ChecklistsManagerDialogContext';
import { LoadingButton } from '@mui/lab';
import ChecklistGroup, { ChecklistGroupProps } from './ChecklistGroup';

interface BaseProps {
  isOpen: boolean;
  mode: ChecklistsManagerDialogMode;
  onClose: () => void;
  onSelect?: (checklists: Checklist[]) => void;
}

interface SelectProps extends BaseProps {
  mode: 'select';
  onSelect: (checklists: Checklist[]) => void;
}

type EditorChecklistsDialogProps = BaseProps | SelectProps;

export type ChecklistFormValue = z.infer<typeof validationSchema>;

const ChecklistsManagerDialog: FC<EditorChecklistsDialogProps> = ({ isOpen, mode, onClose, onSelect }) => {
  const { t } = useTranslation('project');
  const queryClient = useQueryClient();
  const { palette, spacing } = useTheme();
  const { currentUser } = useAuth();
  const { organization } = useOrganization();
  const { showConfirmDialog } = useConfirmDialog();

  const [filterValue, setFilterValue] = useState('');
  const [currentChecklistId, setCurrentChecklistId] = useState<string | undefined>();
  const [currentChecklist, setCurrentChecklist] = useState<Checklist>({ name: '', queries: [] });
  const [isSaving, setIsSaving] = useState(false);
  const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set<string>());
  const [isUserChecklist, setIsUserChecklist] = useState(false);
  const [selectedChecklists, setSelectedChecklists] = useState<string[] | undefined>();

  const { mutateAsync: createChecklist, isPending: isChecklistCreating } = useCreateOrganizationChecklist();
  const {
    data: checklists,
    isLoading,
    refetch,
  } = useGetOrganizationChecklistsOrgId(organization.id, {
    query: { enabled: isOpen },
  });

  const canEdit = isUserChecklist || currentUser.isAdmin;

  const selectedQuestions = useMemo(
    () => currentChecklist.queries?.filter(query => selectedIds.has(query._id ?? '')),
    [currentChecklist.queries, selectedIds],
  );

  const selectedAllIds = useMemo(
    () => !!currentChecklist.queries?.length && currentChecklist.queries?.length === selectedIds.size,
    [currentChecklist.queries?.length, selectedIds.size],
  );

  const form = useForm<ChecklistFormValue>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: { checklistQuestions: [] },
    shouldFocusError: false,
  });

  const userCheckLists = useMemo(
    () =>
      checklists?.filter(
        ({ createdBy, name }) => createdBy === currentUser._id && name.toLowerCase().includes(filterValue.toLowerCase()),
      ),
    [checklists, currentUser._id, filterValue],
  );
  const orgCheckLists = useMemo(
    () =>
      checklists?.filter(
        ({ createdBy, name }) => createdBy !== currentUser._id && name.toLowerCase().includes(filterValue.toLowerCase()),
      ),
    [checklists, currentUser._id, filterValue],
  );

  useEffect(() => {
    const currentChecklistObject = checklists?.find(({ _id }) => _id === currentChecklistId);
    setCurrentChecklist({
      ...currentChecklistObject,
      name: currentChecklistObject?.name ?? '',
      queries: uniqBy(currentChecklistObject?.queries, '_id') ?? [],
    });
    setIsUserChecklist(currentChecklistObject?.createdBy === currentUser._id);
  }, [checklists, currentChecklistId]);

  const { handleSubmit, reset, watch, setFocus } = form;

  const checklistName = watch('name');

  useEffect(() => {
    reset({ name: currentChecklist.name, checklistQuestions: currentChecklist?.queries, searchQuestionValue: '' });
    setSelectedIds(new Set<string>());
  }, [currentChecklist]);

  useEffect(() => {
    refetch();
  }, [currentChecklistId]);

  useEffect(() => {
    if (!isOpen) return;

    reset({ name: '', checklistQuestions: [], searchQuestionValue: '' });
    setFilterValue('');
    setCurrentChecklistId(undefined);
  }, [isOpen, reset, setFocus]);

  const onSaveField = async (formData: ChecklistFormValue) => {
    setIsSaving(true);
    try {
      const isNewChecklist = !currentChecklist._id;
      const nextChecklist = {
        ...currentChecklist,
        name: formData.name,
        queries: formData.checklistQuestions,
      };

      if (!isNewChecklist) {
        queryClient.setQueryData(
          getGetOrganizationChecklistsOrgIdQueryKey(organization.id),
          checklists?.map(checklist => (checklist._id === nextChecklist?._id ? nextChecklist : checklist)),
        );
      }

      const updatedChecklist = isNewChecklist
        ? await createChecklist({ orgId: organization.id, data: nextChecklist })
        : await updateOrganizationChecklists(organization.id, currentChecklist._id!, nextChecklist);

      queryClient.setQueryData(
        getGetOrganizationChecklistsOrgIdQueryKey(organization.id),
        currentChecklist._id
          ? checklists?.map(checklist => (checklist._id === updatedChecklist?._id ? updatedChecklist : checklist))
          : [...(checklists ?? []), updatedChecklist],
      );

      setCurrentChecklistId(updatedChecklist._id);
    } catch (error) {
      console.error(`Error while saving checklist, ${currentChecklist._id}`, error);
    }
    setIsSaving(false);
  };

  const onAddNewChecklist = () => {
    setFocus('name');
    reset({ name: '', checklistQuestions: [], searchQuestionValue: '' });
    setCurrentChecklist({ _id: '', name: '', queries: [] });
    setCurrentChecklistId(undefined);
  };

  const onDeleteChecklist = async (checklistId?: string) => {
    if (!checklistId) return;

    const result = await showConfirmDialog({
      title: t('queries.checklistsEditDialog.checklistGroup.deleteConfirm.title'),
      confirm: t('queries.checklistsEditDialog.checklistGroup.deleteConfirm.confirm'),
    });
    if (!result) return;

    try {
      await deleteOrganizationChecklists(organization.id, checklistId);
      selectedChecklists?.length && setSelectedChecklists(prev => prev?.filter(id => id !== checklistId));
      queryClient.setQueryData(
        getGetOrganizationChecklistsOrgIdQueryKey(organization.id),
        checklists?.filter(checklist => checklist._id !== checklistId),
      );
    } catch (error) {
      console.error(`Error while deleting checklist, ${checklistId}`, error);
    }
  };

  const onDeleteQuestions = async () => {
    const updatedQuestionList = currentChecklist.queries?.filter(query => !selectedIds.has(query?._id ?? ''));
    await onSaveField({ name: currentChecklist.name, checklistQuestions: updatedQuestionList });
  };

  const onToggleAll = () => {
    setSelectedIds(selectedAllIds ? new Set<string>() : new Set(currentChecklist.queries?.map(item => item?._id ?? '')));
  };

  const onQuestionToggle = (id?: string) => {
    if (!id) return;

    const nextIds = new Set(selectedIds);
    if (nextIds.has(id)) {
      nextIds.delete(id);
    } else {
      nextIds.add(id);
    }
    setSelectedIds(nextIds);
  };

  const onUpdateQueryTags = useCallback(
    async (newTag: Tag, queryToUpdateId: string) => {
      if (isSaving) return;
      const currentQuery = currentChecklist?.queries?.find(query => query?._id === queryToUpdateId);

      const isTagExist = (currentQuery?.tags || []).find(({ name }) => name === newTag.name);
      if (isTagExist) return;
      const updatedQueries = currentChecklist?.queries?.map(queryItem => {
        if (queryItem._id !== queryToUpdateId) return queryItem;
        return { ...queryItem, tags: [...(queryItem.tags || []), newTag] };
      });

      await createOrganizationTag(organization.id, newTag);
      await onSaveField({ name: currentChecklist.name, checklistQuestions: updatedQueries });
    },
    [currentChecklist, isSaving],
  );

  const onDeleteTag = useCallback(
    async (tag: Tag, queryToUpdateId?: string) => {
      if (isSaving) return;
      const updatedQueries = currentChecklist.queries?.map(queryItem => {
        if (queryItem._id !== queryToUpdateId) return queryItem;
        return { ...queryItem, tags: (queryItem.tags || []).filter(({ name }) => name !== tag.name) };
      });

      onSaveField({ name: currentChecklist.name, checklistQuestions: updatedQueries });
    },
    [currentChecklist, isSaving],
  );

  const onSelectChecklistsItems = useCallback<() => ChecklistGroupProps['onSelectItems'] | undefined>(() => {
    if (mode !== 'select') return undefined;

    return (selectedItems: { checklistId: string; isChecked: boolean }[]) => {
      setSelectedChecklists((prev = []) => {
        const next = [...prev];
        selectedItems.forEach(selectedItem => {
          const index = next.findIndex(selectedId => selectedId === selectedItem.checklistId);
          if (selectedItem.isChecked && index === -1) {
            next.push(selectedItem.checklistId);
          }
          if (!selectedItem.isChecked && index >= 0) {
            next.splice(index, 1);
          }
        });
        return next;
      });
    };
  }, [mode]);

  const onSelectChecklists = useCallback(() => {
    const selected = checklists?.filter(checklist => selectedChecklists?.includes(checklist._id as string));
    setSelectedChecklists([]); // NOTE: it have to be reseted before dialog close
    selected && onSelect && onSelect(selected);
  }, [selectedChecklists, onSelect, checklists]);

  const onSubmit = () => {
    if (mode === 'select' && onSelect) {
      return onSelectChecklists();
    }
    onClose();
  };

  const calculateChecklistGroupAmount = (list?: Checklist[]) => {
    if (mode !== 'select') return list?.length;

    const selectedCount = list?.reduce((acc, checklist) => {
      if (selectedChecklists?.includes(checklist._id as string)) {
        return acc + 1;
      }
      return acc;
    }, 0);
    return `${selectedCount}/${list?.length}`;
  };

  const renderContent = () => (
    <Stack flexDirection="row" sx={{ height: '100vh', overflow: 'hidden', zIndex: 2000 }}>
      <Stack sx={{ backgroundColor: palette.grey[50] }}>
        <Stack sx={{ width: 285, height: '100%', overflow: 'auto' }}>
          <Stack sx={{ p: spacing(3.5, 3, 1.75), borderBottom: `1px solid ${palette.grey[100]}`, flexDirection: 'row' }}>
            <ButtonBase onClick={onClose} disableRipple sx={{ mr: 1, p: 0.5, pl: 0 }}>
              <Icon name="doubleArrowLeft" />
            </ButtonBase>
            <TextField
              onChange={event => setFilterValue(event.target.value)}
              sx={{
                width: '100%',
                '& .MuiInputBase-root': {
                  border: `1px solid ${palette.darkPurple.main}`,
                  borderRadius: 99999,
                  backgroundColor: 'transparent',
                  px: 2,
                  py: 0.5,
                  '& .MuiInputAdornment-root': {
                    mr: 1,
                  },
                  '& .MuiInputBase-input': {
                    p: 0,
                    fontSize: 'body2.fontSize',
                    '&::placeholder': { color: palette.grey[800] },
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment sx={{ color: palette.darkPurple.dark }} position="start">
                    <Icon name="search" fontSize="small" />
                  </InputAdornment>
                ),
              }}
              placeholder={t('queries.checklistsEditDialog.search')}
              name="searchChecklist"
              id="searchChecklist"
            />
          </Stack>
          <Stack gap={1.25} sx={{ position: 'relative', backgroundColor: palette.grey[50] }}>
            {!!userCheckLists?.length || !!orgCheckLists?.length ? (
              <>
                <ChecklistGroup
                  checklists={userCheckLists}
                  name={t('queries.checklistsEditDialog.checklistGroup.myList', {
                    amount: calculateChecklistGroupAmount(userCheckLists),
                    interpolation: { escapeValue: false },
                  })}
                  setCurrentChecklistId={setCurrentChecklistId}
                  onAddNewChecklist={onAddNewChecklist}
                  onDeleteChecklist={onDeleteChecklist}
                  withAddButton
                  withDeleteButton={true}
                  onSelectItems={onSelectChecklistsItems()}
                  selectedChecklists={selectedChecklists}
                />
                <ChecklistGroup
                  checklists={orgCheckLists}
                  name={t('queries.checklistsEditDialog.checklistGroup.name', {
                    name: organization.name,
                    amount: calculateChecklistGroupAmount(orgCheckLists),
                    interpolation: { escapeValue: false },
                  })}
                  withDeleteButton={currentUser.isAdmin}
                  setCurrentChecklistId={setCurrentChecklistId}
                  onAddNewChecklist={onAddNewChecklist}
                  onDeleteChecklist={onDeleteChecklist}
                  onSelectItems={onSelectChecklistsItems()}
                  selectedChecklists={selectedChecklists}
                />
              </>
            ) : (
              <Stack alignItems="center" py={3} gap={2}>
                <Typography>{t('queries.checklistsEditDialog.createNewChecklist')}</Typography>
                <IconButton onClick={onAddNewChecklist} sx={{ backgroundColor: palette.background.default, boxShadow: 1 }}>
                  <Icon name="plus" />
                </IconButton>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack
          sx={{
            p: 3,
            pt: 1,
            position: 'relative',
            alignItems: 'center',
            '&::before': {
              content: '""',
              display: 'block',
              height: 44,
              width: '100%',
              position: 'absolute',
              top: -44,
              left: 0,
              zIndex: 10,
              pointerEvents: 'none',
              background: `linear-gradient(0, ${palette.grey[50]}, rgba(0,0,0,0))`,
            },
          }}
        >
          <LoadingButton
            sx={{
              gap: 1,
              alignItems: 'center',
              color: (selectedChecklists || []).length > 0 ? palette.primary.dark : palette.grey[700],
              px: 1.5,
            }}
            variant="contained"
            loading={isLoading}
            disabled={!((selectedChecklists || []).length > 0)}
            onClick={onSelectChecklists}
          >
            <Icon name="bulletList" sx={{ width: 16, height: 16 }} />
            <Typography sx={{ fontWeight: 'fontWeightBold', fontSize: 'body1.fontSize' }}>
              {t(`queries.checklistsEditDialog.runChecklist`)}
            </Typography>
          </LoadingButton>
        </Stack>
      </Stack>
      <Stack sx={{ flex: 1, height: '100%', py: 3, px: 5, overflow: 'auto' }}>
        <ChecklistForm
          canEdit={canEdit}
          selectedIds={selectedIds}
          onSaveField={onSaveField}
          onQuestionToggle={onQuestionToggle}
          onToggleAll={onToggleAll}
          selectedAllIds={selectedAllIds}
          onDeleteQuestions={onDeleteQuestions}
          onUpdateQueryTags={onUpdateQueryTags}
          onDeleteTag={onDeleteTag}
          checklists={checklists}
          selectedQuestions={selectedQuestions}
          setCurrentChecklistId={setCurrentChecklistId}
          currentChecklist={currentChecklist}
        />
      </Stack>
    </Stack>
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      sx={{ '& .MuiBackdrop-root.MuiModal-backdrop': { backgroundColor: 'rgba(0,0,0,0.5)' } }}
      PaperProps={{
        sx: {
          maxWidth: 1238,
          width: '100%',
          height: '100%',
          p: 0,
          borderRadius: 3,
          boxShadow: 18,
          border: `1px solid ${palette.darkPurple.main}`,
          pb: 0,
        },
      }}
    >
      <FormProvider {...form}>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          p={1.5}
          sx={{ borderBottom: `1px solid ${palette.grey[300]}` }}
        >
          <Stack flexDirection="row" alignItems="center" gap={1.25}>
            <Box
              sx={{
                backgroundColor: palette.background.default,
                p: 1,
                color: palette.accent.contrastText,
                boxShadow: 19,
                borderRadius: 2,
                lineHeight: 0,
              }}
            >
              <Icon name="list" fontSize="large" />
            </Box>
            <Typography variant="h3">{t('queries.checklistsEditDialog.title')}</Typography>
          </Stack>
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <Button
              variant="contained"
              color="accent"
              onClick={onAddNewChecklist}
              startIcon={<Icon name="plus" sx={{ width: 16, height: 16 }} />}
              sx={{
                backgroundColor: palette.feedback.main,
                color: palette.feedback.dark,
                py: 0.5,
                px: 1,
                borderRadius: 99999,
                fontWeight: 'fontWeightRegular',
                lineHeight: 'normal',
                boxShadow: 0,
                '&:hover': { boxShadow: 0 },
              }}
            >
              {t('queries.checklistsEditDialog.newCheckList')}
            </Button>
            <Button
              type="submit"
              disabled={!checklistName?.trim()}
              variant="contained"
              sx={{ py: 0.5, px: 1.25, borderRadius: 99999, fontWeight: 'fontWeightRegular', lineHeight: 'normal' }}
            >
              {isSaving || isChecklistCreating ? <CircularProgress size={14} /> : t('queries.checklistsEditDialog.saveAndExit')}
            </Button>
          </Stack>
        </Stack>
        {isLoading ? (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          renderContent()
        )}
      </FormProvider>
    </Dialog>
  );
};

export default ChecklistsManagerDialog;

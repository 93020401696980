import { AnnotationBackendJSON } from 'pspdfkit';
import { ConstructingEquipmentPiece } from '@/api/generated';
import { isShapeAnnotation } from '@/utils/isShapeAnnotation';
import { convertToFeet } from '@/store/utils/convertToFeet';

type Options = {
  equipmentId?: string;
  description?: string | null;
  pricePerUnit: number;
  weightInPounds?: number;
};

const iconsMapBySpecialType: Record<string, string> = {
  autoAnnotationParent: 'auto_count',
  autoAnnotationChild: 'auto_count',
  'multi-count': 'multi_count',
};

const unitTypeByGroupKey = {
  autoAnnotationParent: 'pieces',
  autoAnnotationChild: 'pieces',
  'multi-count': 'pieces',
};

const iconsByType: Record<string, string> = {
  'pspdfkit/shape/line': 'linear',
  'pspdfkit/shape/rectangle': 'area',
  'pspdfkit/shape/ellipse': 'area',
  'pspdfkit/shape/polygon': 'area',
  'pspdfkit/shape/polyline': 'linear',
};

const unitTypeByType = {
  'pspdfkit/shape/line': 'linear',
  'pspdfkit/shape/rectangle': 'area',
  'pspdfkit/shape/ellipse': 'area',
  'pspdfkit/shape/polygon': 'area',
  'pspdfkit/shape/polyline': 'linear',
};

const getIcon = (annotation: AnnotationBackendJSON) => {
  const key = annotation.customData?.specialType as keyof typeof unitTypeByGroupKey | undefined;
  const iconByGroup = key && iconsMapBySpecialType[key];
  return iconByGroup ?? iconsByType[annotation.type];
};

const getUnitType = (annotation: AnnotationBackendJSON) => {
  const key = annotation.customData?.specialType as keyof typeof unitTypeByGroupKey | undefined;
  const unitTypeByGroup = key && unitTypeByGroupKey[key];
  // @ts-expect-error
  return unitTypeByGroup ?? unitTypeByType[annotation.type];
};

export const convertAnnotationToEquipmentItem = (
  annotation: AnnotationBackendJSON,
  { pricePerUnit, description, equipmentId, weightInPounds }: Options,
) => {
  const unitType = getUnitType(annotation);
  const item: ConstructingEquipmentPiece = {
    _id: annotation.id,
    autoDetected:
      !!annotation.customData?.specialType && (annotation.customData.specialType as string).startsWith('autoAnnotation'),
    createdAt: annotation.createdAt?.toString(),
    updatedAt: annotation.updatedAt?.toString(),
    customData: annotation.customData,
    description: description ?? '',
    icon: getIcon(annotation),
    name: annotation.name ?? annotation.id,
    parent_equipment_id: equipmentId ?? 'unknown',
    price_per_unit: pricePerUnit,
    total_price: pricePerUnit,
    unit: unitType === 'pieces' ? 'pieces' : unitType === 'area' ? 'foot ** 2' : 'foot',
    unitType,
    color: '#000',
    iconColors: ['#000'],
  };

  if (isShapeAnnotation(annotation)) {
    item.color = annotation.strokeColor ?? '#000';
    item.iconColors = [annotation.strokeColor ?? '#000'];
  }

  if (unitType === 'pieces') {
    item.count = 1;
    item.calculated_attributes = { weight_in_pounds: 0 };
  } else if (annotation.note && isShapeAnnotation(annotation)) {
    item.count = convertToFeet(annotation.note);
    item.calculated_attributes = { weight_in_pounds: weightInPounds ? +(item.count * weightInPounds).toFixed(2) : 0 };
  }

  return item;
};

import { Box, Button, CircularProgress, Dialog, Stack, useTheme } from '@mui/material';
import { BaseSyntheticEvent, FC, useCallback, useEffect, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { ProjectFull, getFilesystem } from '@/api/generated';
import DocumentUpload from '@/views/Projects/components/ProjectFormDialog/components/DocumentUpload';
import ProjectForm from '@/views/Projects/components/ProjectFormDialog/components/ProjectForm';
import { STATUS } from '@/utils/enums';
import { useFileSystemTree } from '@/views/Projects/components/ProjectFormDialog/hooks/useFileSystemTree';
import { useProjectForm } from '@/views/Projects/components/ProjectFormDialog/hooks/useProjectForm';
import { updateProjectCache } from '@/utils/updateProjectCache';
import VersionsUpload from '@/views/Projects/components/ProjectFormDialog/components/VersionsUpload';
import FilesTree from '@/views/Projects/components/ProjectFormDialog/components/FilesTree';
import { useFilesUpload } from '@/views/Projects/components/ProjectFormDialog/hooks/useDocumentsUpload';
import { TreeFileSystemNode } from '@/types';

interface ProjectFormDialogProps {
  isOpened: boolean;
  initialProject?: ProjectFull;
  onClose: (project?: ProjectFull) => void;
  onAfterSave: (project: ProjectFull) => void;
}

const ProjectFormDialog: FC<ProjectFormDialogProps> = ({ isOpened, initialProject, onClose, onAfterSave }) => {
  const queryClient = useQueryClient();
  const { palette } = useTheme();
  const { t } = useTranslation('projectUpdate');

  const treeNodesByIdRef = useRef<Record<string, TreeFileSystemNode>>({});

  const { projectRef, form, isSaving, resetFormValues, onSubmit, getOrCreateProject, onSaveField } = useProjectForm({
    initialProject,
    onClose,
    onAfterSave,
  });
  const name = form.watch('name');

  const {
    loadFileSystem,
    selectedDocument,
    selectedDocumentVersions,
    fileSystemNodes,
    importDriveFiles,
    uploadFiles,
    uploadVersion,
    onRename,
    onMove,
    onFolderCreate,
    onDelete,
    onFileOpen,
    onDocumentConvertToPage,
    onAddVersion,
    onVersionsClose,
    deleteDocumentVersion,
    setAsMainDocumentVersion,
  } = useFileSystemTree({
    getOrCreateProject,
    getSlug: () => form.getValues('slug'),
    nodesByIdRef: treeNodesByIdRef,
    onClose,
  });

  const filesUploadProps = useFilesUpload({ uploadFiles });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onClick, ...rootProps } = filesUploadProps.getRootProps();

  const isUploading = Object.entries(fileSystemNodes).some(([, node]) => node.status === STATUS.LOADING);

  useEffect(() => {
    if (!isOpened) return;
    projectRef.current = initialProject;
    resetFormValues(initialProject);
    loadFileSystem(initialProject);
  }, [isOpened, initialProject]);

  useEffect(() => {
    const slug = form.getValues('slug');
    if (!slug || isOpened) return;

    (async () => {
      // We update file system here on close to keep it in sync for documents in navigation.
      const fileSystem = await getFilesystem(slug);
      updateProjectCache({ queryClient, projectSlug: slug }, prevProject => {
        if (!prevProject) return;
        return { ...prevProject, filesystem: fileSystem };
      });
    })();
  }, [isOpened, initialProject]);

  const handleSaveField = useCallback(
    async (data: { name: string; slug?: string; address?: string }) => {
      // Transform data as needed before submitting
      await onSaveField({ target: { value: data.name } } as BaseSyntheticEvent);
    },
    [onSaveField],
  );

  return (
    <Dialog
      component="form"
      fullWidth
      open={isOpened}
      onClose={() => onClose()}
      onSubmit={onSubmit}
      PaperProps={{ sx: { backgroundColor: palette.background.default, height: '90%', maxWidth: 1000 } }}
    >
      <FormProvider {...form}>
        <Button
          type="submit"
          variant="contained"
          disabled={!name?.trim()}
          sx={{ position: 'absolute', top: 20, right: 20, zIndex: 1, py: 0.1, minHeight: 26, borderRadius: 99 }}
        >
          {isUploading || isSaving ? <CircularProgress size={14} /> : t('details.save')}
        </Button>
        <Box sx={{ p: 3, pt: 6 }}>
          <ProjectForm onSaveField={handleSaveField} />

          <Stack
            flex={1}
            data-folderid="root"
            sx={{
              minHeight: 0,
              pt: 3,
              mt: 2.5,
              borderTop: `1px solid ${palette.grey[200]}`,
              color: '#676767',
            }}
          >
            <Box sx={{ fontSize: 'h3.fontSize', fontWeight: 'bold', color: palette.grey['700'], mb: 2 }}>
              {t('details.projectFiles')}
            </Box>
            <DocumentUpload {...filesUploadProps} importDriveFiles={importDriveFiles} />

            <Box sx={{ display: 'flex', gap: 1, minHeight: 0, height: 340 }}>
              <FilesTree
                sx={{ width: '100%' }}
                dropzoneRootProps={rootProps}
                openFilesDialog={filesUploadProps.openWithId}
                fileSystemNodes={fileSystemNodes}
                onRename={onRename}
                onMove={onMove}
                onFolderCreate={onFolderCreate}
                onDelete={onDelete}
                onFileOpen={onFileOpen}
                onAddVersion={onAddVersion}
                onDocumentConvertToPage={onDocumentConvertToPage}
              />

              {selectedDocument && selectedDocumentVersions && (
                <VersionsUpload
                  sx={{ width: '40%' }}
                  document={selectedDocument}
                  versionsNodes={selectedDocumentVersions}
                  uploadVersion={uploadVersion}
                  importDriveFiles={importDriveFiles}
                  onVersionClick={onFileOpen}
                  onClose={onVersionsClose}
                  deleteDocumentVersion={deleteDocumentVersion}
                  setAsMainDocumentVersion={setAsMainDocumentVersion}
                />
              )}
            </Box>
          </Stack>
        </Box>
      </FormProvider>
    </Dialog>
  );
};

export default ProjectFormDialog;

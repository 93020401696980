import { useTranslation } from 'react-i18next';
import { useOrganization } from '@/hooks/useOrganization';
import { useEffect } from 'react';
import { usePageTitle } from '@/hooks/usePageTitle';
import Pages from '@/components/Pages/Pages';
import { IconNames } from '@/components/Icon/Icon';
import { useExportMarkdownToPage } from '@/hooks/useExportMarkdownToPage';
import { format } from 'date-fns/format';
import { useSearchParams } from 'react-router-dom';

const OrgAutomatePages = () => {
  const { t } = useTranslation('project');
  const { organization } = useOrganization();
  const { setPageTitle: setMainPageTitle } = usePageTitle();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setMainPageTitle(t('pages.mainPageTitle'));
  }, []);

  const exportFromMarkdown = useExportMarkdownToPage({
    getName: () => t('pages.editor.ai.exportName', { date: format(Date.now(), 'P'), interpolation: { escapeValue: false } }),
    slug: organization.name,
  });

  const aiMenuItems: {
    iconName: IconNames;
    textContent: string;
    onClick: (text: string) => void;
  }[] = [
    {
      iconName: 'exportToPage',
      textContent: t('pages.editor.ai.past'),
      onClick: async (text: string) => {
        if (!text) return;

        const createdPage = await exportFromMarkdown(text);
        if (!createdPage) return;
        const nextParams = new URLSearchParams(searchParams);
        nextParams.set('pageId', createdPage._id!);
        setSearchParams(nextParams);
      },
    },
  ];

  return <Pages slug={organization.name} width="50%" minMaxHeight={170} aiMenuItems={aiMenuItems} />;
};

export default OrgAutomatePages;

import { AnnotationBackendJSON } from 'pspdfkit';
import { isShapeAnnotation } from '@/utils/isShapeAnnotation';
import { isLineAnnotation } from '@/utils/isLineAnnotation';

export const getDefaultEquipmentStyle = (annotation: AnnotationBackendJSON) => {
  const shapeProps = isShapeAnnotation(annotation)
    ? {
        fillColor: annotation.fillColor,
        opacity: annotation.opacity,
        strokeColor: annotation.strokeColor,
        strokeWidth: annotation.strokeWidth,
      }
    : {};
  const lineProps = isLineAnnotation(annotation) ? { lineCaps: annotation.lineCaps } : {};

  return {
    ...shapeProps,
    ...lineProps,
    type: annotation.type,
  };
};

import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, FormControlLabel, Stack, Switch, Tooltip, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useConfirmDialog } from '@/hooks/useConfirmDialog';
import { useOrganization } from '@/hooks/useOrganization';
import { useSearchParams } from 'react-router-dom';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { $createRangeSelection, $getRoot, LexicalEditor, SerializedLexicalNode } from 'lexical';
import { EditedPage, NewPage, TreeItem, TreePage } from '@/views/Project/views/AutomatePages/types';
import { getPageIdFromLocalStorage, isExistedPage, savePageIdToLocalStorage } from '@/views/Project/views/AutomatePages/utils';
import {
  MentionType,
  PageActionType,
  PageEditAction,
  PageEditActionParams,
  PageMetadata,
  PageMetadataParentId,
  PageResponse,
  PageType,
  Source,
  askAutomate,
  automatePage,
  deletePage,
  duplicatePage,
  editPage,
  useGetAllPages,
  getFollowUpQuestions,
  useGetMentionsByType,
  getPageById,
  setPageIsPrivate,
  updatePage,
  updatePages,
} from '@/api/generated';
import { getMentionsByType } from '@/utils/getMentionsByType';
import keyBy from 'lodash/keyBy';
import { TEMPLATE_FOLDER } from '@/views/Project/views/AutomatePages/constants';
import { arrayToTree } from 'performant-array-to-tree';
import { AskAiFn } from '@/containers/PagesEditor/types';
import sortBy from 'lodash/sortBy';
import useDispatchEditorClick from '@/containers/PagesEditor/hooks/useDispatchEditorClick';
import { updatePagesCache } from '@/utils/updatePagesCache';
import debounce from 'lodash/debounce';
import { CreateHandler, DeleteHandler, MoveHandler, RenameHandler } from 'react-arborist';
import { enqueueSnackbar } from 'notistack';
import { loadImageBase64 } from '@/utils/loadImageBase64';
import { format } from 'date-fns/format';
import { pdf } from '@react-pdf/renderer';
import LexicalPdfExport from '@/components/LexicalPdfExport/LexicalPdfExport';
import { saveAs } from 'file-saver';
import { $generateHtmlFromNodes } from '@lexical/html';
import { $createElementNodeFromMarkdown } from '@/containers/PagesEditor/utils/$createElementNodeFromMarkdown';
import PagesDrawer from '@/views/Project/views/AutomatePages/components/PagesDrawer';
import PagesEditor from '@/containers/PagesEditor/PagesEditor';
import Icon, { IconNames } from '@/components/Icon/Icon';
import { getFadingShadowSxProps } from '@/utils/getFadingShadowSxProps';
import CellEditable from '@/components/CellEditable/CellEditable';
import { EDITOR_CONTENT_LEFT_PADDING, EDITOR_SHEET_MARGIN_SPACING } from '@/containers/PagesEditor/constants';
import SliderWithConfirm from '@/views/Project/views/AutomatePages/components/SliderWithConfirm';
import FabMenu from '@/components/FabMenu/FabMenu';
import FabButton from '@/components/FabMenu/FabButton';

export interface AiMenuItemProps {
  iconName: IconNames;
  textContent: string;
  onClick: (text: string) => void;
}

interface PagesProps {
  slug: string;
  projectId?: string;
  width: string | number;
  minMaxHeight: number;
  aiMenuItems: AiMenuItemProps[];
}

const INITIAL_STATE = { showSlider: false, sliderValue: 0 };

const Pages: FC<PagesProps> = ({ slug, projectId = null, width, minMaxHeight, aiMenuItems }) => {
  const { t } = useTranslation('project');
  const { palette, typography, spacing } = useTheme();
  const queryClient = useQueryClient();
  const defaultTitle = t('pages.pageDefaultTitle');
  const { showConfirmDialog } = useConfirmDialog();
  const { organization } = useOrganization();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPageId = searchParams.get('pageId');

  const lexicalEditorRef = useRef<LexicalEditor | null>(null);
  const ignoreNextEditorUpdate = useRef(false);
  const loadPageController = useRef(new AbortController());
  const [pageTitle, setPageTitle] = useState(t('pages.pageDefaultTitle'));
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [editedPage, setEditedPage] = useState<EditedPage>();

  const isTemplate = useMemo(
    () => (editedPage && isExistedPage(editedPage) ? editedPage.page_type === 'template' : false),
    [editedPage],
  );
  const [isReadOnly, setIsReadOnly] = useState(isTemplate);

  useEffect(() => {
    setIsReadOnly(isTemplate);
  }, [isTemplate]);

  const pagesRef = useRef<PageMetadata[]>();
  const pagesByIdRef = useRef<Record<string, PageMetadata>>({});
  const [pagesTree, setPagesTree] = useState<TreePage[]>();
  const [isSliderChangeDisabled, setIsSliderChangeDisabled] = useState(false);
  const [isConfirmButtonShowed, setIsConfirmButtonShowed] = useState(false);
  const [actionData, setActionData] = useState<PageEditAction>({
    action: PageActionType.change_page_length,
    params: null,
  });
  const [sliderStateActions, setSliderStateActions] = useState(INITIAL_STATE);
  const [isSliderActionDataLoading, setIsSliderActionDataLoading] = useState(false);

  const getSuggestions = async (selectedText?: string) =>
    getFollowUpQuestions({
      source: selectedText ? Source.create_follow_ups : Source.create,
      context: { page_id: '', query_id: '', equipment_id: '', text: '' },
    });
  const { data: mentionsByType = [] } = useGetMentionsByType({
    id: projectId,
  });

  const getMentions = (type: MentionType) => getMentionsByType(mentionsByType, type);

  const updateTreePages = (cb: (currentPages: PageMetadata[]) => PageMetadata[]) => {
    pagesRef.current = cb(pagesRef.current ?? []);
    pagesByIdRef.current = keyBy(pagesRef.current, '_id');

    const pageItemsWithTemplates: TreeItem[] = pagesRef.current.map(page => ({
      id: page._id!,
      name: page.name,
      parentId:
        page.page_type === 'template'
          ? TEMPLATE_FOLDER.id
          : !page.parentId || page.parentId === 'root'
            ? undefined
            : page.parentId,
      isFolder: page.page_type === 'folder',
      isStatic: page.page_type === 'template',
      order: page.order,
      page,
    }));
    pageItemsWithTemplates.push(TEMPLATE_FOLDER);

    setPagesTree(arrayToTree(pageItemsWithTemplates, { id: 'id', parentId: 'parentId', dataField: 'item' }) as TreePage[]);
  };

  const currentPagePath = useMemo(() => {
    if (!currentPageId) return;

    const getPath = (pageId: string): string[] => {
      if (pageId === TEMPLATE_FOLDER.id) return [TEMPLATE_FOLDER.name];

      const page = pagesByIdRef.current[pageId];
      if (!page) return [];
      if (!page.parentId) return [page.name];
      return [...getPath(page.parentId), page.name];
    };

    return getPath(currentPageId);
  }, [currentPageId, pagesTree]);

  const clearEditor = () => {
    lexicalEditorRef.current?.update(() => $getRoot().clear());
    ignoreNextEditorUpdate.current = true;
  };

  const askAi: AskAiFn = (question, { reference, mentions = [] } = {}) =>
    askAutomate(slug!, {
      question_receive: {
        mentions,
        question,
        reference,
      },
      page_id: currentPageId!,
    });

  const setUrlPageId = (pageId: string) => {
    setSearchParams(prevParams => {
      if (prevParams.get('pageId') === pageId) return prevParams;
      const nextParams = new URLSearchParams(searchParams);
      nextParams.set('pageId', pageId);
      return nextParams;
    });
  };

  const loadPage = useCallback(
    async (pageId: string) => {
      setIsPageLoading(true);

      savePageIdToLocalStorage(slug!, pageId);

      loadPageController.current.abort('Next page is loading');
      loadPageController.current = new AbortController();

      const page = await getPageById(slug, pageId, undefined, loadPageController.current.signal);
      setEditedPage(page);
      setPageTitle(page.name);
      setIsPageLoading(false);
    },
    [slug],
  );

  const { data: pages, isLoading: isPagesLoading, queryKey: allPagesQueryKey } = useGetAllPages(slug, { query: { gcTime: 0 } });

  useEffect(() => {
    updateTreePages(() => (pages?.length ? sortBy(pages, ['parentId', 'order']) : []));
  }, [pages]);

  useEffect(() => {
    if (!pages) return;
    (async () => {
      const storagePageId = getPageIdFromLocalStorage(slug!);
      const isPageWithTypePageExists = !!pagesRef.current?.some(page => page.page_type === PageType.page);
      const isUrlPageExists = currentPageId && pagesRef.current?.some(page => page._id === currentPageId);
      const isStoragePageExists = storagePageId && pagesRef.current?.some(page => page._id === storagePageId);

      if (isUrlPageExists || isStoragePageExists || isPageWithTypePageExists) {
        const nextPageId = isUrlPageExists ? currentPageId : isStoragePageExists ? storagePageId : pagesRef.current![0]._id!;
        !isUrlPageExists ? setUrlPageId(nextPageId) : await loadPage(nextPageId);
        !isUrlPageExists && !isStoragePageExists && enqueueSnackbar(t('pages.pageNotExist'), { variant: 'error' });
      } else {
        setEditedPage({ name: defaultTitle });
        setPageTitle(defaultTitle);
      }

      setIsPageLoading(false);
    })();
  }, [pages, loadPage, currentPageId]);

  useDispatchEditorClick(lexicalEditorRef.current);

  const createOrUpdatePage = async (
    pageToUpdate: NewPage | Pick<PageResponse, '_id' | 'name' | 'content' | 'parentId' | 'page_type' | 'order'>,
  ) => {
    if (pageToUpdate._id) {
      const localPage = pagesByIdRef.current[pageToUpdate._id];
      const updatedPage = await updatePage(slug!, pageToUpdate._id!, {
        order: localPage.order,
        page_type: localPage.page_type,
        ...pageToUpdate,
        parentId: pageToUpdate.parentId ?? localPage.parentId ?? 'root',
      });
      updatePagesCache({ queryClient, projectSlug: slug }, prevPages => {
        if (!prevPages) return;
        return prevPages.filter(page => (page._id === pageToUpdate._id ? { ...page, name: pageToUpdate.name } : page));
      });
      return updatedPage;
    }

    const createdPage = await automatePage(slug!, pageToUpdate);
    savePageIdToLocalStorage(slug!, createdPage._id!);
    setEditedPage(createdPage);
    queryClient.invalidateQueries({ queryKey: allPagesQueryKey });
    return createdPage;
  };

  const onContentChange = useMemo(
    () =>
      debounce(async (pageToUpdate: EditedPage) => {
        if (ignoreNextEditorUpdate.current) {
          ignoreNextEditorUpdate.current = false;
          return;
        }

        const nextPage = await createOrUpdatePage(pageToUpdate);
        updateTreePages(prevPages =>
          pageToUpdate._id
            ? prevPages.map(page => (page._id === pageToUpdate._id ? nextPage : page))
            : [nextPage as PageMetadata, ...prevPages],
        );
      }, 500),
    [],
  );

  const onTitleValidate = async (nextTitle: string) => {
    if (!editedPage) return;

    if (!nextTitle.trim() || nextTitle.trim() === editedPage.name) {
      setPageTitle(editedPage.name);
    }

    const nextPage = await createOrUpdatePage({ ...editedPage, name: nextTitle });
    setEditedPage(prevPage => ({ ...prevPage, name: nextPage.name }));
    updateTreePages(prevPages =>
      editedPage._id
        ? prevPages.map(page => (page._id === editedPage._id ? { ...page, name: nextTitle } : page))
        : [nextPage as PageMetadata, ...prevPages],
    );
  };

  const onPage = useCallback(
    (pageId: string) => {
      setUrlPageId(pageId);
    },
    [searchParams],
  );

  const onCreate: CreateHandler<TreePage> = async ({ parentId }) => {
    const firstParentChild =
      parentId === null ? pagesRef.current?.[0] : pagesRef.current?.find(page => page.parentId === parentId);

    const newPage = await createOrUpdatePage({
      name: defaultTitle,
      parentId: parentId ?? 'root',
      page_type: parentId === TEMPLATE_FOLDER.id ? 'template' : 'page',
      order: firstParentChild?.order !== undefined ? firstParentChild.order - 1 : 0,
    });

    updateTreePages(prevPages => [{ ...newPage }, ...prevPages]);
    setUrlPageId(newPage._id!);
    savePageIdToLocalStorage(slug!, newPage._id!);

    return { id: newPage._id! };
  };

  const onRename: RenameHandler<TreePage> = ({ id, name, node }) => {
    if (!pagesRef.current) return;

    if (editedPage?._id === id) {
      setPageTitle(name);
      setEditedPage(prev => ({ ...prev, name }));
    }
    updateTreePages(prevPages => prevPages.map(page => (page._id === id ? { ...page, name } : page)));
    createOrUpdatePage({ _id: node.data.item.id, name });
  };

  const onMove: MoveHandler<TreePage> = ({ dragIds, parentId, parentNode, index }) => {
    updateTreePages(prevPages => {
      const nextOrder =
        (parentNode?.children?.[index]?.data?.item?.page?.order ?? pagesTree![index]?.item?.page?.order ?? Infinity) - 0.5;

      const updatedPages = sortBy(
        prevPages.map(page =>
          dragIds.includes(page._id!)
            ? {
                ...page,
                page_type: parentId === TEMPLATE_FOLDER.id ? ('template' as const) : ('page' as const),
                parentId: parentId === TEMPLATE_FOLDER.id ? undefined : parentId,
                order: nextOrder,
              }
            : { ...page },
        ),
        ['parentId', 'order'],
      );

      let order = 0;
      let prevParentId: PageMetadataParentId | undefined = undefined;
      // Order will be set to order that pages have inside the array.
      updatedPages.map(page => {
        if (prevParentId !== page.parentId) {
          order = 0;
          prevParentId = page.parentId;
        }

        order++;
        page.order = order;
      });

      return updatedPages;
    });

    const updatedPages = pagesRef.current!.map(({ _id, name, order, page_type, parentId: parentIdToUpdate }) => ({
      page_id: _id!,
      name,
      order,
      parentId: page_type === 'template' ? null : parentIdToUpdate,
      page_type,
    }));
    updatePages(slug!, updatedPages);

    const updatedCurrentPage = updatedPages.find(page => page.page_id === editedPage?._id);
    if (updatedCurrentPage) {
      setEditedPage(prevPage => ({ ...prevPage, ...updatedCurrentPage }));
    }
  };

  const onDelete: DeleteHandler<TreePage> = async ({ ids }) => {
    const multiplePages = ids.length > 1;
    const result = await showConfirmDialog({
      title: multiplePages ? t('pages.deleteConfirm.titleMultiple') : t('pages.deleteConfirm.title'),
      description: multiplePages ? t('pages.deleteConfirm.descriptionMultiple') : t('pages.deleteConfirm.description'),
      confirm: t('pages.deleteConfirm.confirm'),
    });

    if (!result) return;

    ids.forEach(pageId => deletePage(slug!, pageId));
    updateTreePages(prevPages => prevPages.filter(({ _id }) => !ids.includes(_id!)));
    queryClient.setQueryData<PageMetadata[]>(allPagesQueryKey, allPages => allPages?.filter(page => !ids.includes(page._id!)));

    if (!editedPage?._id || !ids.includes(editedPage._id)) return;

    if (pagesRef.current?.length) {
      loadPage(pagesRef.current[0]._id!);
    } else {
      setSearchParams(prevParams => {
        const nextUrlParams = new URLSearchParams(prevParams);
        nextUrlParams.delete('pageId');
        return nextUrlParams;
      });
      setEditedPage({ name: defaultTitle });
      setPageTitle(defaultTitle);
      clearEditor();
    }
  };

  const onPublicToggle = async () => {
    if (!editedPage || !isExistedPage(editedPage)) return;

    const nextIsPrivate = !editedPage.isPrivate;
    const result = nextIsPrivate
      ? true
      : await showConfirmDialog({
          title: t('pages.editor.makePagePublicConfirm.title'),
          description: t('pages.editor.makePagePublicConfirm.description'),
          confirm: t('pages.editor.makePagePublicConfirm.confirm'),
        });
    if (!result) return;

    setEditedPage(prevPage => ({ ...prevPage, isPrivate: nextIsPrivate }));

    try {
      await setPageIsPrivate(slug!, editedPage._id!, { is_private: nextIsPrivate });
      enqueueSnackbar(nextIsPrivate ? t('pages.editor.makePagePrivateSuccess') : t('pages.editor.makePagePublicSuccess'));
    } catch {
      setEditedPage(prevPage => ({ ...prevPage, isPrivate: !nextIsPrivate }));
    }
  };

  const onExport = async () => {
    if (!lexicalEditorRef.current) return;

    const logoBase64 = await loadImageBase64(organization.logo.url);
    const proportions = organization.logo.width! / organization.logo.height!;

    lexicalEditorRef.current.update(async () => {
      const todayDate = format(Date.now(), 'P');
      const title = editedPage?.name ?? '';
      const pageName = title ? `${title}-` : 'pelles-page-';

      const nodes = $getRoot().getChildren();
      const blob = await pdf(
        <LexicalPdfExport title={editedPage!.name} nodes={nodes} logoBase64={logoBase64} logoProportions={proportions} />,
      ).toBlob();
      saveAs(blob, `${pageName}${todayDate}.pdf`);
    });
  };

  const onCopy = () => {
    if (!lexicalEditorRef.current) return;

    lexicalEditorRef.current.getEditorState().read(async () => {
      const htmlString = $generateHtmlFromNodes(lexicalEditorRef.current!);
      const clipboardItem = new ClipboardItem({
        'text/html': new Blob([htmlString], { type: 'text/html' }),
        'text/plain': new Blob([htmlString], { type: 'text/plain' }),
      });

      try {
        await navigator.clipboard.write([clipboardItem]);
        enqueueSnackbar(t('pages.editor.copySuccess'));
      } catch (error) {
        enqueueSnackbar(t('pages.editor.copyError'), { variant: 'error' });
      }
    });
  };

  const onMakeTemplate = async (page: TreePage) => {
    const newPage = await duplicatePage(slug!, page.item.id, { to_page_type: 'template' });
    updateTreePages(prevPages => [newPage, ...prevPages]);
    setUrlPageId(newPage._id!);
  };

  const resetSlider = () => {
    if (isSliderActionDataLoading) return;
    setSliderStateActions(INITIAL_STATE);
    setIsConfirmButtonShowed(false);
    setIsSliderChangeDisabled(false);
  };

  const putAiTextIntoEditor = (textFromAi: string) => {
    if (!lexicalEditorRef.current) return;

    lexicalEditorRef.current.update(() => {
      $getRoot().clear();

      const newParagraphNode = $createElementNodeFromMarkdown(textFromAi);
      const selection = $createRangeSelection();
      selection.insertNodes(newParagraphNode.getChildren());
    });
    resetSlider();
  };

  const onCreatePageFromTemplate = async (page: TreePage) => {
    const newPage = await duplicatePage(slug!, page.item.id, { to_page_type: 'page' });
    updateTreePages(prevPages => [newPage, ...prevPages]);
    setUrlPageId(newPage._id!);
  };

  const onValueChange = (_: Event, newValue: number | number[]) => {
    if (isSliderChangeDisabled) return;

    setSliderStateActions(prev => ({ ...prev, sliderValue: newValue as number }));
    setActionData({
      ...actionData,
      params: { change_percent: newValue as number },
    });
  };

  const onValueChangeCommitted = () => {
    setIsSliderChangeDisabled(true);
    setIsConfirmButtonShowed(true);
  };

  const onConfirmSelection = async () => {
    try {
      setIsSliderActionDataLoading(true);
      const markdown = await editPage(slug, currentPageId!, {
        action: actionData.action,
        params: actionData.params,
      });
      putAiTextIntoEditor(markdown as string);
    } catch (err) {
      console.error(err);
    } finally {
      setIsSliderActionDataLoading(false);
    }
  };

  const handleAction = (action: PageActionType, params: PageEditActionParams) => {
    setActionData({ action, params });
    setSliderStateActions(prev => ({
      ...prev,
      showSlider: action !== PageActionType.touch_up,
    }));
  };

  useEffect(() => {
    if (actionData.action === PageActionType.touch_up) {
      onConfirmSelection();
    }
  }, [actionData]);

  return (
    <Box
      sx={{
        display: 'grid',
        height: '100%',
        gridTemplateRows: isPagesLoading ? 'auto' : 'auto 1fr',
        gridTemplateColumns: '100%',
      }}
    >
      {!isPagesLoading && (
        <PagesDrawer
          currentPageId={currentPageId}
          currentPagePath={currentPagePath}
          pagesTree={pagesTree}
          onPage={onPage}
          onMove={onMove}
          onCreate={onCreate}
          onRename={onRename}
          onDelete={onDelete}
          onMakeTemplate={onMakeTemplate}
          onCreatePageFromTemplate={onCreatePageFromTemplate}
        />
      )}

      {isPageLoading || !editedPage ? (
        <Stack justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <PagesEditor
            ref={lexicalEditorRef}
            sx={{ display: 'grid', gridTemplateRows: isTemplate ? 'auto auto 1fr' : 'auto 1fr' }}
            shellSx={{ height: `calc(100% + ${spacing(3)})`, mt: -3 }}
            placeholder={t('pages.editor.placeholder')}
            initialState={editedPage?.content as SerializedLexicalNode[]}
            askAi={askAi}
            isReadOnly={isReadOnly}
            getSuggestions={getSuggestions}
            onChange={(content, markdown) => onContentChange({ ...editedPage!, content, markdown })}
            getMentions={getMentions}
            width={width}
            minMaxHeight={minMaxHeight}
            aiMenuItems={aiMenuItems}
          >
            {isTemplate && (
              <Box
                sx={{
                  position: 'sticky',
                  top: 20,
                  zIndex: 1000000,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  height: 0,
                  mr: '-40px',
                }}
              >
                <FormControlLabel
                  sx={{
                    borderRadius: 4,
                    boxShadow: 1,
                    backgroundColor: palette.background.default,
                    '& .MuiTypography-root': {
                      pr: 1,
                      fontSize: 'body4.fontSize',
                    },
                  }}
                  control={<Switch checked={!isReadOnly} size="small" onChange={(_, value) => setIsReadOnly(!value)} />}
                  label={
                    <Icon
                      name={isReadOnly ? 'lockLocked' : 'lockUnlocked'}
                      fontSize="xsmall"
                      htmlColor={isReadOnly ? palette.grey[700] : palette.primary.dark}
                    />
                  }
                />
              </Box>
            )}
            <Box sx={getFadingShadowSxProps(7)}>
              <CellEditable
                sx={{
                  width: '100%',
                  maxWidth: '100%',
                  p: 3,
                  pl: `${EDITOR_CONTENT_LEFT_PADDING}px`,
                  pr: 0,
                  ...typography.h1,
                  '&:hover, &:focus, &.Mui-focused ': {
                    '&.MuiInputBase-root': { borderRadius: 0, borderColor: 'transparent' },
                  },
                }}
                readOnly={isReadOnly}
                textAlign="left"
                value={pageTitle}
                onChangeValue={setPageTitle}
                onValidateValue={onTitleValidate}
              />
            </Box>
          </PagesEditor>
          <Stack
            sx={{
              position: 'absolute',
              bottom: spacing(EDITOR_SHEET_MARGIN_SPACING + 2),
              left: spacing(EDITOR_SHEET_MARGIN_SPACING + 2),
            }}
            alignItems="center"
            gap={1.5}
          >
            {sliderStateActions.showSlider && (
              <Stack
                sx={{
                  height: 225,
                  mb: 1.5,
                  boxShadow: 1,
                  backgroundColor: palette.background.default,
                  py: 2,
                  px: 2.25,
                  borderRadius: 999,
                }}
              >
                <SliderWithConfirm
                  onClickAway={resetSlider}
                  isConfirmButtonShowed={isConfirmButtonShowed}
                  isSliderActionDataLoading={isSliderActionDataLoading}
                  onValueChangeCommitted={onValueChangeCommitted}
                  onValueChange={onValueChange}
                  onConfirmSelection={onConfirmSelection}
                  sliderValue={sliderStateActions.sliderValue}
                  type={actionData.action}
                />
              </Stack>
            )}
            <FabMenu popupId="editor-fabs" resetSlider={resetSlider}>
              <Tooltip arrow title={t('pages.editor.touchUps')} placement="right">
                <FabButton iconName="paintBrush" onClick={() => handleAction(PageActionType.touch_up, null)} />
              </Tooltip>
              <Tooltip arrow title={t('pages.editor.writingLevel')} placement="right">
                <FabButton
                  iconName="reading"
                  onClick={() =>
                    handleAction(PageActionType.change_page_reading_level, {
                      change_percent: sliderStateActions.sliderValue,
                    })
                  }
                />
              </Tooltip>
              <Tooltip arrow title={t('pages.editor.pageLength')} placement="right">
                <FabButton
                  iconName="paragraphAdjustment"
                  onClick={() =>
                    handleAction(PageActionType.change_page_length, { change_percent: sliderStateActions.sliderValue })
                  }
                />
              </Tooltip>
              {false && (
                <Tooltip arrow title={t('pages.editor.writeEmail')} placement="right">
                  <FabButton iconName="email" isDisabled onClick={() => {}} />
                </Tooltip>
              )}
              {isExistedPage(editedPage) && (
                <Tooltip
                  arrow
                  title={editedPage.isPrivate ? t('pages.editor.makePagePublic') : t('pages.editor.makePagePrivate')}
                  placement="right"
                >
                  <FabButton iconName={editedPage.isPrivate ? 'public' : 'private'} onClick={onPublicToggle} />
                </Tooltip>
              )}
              <Tooltip arrow title={t('pages.editor.exportPDF')} placement="right">
                <FabButton iconName="download" onClick={onExport} />
              </Tooltip>
              <Tooltip arrow title={t('pages.editor.copy')} placement="right">
                <FabButton iconName="cards" onClick={onCopy} />
              </Tooltip>
            </FabMenu>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default Pages;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { Document, uploadFileWithVdb, getProjectsIdDocumentsDocumentId } from '@/api/generated';
import { RootState } from '@/store';
import { createAppAsyncThunk } from '@/store/createAppAsyncThunk';

type SingleProgressState = {
  progress: number;
  name: string;
  parentId?: string;
  order?: number;
  versionedFileId?: string;
  document?: Document;
  error?: AxiosError;
};
type ProjectProgressState = {
  [id: string]: SingleProgressState;
};
export type UploadProgressState = {
  [slug: string]: ProjectProgressState;
};

type UploadProgressPayload = { id: string; slug: string; progress: number };
type UploadPayload = {
  id: string;
  slug: string;
  parentId?: string;
  order?: number;
  file: File;
  versionedFileId?: string;
};

const uploadFileThunk = createAppAsyncThunk<Document, UploadPayload, { rejectValue: AxiosError }>(
  'documents/upload',
  async (data, api) => {
    const { id, slug, parentId, order = 0, file, versionedFileId } = data;
    let document = await uploadFileWithVdb(
      slug,
      { file },
      { parentId, order, versioned_file_id: versionedFileId },
      {
        onUploadProgress: progressEvent => {
          const { loaded, total } = progressEvent;
          const percentCompleted = Math.round((loaded * 90) / (total || 0));
          api.dispatch(setUploadProgress({ id, slug, progress: percentCompleted }));
        },
      },
    );

    while (!document.urls?.length) {
      document = await getProjectsIdDocumentsDocumentId(slug, { document_id: document._id! });
      await new Promise(resolve => setTimeout(resolve, 4000));
    }
    api.dispatch(setUploadProgress({ id, slug, progress: 100 }));

    return document;
  },
);

const uploadProgressSlice = createSlice({
  name: 'uploadProgress',
  initialState: {} as UploadProgressState,
  reducers: {
    setUploadProgress: (state: UploadProgressState, action: PayloadAction<UploadProgressPayload>) => ({
      ...state,
      [action.payload.slug]: {
        ...state[action.payload.slug],
        [action.payload.id]: {
          ...state[action.payload.slug]?.[action.payload.id],
          progress: action.payload.progress,
        },
      },
    }),
  },
  extraReducers: builder => {
    builder
      .addCase(uploadFileThunk.pending, (state: UploadProgressState, action) => {
        state[action.meta.arg.slug] = state[action.meta.arg.slug] ?? {};
        state[action.meta.arg.slug][action.meta.arg.id] = {
          progress: 0,
          name: action.meta.arg.file.name,
          parentId: action.meta.arg.parentId,
          order: action.meta.arg.order,
          versionedFileId: action.meta.arg.versionedFileId,
        };
      })
      .addCase(uploadFileThunk.fulfilled, (state: UploadProgressState, action) => {
        state[action.meta.arg.slug][action.meta.arg.id].document = action.payload;
      })
      .addCase(uploadFileThunk.rejected, (state: UploadProgressState, action) => {
        state[action.meta.arg.slug][action.meta.arg.id].error = action.payload as AxiosError;
      });
  },
});

const { setUploadProgress } = uploadProgressSlice.actions;

const EMPTY_STATE: ProjectProgressState = {};
export const selectUploadProgress = (state: RootState) => state.uploadProgress;
export const selectProjectUploadProgress = (projectSlug?: string) => (state: RootState) =>
  projectSlug ? state.uploadProgress[projectSlug] : EMPTY_STATE;

export { uploadFileThunk };
export default uploadProgressSlice;

import { Components, Theme } from '@mui/material/styles';

const MuiTab: Components<Theme>['MuiTab'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      display: 'grid',
      minWidth: 'auto',
      minHeight: 'unset',
      padding: 0,
      paddingBottom: theme.spacing(2),
      textTransform: 'capitalize',
      fontSize: theme.typography.h3.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: '20px',
      color: theme.palette.text.primary,
      borderBottom: '2px solid transparent',

      '&.Mui-selected': {
        color: theme.palette.primary.dark,
        fontWeight: theme.typography.fontWeightBold,
        borderBottomColor: theme.palette.primary.dark,
      },

      // Prevent text jumping.
      '&::before': {
        gridColumn: '1 / 2',
        content: `attr(title)`,
        height: 0,
        fontWeight: theme.typography.fontWeightBold,
        opacity: 0,
      },
    }),
    labelIcon: {
      '&::before': { gridColumn: '2 / 3' },
    },
  },
};
export default MuiTab;

import { Box, Button, Menu, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { useTranslation } from 'react-i18next';
import ArrowDown from '@/assets/arrow-down.svg?react';
import LogoutIcon from '@/assets/logout.svg?react';
import UserAvatar from '@/components/Avatar/UserAvatar';
import { useAuth } from '@/hooks/useAuth';
import TagChip from '@/components/TagChip/TagChip';

const UserDropdown: FC = () => {
  const { currentUser, logout } = useAuth();
  const { t } = useTranslation('common');
  const { palette, typography, shadows, shape } = useTheme();
  const popupState = usePopupState({ variant: 'popover', popupId: 'current-user-dropdown' });

  if (!currentUser) return;

  return (
    <>
      <Button
        {...bindTrigger(popupState)}
        variant="contained"
        sx={{
          borderRadius: 999,
          textTransform: 'capitalize',
          lineHeight: '19.2px',
          py: 0.25,
          pl: 0.25,
          pr: 1.25,
          height: 'fit-content',
          color: palette.primary.contrastText,
          '.MuiButton-startIcon': { mr: 1 },
        }}
        startIcon={<UserAvatar size={36} user={currentUser} />}
        endIcon={
          <Box
            sx={{
              transform: `rotate(${popupState.isOpen ? '180deg' : '0deg'})`,
              transition: 'transform 0.2s ease-in-out',
              height: 26,
              width: 26,
              lineHeight: 0,
            }}
          >
            <ArrowDown style={{ height: 26, width: 26 }} />
          </Box>
        }
      >
        {currentUser.firstName}
      </Button>
      <Menu
        {...bindMenu(popupState)}
        PaperProps={{ sx: { boxShadow: shadows[2], borderRadius: shape.borderRadius } }}
        MenuListProps={{ sx: { backgroundColor: palette.background.default, width: '260px', p: 0 } }}
      >
        <MenuItem
          disableRipple
          sx={{
            backgroundColor: palette.background.default,
            '&:hover': { backgroundColor: palette.background.default, cursor: 'default' },
            p: 3,
          }}
        >
          <Stack maxWidth="100%">
            <Stack direction="row" gap={2.75} alignItems="center">
              <UserAvatar size={44} user={currentUser} />
              <Stack gap={1}>
                <Typography fontSize={typography.body2.fontSize} fontWeight={typography.fontWeightBold}>
                  {t('header.user.fullName', { firstName: currentUser.firstName, lastName: currentUser.lastName })}
                </Typography>
                <Typography fontSize={typography.body3.fontSize}>{currentUser.title}</Typography>
                <Typography fontSize={typography.body3.fontSize}>{currentUser.email}</Typography>
              </Stack>
            </Stack>
            {currentUser.isAdmin && (
              <Stack direction="row" sx={{ pt: 1, pl: 8.25 }} gap={0.8}>
                <TagChip
                  name={t('header.user.admin')}
                  color={palette.background.default}
                  sx={{ border: `1px solid ${palette.primary.main}`, py: 0.7 }}
                />
              </Stack>
            )}
            {!!currentUser.tags?.length && (
              <Stack direction="row" flexWrap="wrap" gap={0.8} sx={{ pt: 1, pl: 8.25 }}>
                {currentUser.tags.map(tag => (
                  <TagChip
                    key={tag.name}
                    name={tag.name}
                    color={
                      tag.color?.toLocaleLowerCase() === palette.background.default.toLocaleLowerCase()
                        ? palette.primary.main
                        : tag.color ?? palette.primary.main
                    }
                  />
                ))}
              </Stack>
            )}
          </Stack>
        </MenuItem>
        <MenuItem sx={{ py: 1.5, px: 3, borderTop: `1px solid ${palette.grey[200]}` }} onClick={logout}>
          <Button sx={{ p: 0, color: palette.grey[800], '.MuiButton-startIcon': { mr: 1.5 } }} startIcon={<LogoutIcon />}>
            {t('header.user.signOut')}
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
};
export default UserDropdown;

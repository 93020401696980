import { FC } from 'react';
import { Box, IconButton, Tooltip, useTheme } from '@mui/material';
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'react-i18next';
import TagsSelectPopover from '@/components/TagsSelectPopover';
import TagView from '@/components/TagView';
import { Tag } from '@/api/generated';
import Icon from '@/components/Icon/Icon';

interface TagSelectProps {
  isDisabled?: boolean;
  visibleTagsLimit?: number;
  isHighlighted?: boolean;
  headerText?: string;
  tags?: Tag[];
  selectedTags: string[];
  onTagAdd: (name: string) => void;
  onTagDelete: (name: string) => void;
  onEnter?: (search: string) => void;
}

const TagSelect: FC<TagSelectProps> = ({
  isDisabled,
  visibleTagsLimit,
  isHighlighted,
  headerText,
  tags = [],
  selectedTags,
  onTagAdd,
  onTagDelete,
  onEnter,
}) => {
  const { t } = useTranslation('common');
  const { palette } = useTheme();
  const popupState = usePopupState({ popupId: 'tags-select', variant: 'popover' });

  const limitedTags = visibleTagsLimit ? selectedTags.slice(0, visibleTagsLimit) : selectedTags;

  const onTagToggle = (name: string) => {
    if (isDisabled) return;

    if (selectedTags.includes(name)) {
      onTagDelete(name);
    } else {
      onTagAdd(name);
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', flexWrap: 'wrap' }}>
      {limitedTags.map(tagName => (
        <TagView
          key={tagName}
          tag={tagName}
          disableRipple
          isHighlighted={isHighlighted}
          onDelete={isDisabled ? undefined : () => onTagToggle(tagName)}
          sx={{ maxWidth: '150px' }}
        />
      ))}
      {!isDisabled && (
        <Tooltip arrow title={t('tagSelect.tooltip')} disableInteractive>
          <IconButton
            size="small"
            disableRipple
            {...bindTrigger(popupState)}
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              minHeight: '100%',
              borderRadius: 999,
              backgroundColor: isHighlighted ? palette.grey['300'] : palette.background.default,
              '&, &:hover': {
                backgroundColor: isHighlighted ? palette.grey['300'] : palette.background.default,
                color: palette.grey[800],
                boxShadow: 0,
              },
            }}
          >
            <Icon name="plus" fontSize="xsmall" />
            <Box sx={{ pb: '1px', fontSize: 'body3.fontSize' }}>
              {visibleTagsLimit && selectedTags.length > visibleTagsLimit ? selectedTags.length - visibleTagsLimit : ''}
            </Box>
          </IconButton>
        </Tooltip>
      )}
      <TagsSelectPopover
        popupState={popupState}
        placeholder={t('tagSelect.searchPlaceholder')}
        headerText={headerText}
        tags={tags}
        selectedTags={selectedTags}
        onTagToggle={onTagToggle}
        onEnter={onEnter}
      />
    </Box>
  );
};

export default TagSelect;

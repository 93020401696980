import { FC, memo, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Paper, Tooltip, useTheme } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { Position, Rnd, RndDragCallback, RndResizeCallback } from 'react-rnd';
import { useStorageState } from 'react-storage-hooks';
import Header from '@/views/Knowledge/components/Header';
import { FormInputSubmitRef } from '@/components/FormInputSubmit/FormInputSubmit';
import Chat from '@/views/Knowledge/components/Chat';
import Threads from '@/views/Knowledge/components/Threads';
import { Thread } from '@/api/generated';
import { useKnowledge } from '@/views/Knowledge/useKnowledge';
import KnowledgeBase from '@/views/Knowledge/components/KnowledgeBase';
import { TABS } from '@/views/Knowledge/components/consts';
import { useConfirmDialog } from '@/hooks/useConfirmDialog';
import { Size } from '@/views/Knowledge/types';
import {
  MIN_HEIGHT,
  MIN_WIDTH,
  recalculateSafePositionForCollapsed,
} from '@/views/Knowledge/utils/recalculateSafePositionForCollapsed';
import { EXPANDED_OFFSET, getPositionAndSizeForExpanded } from '@/views/Knowledge/utils/getPositionAndSizeForExpanded';
import { HomepageActions } from '@/views/Project/views/Header/types';
import { useUrlAction } from '@/views/Project/views/Homepage/utils/useUrlAction';
import { TreeFileSystemNode } from '@/types';
import Icon from '@/components/Icon/Icon';
import RootPortal from '@/components/RootPortal';

export interface MessagesValue {
  question?: string;
  answer?: string;
}

const HEADER_HEIGHT = 83;
const INITIAL_POPOVER_SIZE = {
  width: MIN_WIDTH,
  height: MIN_HEIGHT,
};

const WIDTH_OFFSET = 30;
const HEIGHT_OFFSET = 95;

const OpenKnowledge: FC = memo(() => {
  const { t } = useTranslation('common');
  const { palette, shadows } = useTheme();
  const { isThreadLoading, setCurrentThreadId, currentThreadId, isModified, setIsModified, isActive, setIsActive } =
    useKnowledge();
  const [tabValue, setTabValue] = useState<TABS>(TABS.CHAT);
  const [currentQuestionResponse, setCurrentQuestionResponse] = useState<Thread | undefined>();
  const [messages, setMessages] = useState<MessagesValue[]>([]);
  const [isWeb, setIsWeb] = useState<boolean>(false);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const formInputSubmitRef = useRef<FormInputSubmitRef | null>(null);
  const { showConfirmDialog } = useConfirmDialog();
  const treeNodesByIdRef = useRef<Record<string, TreeFileSystemNode>>({});

  const [popOverSize, setPopupSize] = useStorageState(sessionStorage, `knowledge-popup-size`, () => {
    const { size: initialSize } = recalculateSafePositionForCollapsed(INITIAL_POPOVER_SIZE, {
      x: window.innerWidth - WIDTH_OFFSET - INITIAL_POPOVER_SIZE.width,
      y: window.innerHeight - HEIGHT_OFFSET - INITIAL_POPOVER_SIZE.height,
    });
    return initialSize;
  });
  const [popOverPosition, setPopOverPosition] = useStorageState(sessionStorage, `knowledge-popup-position`, () => {
    const { position: initialPosition } = recalculateSafePositionForCollapsed(INITIAL_POPOVER_SIZE, {
      x: window.innerWidth - WIDTH_OFFSET - INITIAL_POPOVER_SIZE.width,
      y: window.innerHeight - HEIGHT_OFFSET - INITIAL_POPOVER_SIZE.height,
    });
    return initialPosition;
  });
  const sizeBeforeExpand = useRef<Size>(popOverSize);
  const positionBeforeExpand = useRef<Position>(popOverPosition);
  const [isExpanded, setIsExpanded] = useState(false);

  const commonTabsStyle = {
    px: 6,
    py: 2,
    overflowY: 'auto',
  };

  const handleTabChange = async (_: SyntheticEvent, newValue: TABS) => {
    const result =
      tabValue === TABS.KNOWLEDGE_BASE && isModified
        ? await showConfirmDialog({
            title: t('knowledge.memos.discardModal.title'),
            confirm: t('knowledge.memos.discardModal.discard'),
            cancel: t('knowledge.memos.discardModal.cancel'),
          })
        : true;

    if (result) {
      setTabValue(newValue);
      setIsModified(false);
    }
  };

  useEffect(() => {
    if (tabValue === TABS.CHAT && chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [tabValue]);

  useEffect(() => {
    if (isActive && formInputSubmitRef.current) {
      formInputSubmitRef.current.focus();
    }
  }, [tabValue, isActive]);

  const createNewThread = () => {
    setMessages([]);
    setCurrentQuestionResponse(undefined);
    setCurrentThreadId('');
    setTabValue(TABS.CHAT);
  };

  const onPopoverClose = async () => {
    const result = isModified
      ? await showConfirmDialog({
          title: t('knowledge.memos.discardModal.title'),
          confirm: t('knowledge.memos.discardModal.discard'),
          cancel: t('knowledge.memos.discardModal.cancel'),
        })
      : true;

    if (result) {
      setIsActive(false);
      setIsModified(false);
    }
  };

  const onToggleKbPopover = () => {
    if (isActive) return onPopoverClose();
    setIsActive(!isActive);
  };

  useEffect(() => {
    const onResize = () => {
      const { position, size } = isExpanded
        ? getPositionAndSizeForExpanded()
        : recalculateSafePositionForCollapsed(popOverSize, popOverPosition);

      if (!isExpanded) {
        positionBeforeExpand.current = { ...position };
        sizeBeforeExpand.current = { ...size };
      }

      setPopOverPosition(position);
      setPopupSize(size);
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [popOverPosition, popOverSize, isExpanded]);

  const onResizeStop: RndResizeCallback = (_e, _direction, ref, _delta, position) => {
    const { position: nextPosition, size: nextSize } = recalculateSafePositionForCollapsed(
      {
        width: Math.max(ref.offsetWidth, MIN_WIDTH),
        height: Math.max(ref.offsetHeight, MIN_HEIGHT),
      },
      position,
    );
    setPopupSize(nextSize);
    setPopOverPosition(nextPosition);
  };

  const onDragStop: RndDragCallback = (_e, data) => {
    setPopOverPosition({ x: data.x, y: data.y });
  };

  const onToggleExpandPopover = () => {
    if (isExpanded) {
      setIsExpanded(false);
      const { position, size } = recalculateSafePositionForCollapsed(
        sizeBeforeExpand.current ?? INITIAL_POPOVER_SIZE,
        positionBeforeExpand.current ?? {
          x: window.innerWidth - INITIAL_POPOVER_SIZE.width - WIDTH_OFFSET,
          y: window.innerHeight - INITIAL_POPOVER_SIZE.height - HEIGHT_OFFSET,
        },
      );
      setPopOverPosition(position);
      setPopupSize(size);
    } else {
      setIsExpanded(true);
      positionBeforeExpand.current = { ...popOverPosition };
      sizeBeforeExpand.current = { ...popOverSize };

      setPopOverPosition({
        x: window.innerWidth / 20,
        y: window.innerHeight / 20,
      });
      setPopupSize({
        width: window.innerWidth * 0.9,
        height: window.innerHeight * 0.9,
      });
    }
  };

  useUrlAction(HomepageActions.OPEN_KB_DIALOG, () => {
    onToggleKbPopover();
  });

  return (
    <>
      <Tooltip arrow title={t('tooltips.kb')} placement="top">
        <Button sx={{ gap: 1, borderRadius: 999 }} variant="contained" onClick={onToggleKbPopover}>
          <Icon name="bulb" />
          {t('knowledge.openButton')}
        </Button>
      </Tooltip>
      <RootPortal>
        <Rnd
          dragHandleClassName="draggable-header"
          bounds="window"
          onResizeStop={onResizeStop}
          onDragStart={e => {
            e.preventDefault();
          }}
          onDragStop={onDragStop}
          size={popOverSize}
          position={popOverPosition}
          enableResizing={!isExpanded}
          disableDragging={isExpanded}
          resizeHandleStyles={{
            top: { cursor: 'ns-resize' },
            bottom: { cursor: 'ns-resize' },
            left: { cursor: 'ew-resize' },
            right: { cursor: 'ew-resize' },
          }}
          maxWidth={isExpanded ? window.innerWidth * (1 - EXPANDED_OFFSET) : undefined}
          maxHeight={isExpanded ? window.innerHeight * (1 - EXPANDED_OFFSET) : undefined}
          style={{
            position: 'fixed',
            display: 'inline-flex',
            backgroundColor: palette.background.default,
            boxShadow: shadows[1],
            borderRadius: 8,
            flexDirection: 'column',
            overflow: 'hidden',
            zIndex: 1300,
            opacity: isActive ? 1 : 0,
            visibility: isActive ? 'visible' : 'hidden',
            transition: 'opacity 0.25s ease-in-out, visibility 0.25s ease-in-out',
            pointerEvents: 'all',
          }}
        >
          <Paper
            sx={{
              boxShadow: 0,
              borderRadius: 2,
              minWidth: MIN_WIDTH,
              minHeight: MIN_HEIGHT,
              width: '100%',
              height: '100%',
              cursor: 'auto !important',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <TabContext value={tabValue}>
              <Header
                handleTabChange={handleTabChange}
                setIsWeb={setIsWeb}
                isWeb={isWeb}
                createNewThread={createNewThread}
                onPopoverClose={onPopoverClose}
                onToggleExpandPopover={onToggleExpandPopover}
                isExpanded={isExpanded}
              />
              <TabPanel
                value={TABS.CHAT}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: tabValue === TABS.CHAT ? '100%' : 'auto',
                  mt: 'auto',
                  p: 0,
                  overflow: 'hidden',
                }}
                ref={chatContainerRef}
              >
                {isThreadLoading && currentThreadId ? (
                  <Box sx={{ position: 'absolute', left: '50%', top: '50%' }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Chat
                    currentQuestionResponse={currentQuestionResponse}
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                    messages={messages}
                    setMessages={setMessages}
                    isWeb={isWeb}
                    setCurrentQuestionResponse={setCurrentQuestionResponse}
                  />
                )}
              </TabPanel>
              <TabPanel sx={{ p: 0, overflowY: 'auto', height: `calc(100% - ${HEADER_HEIGHT}px)` }} value={TABS.THREADS}>
                <Threads setTabValue={setTabValue} tabValue={tabValue} />
              </TabPanel>
              <TabPanel
                sx={{ ...commonTabsStyle, height: `calc(100% - ${HEADER_HEIGHT}px)`, pl: 3, pr: 4 }}
                value={TABS.KNOWLEDGE_BASE}
              >
                <KnowledgeBase nodesByIdRef={treeNodesByIdRef} />
              </TabPanel>
            </TabContext>
          </Paper>
        </Rnd>
      </RootPortal>
      {isExpanded && isActive && (
        <Box
          sx={{
            position: 'fixed',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 1000,
            backgroundColor: palette.controls.border,
          }}
        />
      )}
    </>
  );
});

export default OpenKnowledge;

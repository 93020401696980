import GoogleAutoComplete from '@/components/GoogleAutoComplete/GoogleAutoComplete';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardEvent, useMemo, useState } from 'react';
import { IconButton, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { Control, FieldPath, FieldValues, useController, UseFormGetValues } from 'react-hook-form';
import Icon from '@/components/Icon/Icon';
import Map from '@/components/GoogleMap/GoogleMap';
import { ProjectFormValue } from '@/views/Projects/components/ProjectFormDialog/types';
import isEqual from 'lodash/isEqual';

interface AddressProps<Values extends FieldValues = FieldValues> {
  id: string;
  label: string;
  labelSx?: SxProps;
  placeholder: string;
  control: Control<Values>;
  name: FieldPath<Values>;
  options?: Omit<google.maps.places.AutocompletionRequest, 'input'>;
  onKeyDown: (event: KeyboardEvent) => void;
  onPlaceSelected: (place: google.maps.places.PlaceResult) => void;
  coordinates: { lat?: number; lng?: number };
  slug?: string;
  onSaveField: (data: ProjectFormValue) => Promise<void>;
  getValues: UseFormGetValues<ProjectFormValue>;
}

export default function Address<Values extends FieldValues>({
  id,
  name,
  control,
  onPlaceSelected,
  onKeyDown,
  coordinates,
  slug,
  onSaveField,
  getValues,
  labelSx,
}: AddressProps<Values>) {
  const { t } = useTranslation('projectUpdate');
  const [isEditing, setIsEditing] = useState(false);
  const { palette, spacing } = useTheme();
  const { field } = useController({ name, control });
  const { value: fieldValue } = field;

  const mapCenter = useMemo(() => {
    if (!coordinates?.lat || !coordinates.lng) return;
    return { lat: coordinates.lat, lng: coordinates.lng };
  }, [coordinates]);

  const prevValues = getValues();

  const isEqualValues = (
    currentValues: ProjectFormValue['location'] | ProjectFormValue['stakeholders'],
    updatedValues: ProjectFormValue['location'] | ProjectFormValue['stakeholders'],
  ) => isEqual(currentValues, updatedValues);

  const isInReadMode = !isEditing && !!mapCenter;
  const onBlur = () => {
    if (!slug) return;
    const allValues = getValues();

    setIsEditing(false);
    !isEqualValues(prevValues.location, allValues.location) && onSaveField(allValues);
  };

  return (
    <>
      <Stack flexDirection="row" alignItems="center" gap={1} sx={{ '&:hover .Address__edit': { opacity: 1 } }}>
        {isInReadMode ? (
          <Typography sx={labelSx}>{fieldValue}</Typography>
        ) : (
          <GoogleAutoComplete
            label={t('details.address')}
            placeholder={t('details.addressPlaceholder')}
            id={id}
            name={name}
            control={control}
            labelSx={labelSx}
            options={{ types: ['address'] }}
            onPlaceSelected={onPlaceSelected}
            onKeyDown={onKeyDown}
            autoFocus
            onBlur={onBlur}
            sx={{
              '& .MuiInputBase-root': {
                backgroundColor: palette.controls.background,
                input: {
                  fontSize: 'body3.fontSize',
                  fontWeight: 'fontWeightRegular',
                  '&.MuiAutocomplete-input': {
                    p: 1.5,
                  },
                },
              },
            }}
          />
        )}
        {isInReadMode && (
          <IconButton sx={{ opacity: 0 }} onClick={() => setIsEditing(true)} className="Address__edit">
            <Icon fontSize="small" name="edit" />
          </IconButton>
        )}
      </Stack>
      {mapCenter && (
        <Map
          center={mapCenter}
          containerStyle={{ width: '100%', height: 200, marginTop: spacing(2), borderRadius: '0.5rem', overflow: 'hidden' }}
        />
      )}
    </>
  );
}

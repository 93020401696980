import { Stack } from '@mui/material';
import { FC, MutableRefObject, SyntheticEvent, useEffect, useState } from 'react';
import { TabContext, TabPanel } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { TABS } from '@/views/Knowledge/components/consts';
import Tabs, { TabsValues } from '@/views/Knowledge/components/Tabs';
import OrganizationalDocuments from '@/views/Knowledge/components/OrganizationalDocuments';
import Memos from '@/views/Knowledge/components/Memos';
import { useKnowledge } from '@/views/Knowledge/useKnowledge';
import { useConfirmDialog } from '@/hooks/useConfirmDialog';
import { TreeFileSystemNode } from '@/types';

interface KnowledgeBaseProps {
  nodesByIdRef: MutableRefObject<Record<string, TreeFileSystemNode>>;
}

const tabs: TabsValues[] = [{ value: TABS.ORGANIZATIONAL_DOCUMENTS }, { value: TABS.MEMOS }];

const KnowledgeBase: FC<KnowledgeBaseProps> = ({ nodesByIdRef }) => {
  const { t } = useTranslation('common');
  const { isModified, setIsModified, isActive } = useKnowledge();
  const { showConfirmDialog } = useConfirmDialog();

  const [tabValue, setTabValue] = useState<TABS>(TABS.ORGANIZATIONAL_DOCUMENTS);

  useEffect(() => {
    !isActive && setTabValue(TABS.ORGANIZATIONAL_DOCUMENTS);
  }, [isActive]);

  const handleTabChange = async (_: SyntheticEvent, newValue: TABS) => {
    const result =
      tabValue === TABS.MEMOS && isModified
        ? await showConfirmDialog({
            title: t('knowledge.memos.discardModal.title'),
            confirm: t('knowledge.memos.discardModal.discard'),
            cancel: t('knowledge.memos.discardModal.cancel'),
          })
        : true;

    if (result) {
      setTabValue(newValue);
      setIsModified(false);
    }
  };

  return (
    <Stack sx={{ height: '100%' }}>
      <TabContext value={tabValue}>
        <Stack sx={{ pl: 1, width: '100%' }}>
          <Tabs tabs={tabs} handleTabChange={handleTabChange} />
        </Stack>
        <TabPanel sx={{ pt: 3, pb: 0, px: 0, display: 'flex', flexDirection: 'column' }} value={TABS.ORGANIZATIONAL_DOCUMENTS}>
          <OrganizationalDocuments nodesByIdRef={nodesByIdRef} />
        </TabPanel>
        <TabPanel sx={{ pt: 3, px: 0.5, pb: 2, height: '100%', mt: -3, ml: -3, pl: 3, pr: 0 }} value={TABS.MEMOS}>
          <Memos />
        </TabPanel>
      </TabContext>
    </Stack>
  );
};

export default KnowledgeBase;

import { convertMarkdownToLexicalState } from '@/containers/PagesEditor/utils/convertMarkdownToLexicalState';
import { getGetAllPagesQueryKey, PageMetadata, automatePage } from '@/api/generated';
import { enqueueSnackbar } from 'notistack';
import { useLoader } from '@/hooks/useLoader';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

type Params = {
  getName: () => string;
  slug: string;
};

export const useExportMarkdownToPage = ({ getName, slug }: Params) => {
  const { addLoadingId, removeLoadingId } = useLoader();
  const queryClient = useQueryClient();
  const { t } = useTranslation('common');

  return async (markdown?: string | null) => {
    if (!markdown) return;

    const lexicalState = await convertMarkdownToLexicalState(markdown, { shouldPreserveNewLines: true });
    const markdownNodes = lexicalState.root.children;
    try {
      addLoadingId('markdown-convert');
      const createdPage = await automatePage(slug, {
        name: getName(),
        content: markdownNodes,
      });
      queryClient.setQueryData<PageMetadata[]>(getGetAllPagesQueryKey(slug), pages => [...(pages ?? []), createdPage]);
      removeLoadingId('markdown-convert');
      return createdPage;
    } catch (error) {
      removeLoadingId('markdown-convert');
      enqueueSnackbar(t('exportError'), { variant: 'error' });
      console.error('Create page from markdown error', error);
    }
  };
};

import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { useCallback, useRef } from 'react';
import { acceptedFileTypes } from '@/utils/consts';

type Params = {
  uploadFiles: (files: File[], folderId?: string) => void;
};

export const useFilesUpload = ({ uploadFiles }: Params) => {
  const folderIdRef = useRef<string | undefined>(undefined);

  const beforeFilesUpload = (files: File[], _: FileRejection[], event: DropEvent | null) => {
    if (!event) {
      return uploadFiles(files, folderIdRef.current);
    }

    const dropTarget = event.target as HTMLElement;
    const closestFolder = dropTarget.closest<HTMLElement>('[data-folderid]');
    const folderIdAttr = closestFolder?.dataset?.['folderid'];
    const folderId = folderIdAttr && folderIdAttr !== 'root' ? folderIdAttr : undefined;

    uploadFiles(files, folderId);
  };

  const props = useDropzone({
    onDrop: beforeFilesUpload,
    accept: acceptedFileTypes,
    onFileDialogCancel: () => (folderIdRef.current = undefined),
  });

  const openWithId = useCallback((id: string) => {
    folderIdRef.current = id;
    props.open();
  }, []);

  return { openWithId, ...props };
};

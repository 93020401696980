import { FC, ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

interface RootPortalProps {
  children: ReactNode;
}

const RootPortal: FC<RootPortalProps> = ({ children }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    ref.current = document.getElementById('root-portal') as HTMLDivElement | null;
  }, []);

  if (!ref.current) return null;

  return createPortal(children, ref.current);
};

export default RootPortal;

import { createContext } from 'react';
import { DocumentMetadata, Thread, ThreadMetadata, User } from '@/api/generated';

interface KnowledgeContextValue {
  threads?: ThreadMetadata[];
  currentUser?: User;
  uploadFile: (file: File) => Promise<DocumentMetadata>;
  isUploadFilesFetching: boolean;
  threadsRefetch: () => Promise<unknown>;
  setCurrentThreadId: (currentThreadId: string) => void;
  currentThread?: Thread;
  currentThreadId?: string;
  isThreadLoading: boolean;
  isLoadingUpload: boolean;
  deleteThread: (threadId: string) => Promise<ThreadMetadata[]>;
  isDeleteThreadPending: boolean;
  uploadedFiles?: DocumentMetadata[];
  deleteDocument: (documentId: string) => Promise<unknown>;
  isModified: boolean;
  setIsModified: (value: boolean) => void;
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
  setLoadingUpload: (isLoadingUpload: boolean) => void;
  uploadedFilesRefetch: () => void;
}

export const KnowledgeContext = createContext<KnowledgeContextValue | null>(null);

import { ChangeEvent, memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Status, getGetProjectsQueryKey, getProjects, ProjectResponse, useGetRecentItems } from '@/api/generated';
import { usePageTitle } from '@/hooks/usePageTitle';
import Loader from '@/components/Loader/Loader';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useDebounceValue, useIntersectionObserver } from 'usehooks-ts';
import { SORT_DIRECTION } from '@/enums/sort';
import EmptyState from '@/views/Projects/views/EmptyState/EmptyState';
import Project from '@/views/Projects/components/Project/Project';
import ProjectsSkeleton from '@/views/Projects/components/ProjectsSkeleton';
import SortingArrows from '@/views/Projects/components/SortingArrows';
import CreateProjectButton from '@/views/Projects/components/CreateProjectButton/CreateProjectButton';
import RoundedInput from '@/components/RoundedInput';
import Icon from '@/components/Icon/Icon';
import SubjectWrapper from '@/views/Projects/components/SubjectWrapper';
import Documentations from '@/views/Projects/Documentations/Documentations';
import { useGreetingMessage } from '@/views/Project/views/Homepage/utils/useGreetingMessage';
import RecentlyViewed from '@/views/Projects/Documentations/RecentlyViewed';
import { toProjects } from '@/services/linker';
import Apps from '@/views/Projects/components/Apps/Apps';

const PAGE_LIMIT = 10;
const HEADER_KEYS: (keyof ProjectResponse)[] = ['name', 'address', 'type', 'last_modified'];
const SORT_DIRECTIONS = [SORT_DIRECTION.DESC, SORT_DIRECTION.ASC, SORT_DIRECTION.NONE];
const STATUSES = [Status.active, Status.done];
const cellWidth = [3, 3, 2.5, 2];

const Projects = memo(function Projects() {
  const { t } = useTranslation('projects');
  const { palette, breakpoints } = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { isIntersecting, ref } = useIntersectionObserver({});
  const { setPageTitle } = usePageTitle();
  const status = (searchParams.get('status') as Status | null) ?? Status.active;
  const searchFilter = searchParams.get('searchFilter') || undefined;
  const sortDir = (searchParams.get('sortDir') as SORT_DIRECTION | null) ?? SORT_DIRECTION.NONE;
  const sortBy = searchParams.get('sortBy') ?? undefined;
  const greetingMessage = useGreetingMessage();

  const { data: recentlyViewedItems, isLoading: isLoadingRecentlyViewedItems } = useGetRecentItems({ max_items: 10 });

  const [debouncedSearchFilter] = useDebounceValue(searchFilter, 100);

  const { data, isLoading, isPending, isFetching, isFetchingNextPage, fetchNextPage, refetch, hasNextPage, dataUpdatedAt } =
    useInfiniteQuery({
      initialPageParam: 0,
      placeholderData: previousData => previousData,
      queryKey: [getGetProjectsQueryKey(), status, sortDir, sortBy, debouncedSearchFilter],
      queryFn: ({ pageParam }: { pageParam: number }) =>
        getProjects({
          statusFilter: status,
          searchFilter: debouncedSearchFilter,
          offset: pageParam,
          limit: PAGE_LIMIT,
          sortDir: sortDir === SORT_DIRECTION.NONE ? SORT_DIRECTION.DESC : sortDir,
          sortBy: sortDir === SORT_DIRECTION.NONE ? 'last_modified' : sortBy,
        }),
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.projects?.[0]?.status !== status) return undefined;
        return (lastPage.projects?.length ?? 0) < PAGE_LIMIT ? undefined : pages.length * PAGE_LIMIT;
      },
    });

  const totalProjects = data?.pages?.[0]?.total ?? 0;
  const projects = useMemo(() => data?.pages.flatMap(page => page.projects!), [dataUpdatedAt]);
  const tabs = STATUSES.map(statusKey => ({
    label: (
      <Tooltip arrow title={t(`tooltips.${statusKey.toLocaleLowerCase()}`)} placement="top">
        <Box component="span">
          {t(`status.${statusKey.toLocaleLowerCase()}`, {
            count: statusKey === status && totalProjects ? totalProjects : 0,
          })}
        </Box>
      </Tooltip>
    ),
    value: statusKey,
    to: toProjects({ status: statusKey, sortDir, sortBy, searchFilter }),
  }));

  useEffect(() => {
    if (!isIntersecting || isFetchingNextPage) return;
    fetchNextPage();
  }, [fetchNextPage, isIntersecting, isFetchingNextPage]);

  useEffect(() => {
    setPageTitle(t('pageTitle'));
  }, [setPageTitle, t]);

  const onSort = (headerKey: string) => {
    const updatedParams = new URLSearchParams(searchParams);

    if (sortBy === headerKey) {
      const currentIndex = SORT_DIRECTIONS.findIndex(dir => dir === sortDir);
      const nextSortDir = SORT_DIRECTIONS[currentIndex + 1] ?? SORT_DIRECTIONS[0];
      updatedParams.set('sortDir', nextSortDir);
      if (nextSortDir === SORT_DIRECTION.NONE) {
        updatedParams.delete('sortBy');
        updatedParams.delete('sortDir');
      }
    } else {
      updatedParams.set('sortDir', SORT_DIRECTION.DESC);
      updatedParams.set('sortBy', headerKey);
    }

    setSearchParams(updatedParams);
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const nextValue = event.target.value;
    const updatedParams = new URLSearchParams(searchParams);
    nextValue.trim() ? updatedParams.set('searchFilter', nextValue) : updatedParams.delete('searchFilter');
    setSearchParams(updatedParams);
  };

  if (isPending) return <Loader id="projects" />;

  const renderList = () => (
    <Stack sx={{ overflow: 'hidden', boxShadow: 2, borderRadius: 3, mx: 5, my: 3 }}>
      {!projects?.length && !isLoading ? (
        <EmptyState status={status} searchFilter={searchFilter} />
      ) : (
        <Grid container spacing={3} xs={12} sx={{ m: 0 }}>
          <Grid
            container
            item
            columnGap={2.5}
            xs={12}
            sx={{
              py: '20px',
              borderBottom: `1px solid ${palette.grey[200]}`,
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: palette.background.default,
            }}
          >
            {HEADER_KEYS.map((key, index) => (
              <Grid
                item
                xs={cellWidth[index]}
                key={key}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 0.5,
                  pr: HEADER_KEYS.length - 1 === index ? 0 : 1,
                  textTransform: 'uppercase',
                  fontSize: 'body2.fontSize',
                  color: palette.grey[400],
                }}
              >
                <Box sx={{ color: palette.grey[700] }}>{t(`list.headers.${key}`)}</Box>
                <Tooltip arrow title={t(`tooltips.sort.${key}`)} placement="top">
                  <IconButton onClick={() => onSort(key)}>
                    <SortingArrows order={sortBy === key ? sortDir : SORT_DIRECTION.NONE} />
                  </IconButton>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
          <Grid container xs={12} sx={{ maxHeight: 550, overflowY: 'auto', overflowX: 'hidden', scrollbarWidth: 'thin' }}>
            {projects?.map(project => (
              <Project key={project.slug} project={project} onEditFinish={refetch} onRefetch={refetch} />
            ))}
            {isFetching && <ProjectsSkeleton />}
          </Grid>
        </Grid>
      )}
    </Stack>
  );

  return (
    <Box sx={{ px: 10, py: 5, width: '100%', maxWidth: 1280, mx: 'auto' }} data-testid="projects-page">
      <Stack flexDirection="row" alignItems="flex-start" justifyContent="space-between" sx={{ width: '100%', mb: 3 }}>
        <Typography variant="h2" component="h1">
          {greetingMessage}
        </Typography>

        <Tooltip arrow title={t('tooltips.createProject')} placement="top">
          <CreateProjectButton />
        </Tooltip>
      </Stack>
      <Apps />
      <Stack sx={{ mb: 2.5 }}>
        <SubjectWrapper label={t('subjectLabels.viewed')} iconName="viewed" sx={{ p: 0, border: 'unset', borderRadius: 0 }}>
          {isLoadingRecentlyViewedItems ? (
            <CircularProgress sx={{ m: 2 }} />
          ) : (
            <RecentlyViewed recentlyViewedItems={recentlyViewedItems} navigate={navigate} setSearchParams={setSearchParams} />
          )}
        </SubjectWrapper>
      </Stack>
      {false && <Documentations />}
      <SubjectWrapper label={t('subjectLabels.projects')} iconName="files">
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Tabs value={status} sx={{ pl: 5 }}>
            {tabs.map(({ label, value, to }) => (
              <Tab disableRipple key={value} label={label} value={value} to={to} component={Link} />
            ))}
          </Tabs>
          <RoundedInput
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Stack alignItems="center" sx={{ pl: 2 }}>
                    <Icon name="search" htmlColor={palette.primary.dark} />
                  </Stack>
                </InputAdornment>
              ),
            }}
            sx={{
              width: 360,
              borderRadius: 6,
              mr: 5,
              [breakpoints.down(1130)]: { width: 300 },
              [breakpoints.down(960)]: { width: 240 },
            }}
            inputProps={{ sx: { pl: 1, py: 0.75, fontSize: 'body1.fontSize' } }}
            placeholder={t('searchPlaceholder')}
            value={searchFilter}
            onChange={onSearchChange}
          />
        </Stack>
        {renderList()}
        {hasNextPage && <Box key={data?.pages?.length ?? 0} ref={ref} />}
      </SubjectWrapper>
    </Box>
  );
});

export default Projects;

import { KeyboardEvent, forwardRef, useMemo, FormEvent, MutableRefObject } from 'react';
import { Key } from 'ts-key-enum';
import RoundedMentionInput, { MentionsInstance } from '@/components/RoundedMentionInput/RoundedMentionInput';
import { Box, useTheme } from '@mui/material';
import Icon from '@/components/Icon/Icon';
import SendIconButton from '@/components/SendIconButton';
import { SuggestionDataItem } from '@/utils/getMentionTypeOptions';

interface AiMentionsInputProps {
  placeholder: string;
  value: string;
  documents?: SuggestionDataItem[];
  tools: SuggestionDataItem[];
  isSubmitting: boolean;
  onChange: (text: string) => void;
  onSubmit: () => void;
  minMaxHeight: number;
  instanceRef?: MutableRefObject<MentionsInstance | null>;
}

const AiMentionsInput = forwardRef<HTMLTextAreaElement | null, AiMentionsInputProps>(
  ({ value, onSubmit, documents, isSubmitting, placeholder, onChange, tools, minMaxHeight, instanceRef }, ref) => {
    const { palette } = useTheme();

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.code !== Key.Enter) return;
      event.preventDefault();
      onSubmit();
    };

    const isDisabled = useMemo(() => !value.trim(), [value]);

    const handleSubmit = (event: FormEvent) => {
      event.preventDefault();
      onSubmit();
    };

    return (
      <Box component="form" onSubmit={handleSubmit} sx={{ zIndex: 1000, position: 'relative' }}>
        <RoundedMentionInput
          ref={ref}
          value={value}
          instanceRef={instanceRef}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          onChange={event => onChange(event.target.value)}
          startIcon={<Icon name="ai" fontSize="xsmall" htmlColor={palette.primary.dark} sx={{ ml: 2, lineHeight: 1 }} />}
          endIcon={
            <SendIconButton
              sx={{ position: 'absolute', right: 0, bottom: 0, mb: 0.25 }}
              disabled={isDisabled}
              loading={isSubmitting}
            />
          }
          documents={documents}
          tools={tools}
          minMaxHeight={minMaxHeight}
        />
      </Box>
    );
  },
);

export default AiMentionsInput;

import Icon from '@/components/Icon/Icon';
import { Box, type MenuItemProps, TableCell, TableRow, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';
import { format } from 'date-fns/format';
import { STATUS } from '@/utils/enums';
import LinearProgressWithLabel from '@/components/LinearProgressWithLabel';
import Actions from '../../../Actions/Actions';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { DocumentVersionNode } from '../../types';

type VersionTableRowProps = {
  onClick: (id: string) => void;
  isDisabled?: boolean;
  data: DocumentVersionNode;
  isMainVersion?: boolean;
  canBeRemoved?: boolean;
  deleteDocumentVersion?: (documentVersionId: string) => Promise<void>;
  setAsMainDocumentVersion?: (documentVersionId: string) => Promise<void>;
};

export const VersionTableRow: FC<VersionTableRowProps> = props => {
  const { palette } = useTheme();
  const { t } = useTranslation('projectUpdate');
  const actionsState = usePopupState({
    variant: 'popover',
    popupId: `tree-file-version-actions-${props.data.id}`,
  });

  const actionsMenuItems = useMemo<MenuItemProps[]>(() => {
    const actions: MenuItemProps[] = [];

    if (props.canBeRemoved !== false) {
      actions[actions.length] = {
        id: 'delete',
        children: t('uploadFiles.deleteFile'),
        onClick: () => props.deleteDocumentVersion && props.deleteDocumentVersion(props.data.id),
      };
    }

    if (!props.isMainVersion) {
      actions[actions.length] = {
        id: 'makeMainVersion',
        children: t('uploadFiles.makeMainVersion'),
        onClick: () => props.setAsMainDocumentVersion && props.setAsMainDocumentVersion(props.data.id),
      };
    }

    return actions;
  }, [props.isMainVersion, props.deleteDocumentVersion, props.setAsMainDocumentVersion]);

  return [
    <tr className="spacer"></tr>,
    <TableRow
      key={props.data.id}
      sx={{
        cursor: props.isDisabled ? 'not-allowed' : 'pointer',
        position: 'relative',
        pointerEvents: props.isDisabled ? 'none' : 'auto',
        borderRadius: 50,
        height: '1.875rem',
        '& .MuiTableCell-root': {
          color: props.data.status === STATUS.ERROR ? palette.grey[400] : palette.grey.A400,
          opacity: props.isDisabled ? 0.4 : 1,
          py: 0.1,
          px: 0.1,
          borderBottom: 0,
          fontSize: 'body3.fontSize',
        },
        '&:hover': { backgroundColor: props.data.version ? palette.grey[50] : 'inherit' },
        '& .MuiButtonBase-root': { visibility: 'hidden' },
        '&:hover .MuiButtonBase-root': { visibility: 'visible' },
      }}
      onClick={() => props.data.id && props.onClick(props.data.id)}
    >
      <TableCell>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <Icon name={props.isMainVersion ? 'pdf' : 'pdfGray'} fontSize="small" />
          {props.data.filename}
        </Box>
      </TableCell>
      <TableCell>
        <Box alignItems="center" display="flex" gap={1} position="relative" whiteSpace="nowrap">
          {props.data.status !== STATUS.LOADING && format(new Date(props.data.lastModified), 'P')}

          {props.data.status === STATUS.LOADING ? (
            <LinearProgressWithLabel
              sx={{ position: 'absolute', left: 16, bottom: 0, right: 16, height: '100%' }}
              progress={props.data.progress || 0}
            />
          ) : (
            !props.isDisabled &&
            actionsMenuItems.length && (
              <Actions
                stopPropagation
                icon={<BsThreeDotsVertical size={16} />}
                sx={{ '&:hover': { backgroundColor: palette.primary.main }, zIndex: 1 }}
                menuItems={actionsMenuItems}
                actionsState={actionsState}
              />
            )
          )}
        </Box>
      </TableCell>
    </TableRow>,
  ];
};

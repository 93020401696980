import { TreeFileSystemNode } from '@/types';

export const getRelatedFilesAndFolders = (nodesById: Record<string, TreeFileSystemNode>, ids: string[]) => {
  const fileIds: string[] = [];
  const folderIds: string[] = [];

  ids.forEach(id => {
    if (!nodesById[id]) return;
    if (nodesById[id].type === 'file') {
      fileIds.push(id);
    } else {
      folderIds.push(id);
    }
  });

  let iterationFolderIds: string[] = [...folderIds];
  while (iterationFolderIds.length) {
    const nextFolderIds: string[] = [];
    Object.entries(nodesById).forEach(([, node]) => {
      if (!node.parentId || !iterationFolderIds.includes(node.parentId)) return;

      if (node.type === 'file') {
        fileIds.push(node.id);
      } else {
        nextFolderIds.push(node.id);
      }
    });
    iterationFolderIds = nextFolderIds;
  }

  return { fileIds, folderIds };
};

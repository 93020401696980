import { forwardRef, MouseEvent, ReactNode } from 'react';
import { Fab, FabProps } from '@mui/material';
import Icon, { IconNames } from '@/components/Icon/Icon';

export interface FabButtonProps extends FabProps {
  isDisabled?: boolean;
  iconName?: IconNames;
  children?: ReactNode;
  onClick: (event: MouseEvent) => void;
}

const FabButton = forwardRef<HTMLButtonElement, FabButtonProps>(
  ({ size = 'small', isDisabled, iconName, children, onClick, ...props }, ref) => (
    <Fab {...props} ref={ref} size={size} disabled={isDisabled} onClick={onClick}>
      {children ?? <Icon name={iconName!} />}
    </Fab>
  ),
);

export default FabButton;
